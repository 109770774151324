import { useRestAPIPerformActionResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useDeclineProduct = () => {
  const { onSubmit, isLoading } = useRestAPIPerformActionResource(
    RESOURCES.BRIEF,
    ENDPOINTS.BRIEF_HAS_ISSUES
    //{ title: "PRODUCT.MSG.BRIEF_HAS_ISSUES.TITLE", subtitle: "PRODUCT.MSG.BRIEF_HAS_ISSUES.SUBTITLE" }
  );

  return { onDeclineProduct: onSubmit, isDeclineProduct: isLoading };
};

export default useDeclineProduct;
