const FLOOR = "floor";
const WALL = "wall";

export const AR_PLACEMENT_LIST = {
  [FLOOR]: "AR_PLACEMENT.OPTION.FLOOR",
  [WALL]: "AR_PLACEMENT.OPTION.WALL",
};

export const arPlacementUIHelper = (intl) =>
  Object.keys(AR_PLACEMENT_LIST).map((placement) => ({
    label: intl.formatMessage({ id: AR_PLACEMENT_LIST[placement] }),
    value: placement,
  }));
