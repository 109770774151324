export const animateCSSOut = (
  element,
  animation,
  hideClass,
  prefix = "animate__"
) =>
  // We create a Promise and return it
  new Promise((resolve) => {
    const animationName = `${prefix}${animation}`;
    const node = document.querySelector(element);

    node.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event) {
      event.stopPropagation();
      node.classList.remove(`${prefix}animated`, animationName);
      resolve("Animation ended");
    }

    node.classList.add(hideClass);
    node.addEventListener("animationend", handleAnimationEnd, { once: false });
  });

export const animateCSSIn = (
  element,
  animation,
  hideClass,
  prefix = "animate__"
) => {
  //const animationName = `${prefix}${animation}`;
  const node = document.querySelector(element);

  if (node.classList.contains(hideClass)) {
    node.classList.remove(hideClass);
    //node.classList.add(`${prefix}animated`, animationName);
  }

  //function handleAnimationEnd(event) {
  //    event.stopPropagation();
  //node.classList.remove(`${prefix}animated`, animationName);
  //  }

  //node.addEventListener('animationend', handleAnimationEnd, {once: true});
};
