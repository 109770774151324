import PinPointIssues from "./../containers/pinpoint/PinPointIssues";
import PinPointDiscussions from "./../containers/pinpoint/PinPointDiscussions";

export const clientPinPointRoundIssueRoute = {
  path: "/issue",
  component: PinPointIssues,
};

export const clientPinPointIssueDiscussionRoute = {
  path: "/pinpoint/:pinpointParam",
  component: PinPointDiscussions,
};
