import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { editItem, clearResourceState } from "src/store/actions";

import { toast } from "react-toastify";

import { useEffect } from "react";
import { isEqual } from "lodash";

import { useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

const useRestAPIUpdateResource = (
  resource,
  endpoint,
  msgId,
  redirectTo,
  isFormData = false
) => {
  const dispatch = useDispatch();

  const onSubmit = (payload, params) =>
    dispatch(editItem(resource, endpoint, params, payload, isFormData));

  const { isLoading, updatedItem, success } = useSelector(
    (state) => ({
      isLoading: state.rest?.updatedResource[resource]?.isLoading,
      success: state.rest?.updatedResource[resource]?.success,
      updatedItem: state.rest?.updatedResource[resource]?.updatedItem,
    }),
    isEqual
  );

  const history = useHistory();

  useEffect(() => {
    return () => dispatch(clearResourceState(resource));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (success && redirectTo) {
      history.push(redirectTo);
    }

    // eslint-disable-next-line
  }, [success]);

  const intl = useIntl();

  useEffect(() => {
    if (success && msgId) {
      toast(
        <div className="toast">
          <div className="toast__divider--success" />
          <div className="toast__content">
            <div className="title">
              {intl.formatMessage({ id: msgId.title || "SUCCESS.TITLE" })}
            </div>
            <div className="subtitle">
              {intl.formatMessage({ id: msgId.subtitle || "SUCCESS.SUBTITLE" })}
            </div>
          </div>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          onOpen: () => dispatch(clearResourceState(resource)),
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }

    // eslint-disable-next-line
  }, [success]);

  return { onSubmit, isLoading, updatedItem, success };
};

export default useRestAPIUpdateResource;
