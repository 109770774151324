import { makeCall } from "src/utils";
import { store } from "src/store";

const FETCH_ROLES_CLIENT_ENDPOINT = "/api/v1/roles/for-clients";
const FETCH_ROLES_MANAGER_ENDPOINT = "/api/v1/roles/for-managers";

const formatRoles = (roles) =>
  roles.map((role) => ({
    label: role.name,
    value: role.id,
  }));

export const clientRoleUIHelper = (callback = (f) => f) => {
  const { token } = store.getState().authentication || {};

  return new Promise((resolve, reject) =>
    makeCall(
      "GET",
      FETCH_ROLES_CLIENT_ENDPOINT,
      {},
      { Authorization: `Bearer ${token.access_token}` },
      {}
    )
      .then((resp) => resolve(callback(formatRoles(resp.data))))
      .catch(() => reject(callback([])))
  );
};

export const managerRoleUIHelper = (callback = (f) => f) => {
  const { token } = store.getState().authentication || {};

  return new Promise((resolve, reject) =>
    makeCall(
      "GET",
      FETCH_ROLES_MANAGER_ENDPOINT,
      {},
      { Authorization: `Bearer ${token.access_token}` },
      {}
    )
      .then((resp) => resolve(callback(formatRoles(resp.data))))
      .catch(() => reject(callback([])))
  );
};
