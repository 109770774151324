import { motion } from "framer-motion";
import { useRef } from "react";

const componentVariants = (delay) => ({
  initial: { opacity: 0, x: 0, y: 10, delay, duration: 0.5 },
  in: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: { ease: "anticipate", delay, duration: 0.5 },
  },
  out: {
    opacity: 0,
    x: 10,
    y: 0,
    scale: 0.8,
    transition: { ease: "anticipate", delay, duration: 0.5 },
  },
});

const componentTransition = {
  ease: "easeOut",
  duration: 0.5,
};

const ComponentTransition = ({
  element = "div",
  children,
  duration = 1,
  ...props
}) => {
  const Component = motion[element];
  const ref = useRef();
  const onComplete = () => {
    ref.current.style = "";
  };

  return (
    <Component
      ref={ref}
      initial="initial"
      animate="in"
      exit="out"
      variants={componentVariants(duration)}
      transition={{ ...componentTransition, onComplete }}
      onAnimationComplete={onComplete}
      {...props}
    >
      {children}
    </Component>
  );
};

export default ComponentTransition;
