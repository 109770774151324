import { useEffect, useState } from "react";
import { clientCollaborationListRoute } from "src/modules/client/routes";
import {
  useRestAPICreateResource,
  useRestAPIUpdateResource,
  useRestAPIFetchResource,
} from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";
import { isEmpty, isEqual } from "lodash";

const useCreateUpdateWorkspace = (params) => {
  const { onSubmit: onCreate, isLoading: isLoadingForCreate } =
    useRestAPICreateResource(
      RESOURCES.WORKSPACE,
      ENDPOINTS.WORKSPACES,
      {
        title: "WORKSPACE.MSG.CREATED.TITLE",
        subtitle: "WORKSPACE.MSG.CREATED.SUBTITLE",
      },
      clientCollaborationListRoute.path
    );
  const { onSubmit: onUpdate, isLoading: isLoadingForUpdate } =
    useRestAPIUpdateResource(
      RESOURCES.WORKSPACE,
      ENDPOINTS.WORKSPACE,
      {
        title: "WORKSPACE.MSG.UPDATED.TITLE",
        subtitle: "WORKSPACE.MSG.UPDATED.SUBTITLE",
      },
      clientCollaborationListRoute.path
    );

  const { loadItem, fetchedItem: fetchedWorkspace } = useRestAPIFetchResource(
    RESOURCES.WORKSPACE,
    ENDPOINTS.WORKSPACE
  );

  const [prevParams, setPrevParams] = useState({});

  useEffect(() => {
    if (!isEmpty(params) && !isEqual(prevParams, params)) {
      loadItem(params);
      setPrevParams(params);
    }

    // eslint-disable-next-line
  }, [params]);

  const onSubmit = (values) => {
    if (isEmpty(params)) {
      onCreate(values);
    } else {
      onUpdate(values, params);
    }
  };

  return {
    onSubmit,
    fetchedWorkspace,
    isLoading: isLoadingForUpdate || isLoadingForCreate,
  };
};

export default useCreateUpdateWorkspace;
