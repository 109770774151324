import { motion } from "framer-motion";

const componentVariants = {
  initial: { opacity: 0, duration: 0.5 },
  in: { opacity: 1, transition: { ease: "anticipate", duration: 0.5 } },
  out: {
    opacity: 0,
    scale: 0.8,
    transition: { ease: "anticipate", duration: 0.5 },
  },
};

const componentTransition = {
  ease: "easeOut",
  duration: 0.5,
};

const Note = ({ children, variant = "info", className, ...props }) => {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      className={`note note-${variant} ${className}`}
      variants={componentVariants}
      transition={componentTransition}
      {...props}
    >
      {" "}
      {children}
    </motion.div>
  );
};

const Title = ({ children, className, ...props }) => (
  <div className={`note__title ${className}`} {...props}>
    {children}
  </div>
);

const Description = ({ children, className, ...props }) => (
  <div className={`note__description ${className}`} {...props}>
    {children}
  </div>
);

Note.Title = Title;
Note.Description = Description;

export default Note;
