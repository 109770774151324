import { Img } from ".";

const Discussion = ({ children, className, position = "", ...props }) => {
  return (
    <div
      className={"discussion discussion--" + position + " " + className}
      {...props}
    >
      {children}
    </div>
  );
};

const Toolbar = ({ children, className, ...props }) => (
  <div className={`discussion__toolbar ${className}`} {...props}>
    {children}
  </div>
);

const ToolbarIcon = ({ children, className, src, ...props }) => (
  <Img className="discussion__toolbar__icon" src={src} alt="..." {...props} />
);

const Header = ({ children, className, ...props }) => (
  <div className={`discussion__header ${className}`} {...props}>
    {children}
  </div>
);

const HeaderIcon = ({ children, className, ...props }) => (
  <div className={`discussion__header__icon ${className}`} {...props}>
    {children}
  </div>
);

const HeaderInfo = ({ children, className, ...props }) => (
  <div className={`discussion__header__info ${className}`} {...props}>
    {children}
  </div>
);

const HeaderTitle = ({ children, className, ...props }) => (
  <div className={`discussion__header__info__title ${className}`} {...props}>
    {children}
  </div>
);

const HeaderSubTitle = ({ children, className, ...props }) => (
  <div className={`discussion__header__info__subtitle ${className}`} {...props}>
    {children}
  </div>
);

const Body = ({ children, ...props }) => {
  return (
    <div className={`discussion__body`} {...props}>
      {children}
    </div>
  );
};

Discussion.Header = Header;
Discussion.Toolbar = Toolbar;
Discussion.ToolbarIcon = ToolbarIcon;
Discussion.HeaderIcon = HeaderIcon;
Discussion.HeaderInfo = HeaderInfo;
Discussion.HeaderSubTitle = HeaderSubTitle;
Discussion.HeaderTitle = HeaderTitle;
Discussion.Body = Body;

export default Discussion;
