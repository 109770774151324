import { useState, useEffect } from "react";
import { isEqual, isEmpty } from "lodash";
import { useRestAPIPaginateCollection } from "src/hooks";
import { ENDPOINTS, RESOURCES } from "src/store/constants";

const useIssuePagination = (params) => {
  const {
    loadItems,
    addItem,
    isFetching: isFetchingPinPoint,
    items = [],
    ...props
  } = useRestAPIPaginateCollection(RESOURCES.PINPOINT, ENDPOINTS.PINPOINTS);

  const [prevParams, setPrevParams] = useState({});

  useEffect(() => {
    if (!isEmpty(params) && !isEqual(prevParams, params)) {
      loadItems({ param: params?.roundParam });
      setPrevParams(params);
    }

    // eslint-disable-next-line
  }, [params]);

  return { loadItems, isFetchingPinPoint, pinpoints: items, ...props };
};

export default useIssuePagination;
