import { ACTIONS } from "./../constants";
import moment from "moment";
import { PURGE } from "redux-persist";

const DISCUSSION = "discussion";
const TIMELINE = "timeline";

const initialState = {
  pushNotifications: [],
  notifications: [],
  discussions: [],
  isFetchingNotification: false,
  isFetchingDiscussion: false,
  newComment: false,
  newNotification: false,
  lastActive: null,
};

const notificationReducer = (state = initialState, action) => {
  const { payload, type } = action;

  /*if (ACTIONS.SUBSCRIBE === type) {
    return { ...state, ...initialState, lastActive: new Date() }
  }

  if (ACTIONS.UN_SUBSCRIBE === type) {
    return { ...state, ...initialState }
  }*/

  if (ACTIONS.SEEN_COMMENT === type) {
    return { ...state, newComment: false };
  }

  if (ACTIONS.SEEN_NOTIFICATION === type) {
    return { ...state, newNotification: false };
  }

  if (ACTIONS.CLEAR_NOTIFICATION === type) {
    return { ...state, ...initialState };
  }

  if (ACTIONS.FETCH_NOTIFICATIONS_INIT === type) {
    return { ...state, isFetchingNotification: true };
  }
  if (ACTIONS.FETCH_NOTIFICATIONS_SUCCEDED === type) {
    const { items, paginate } = payload;
    return {
      ...state,
      isFetchingNotification: false,
      notifications: items,
      hasMoreNotification: paginate.page !== paginate.totalPages,
    };
  }
  if (ACTIONS.FETCH_NOTIFICATIONS_FAILED === type) {
    return { ...state, isFetchingNotification: false };
  }

  if (ACTIONS.FETCH_DISCUSSIONS_INIT === type) {
    return { ...state, isFetchingDiscussion: true };
  }
  if (ACTIONS.FETCH_DISCUSSIONS_SUCCEDED === type) {
    const { items, paginate } = payload;
    return {
      ...state,
      isFetchingDiscussion: false,
      discussions: items,
      hasMoreDiscussion: paginate.page !== paginate.totalPages,
    };
  }
  if (ACTIONS.FETCH_DISCUSSIONS_FAILED === type) {
    return { ...state, isFetchingDiscussion: false };
  }

  if (ACTIONS.ACTION_DISCUSSION_SEEN_SUCCEDED === type) {
    return {
      ...state,
      discussions: state.discussions.map((discussion) =>
        discussion.id !== payload.id ? discussion : payload
      ),
    };
  }

  if (ACTIONS.ACTION_NOTIFICATION_SEEN_SUCCEDED === type) {
    return {
      ...state,
      notifications: state.notifications.map((notification) =>
        notification.id !== payload.id ? notification : payload
      ),
    };
  }

  if (ACTIONS.PUSH_NOTIFICATION === type && payload?.type === DISCUSSION) {
    const latest = moment(payload.date).isAfter(moment(state.lastActive));
    return {
      ...state,
      newComment: latest || payload.new,
      pushNotifications: payload.new
        ? [...state.pushNotifications, { ...payload, latest }]
        : state.pushNotifications,
      discussions: [...state.discussions, { ...payload, latest }],
    };
  } else if (ACTIONS.PUSH_NOTIFICATION === type && payload?.type === TIMELINE) {
    const latest = moment(payload.date).isAfter(moment(state.lastActive));
    return {
      ...state,
      newNotification: latest || payload.new,
      pushNotifications: payload.new
        ? [...state.pushNotifications, { ...payload, latest }]
        : state.pushNotifications,
      notifications: [...state.notifications, { ...payload, latest }],
    };
  } else if (PURGE === type) {
    return { ...state, ...initialState };
  }

  if (ACTIONS.FLASH_NOTIFICATION === type) {
    return {
      ...state,
      pushNotifications: state.pushNotifications.length
        ? state.pushNotifications.slice(1)
        : state.pushNotifications,
    };
  }

  return state;
};

export default notificationReducer;
