import { useIntl } from "react-intl";
import { InputTextareaField, SubmitButton } from "src/components/forms";
import { validations } from "src/utils";
import { reduxForm, Field, Form } from "redux-form";
import { useEffect } from "react";
import { reset } from "redux-form";
import { useDispatch } from "react-redux";

const maxLength200 = validations.maxLength(200);

const DisccussionReplyForm = (props) => {
  const {
    handleSubmit,
    waitToSendReply,
    waitToSubmitReply,
    submitting,
    onCancel,
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  useEffect(() => {
    if (waitToSendReply) {
      dispatch(reset("issue-reply"));
    }

    // eslint-disable-next-line
  }, [waitToSendReply]);

  return (
    <Form className="row" onSubmit={handleSubmit}>
      <Field
        name="text"
        size=" col-md-12 no-spacing"
        component={InputTextareaField}
        placeholder={intl.formatMessage({ id: "FIELD.ISSUE_TEXT" })}
        validate={[validations.required, maxLength200]}
      />
      <div className="col-md-6 no-spacing">
        <button
          className="btn btn__block btn__danger"
          onClick={onCancel}
          type="reset"
        >
          {intl.formatMessage({ id: "ISSUE.CANCEL" })}
        </button>
      </div>
      <div className="col-md-6 no-spacing spacing__small--left">
        <SubmitButton
          loading="sm"
          isLoading={waitToSubmitReply}
          submitting={submitting}
          className="btn btn__block btn__primary"
          type="submit"
        >
          {intl.formatMessage({ id: "ISSUE.CREATE" })}
        </SubmitButton>
      </div>
    </Form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  form: "issue-reply", // a unique identifier for this form
})(DisccussionReplyForm);
