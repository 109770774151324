import {
  briefRoutes as briefClientRoutes,
  pinpointRoutes as pinpointClientRoutes,
} from "src/modules/client/pages/collaboration/routes";
import {
  briefRoutes as briefManagerRoutes,
  pinpointRoutes as pinpointManagerRoutes,
} from "src/modules/manager/pages/collaboration/routes";

import { isNumber } from "lodash";

const CREATE_COMMENT = "create_comment";
const UPDATE_COMMENT = "update_comment";
const CREATE_PINPOINT = "create_pinpoint";
const CREATE_ROUND_PENDING = "create_round_pending";
const CREATE_ROUND_ERROR = "create_round_error";
const CREATE_ROUND_DONE = "create_round_done";

const CREATE_BRIEF_PENDING = "create_brief_pending";
const CREATE_BRIEF_ERROR = "create_brief_error";
const CREATE_BRIEF_DONE = "create_brief_done";
const UPDATE_BRIEF = "update_brief";
const VALIDATE_BRIEF = "validate_brief";

const CREATE_WORKSPACE = "create_workspace";
const UPDATE_WORKSPACE = "update_workspace";

const BRIEF_APPROVED = "brief_approved";
const BRIEF_ACTIVE = "brief_active";
const BRIEF_READY_FOR_REVIEW = "brief_ready_for_review";
const BRIEF_CLOSED = "brief_close";
const BRIEF_HAS_ISSUES = "brief_has_issues";

export const NOTIFICATION_DESC = {
  [CREATE_COMMENT]: "NOTIFICATION.DESC.CREATE_COMMENT",
  [UPDATE_COMMENT]: "NOTIFICATION.DESC.UPDATE_COMMENT",
  [CREATE_PINPOINT]: "NOTIFICATION.DESC.CREATE_PINPOINT",
  [CREATE_ROUND_PENDING]: "NOTIFICATION.DESC.CREATE_ROUND_PENDING",
  [CREATE_ROUND_ERROR]: "NOTIFICATION.DESC.CREATE_ROUND_ERROR",
  [CREATE_ROUND_DONE]: "NOTIFICATION.DESC.CREATE_ROUND_DONE",

  [CREATE_BRIEF_PENDING]: "NOTIFICATION.DESC.CREATE_BRIEF_PENDING",
  [CREATE_BRIEF_ERROR]: "NOTIFICATION.DESC.CREATE_BRIEF_ERROR",
  [CREATE_BRIEF_DONE]: "NOTIFICATION.DESC.CREATE_BRIEF_DONE",
  [UPDATE_BRIEF]: "NOTIFICATION.DESC.UPDATE_BRIEF",
  [VALIDATE_BRIEF]: "NOTIFICATION.DESC.VALIDATE_BRIEF",

  [BRIEF_APPROVED]: "NOTIFICATION.DESC.BRIEF_APPROVED",
  [BRIEF_ACTIVE]: "NOTIFICATION.DESC.BRIEF_ACTIVE",
  [BRIEF_READY_FOR_REVIEW]: "NOTIFICATION.DESC.BRIEF_READY_FOR_REVIEW",
  [BRIEF_CLOSED]: "NOTIFICATION.DESC.BRIEF_CLOSED",
  [BRIEF_HAS_ISSUES]: "NOTIFICATION.DESC.BRIEF_HAS_ISSUES",

  [CREATE_WORKSPACE]: "NOTIFICATION.DESC.CREATE_WORKSPACE",
  [UPDATE_WORKSPACE]: "NOTIFICATION.DESC.UPDATE_WORKSPACE",
};

export const NOTIFICATION_TYPE_TIMELINE = "timeline";
export const NOTIFICATION_TYPE_DISCUSSION = "discussion";

export const notificationClientPath = (notification) => {
  if (isNumber(notification?.discussionId) && isNumber(notification?.roundId)) {
    return pinpointClientRoutes.clientPinPointIssueDiscussionRoute.path
      .replace(":param", notification?.briefId)
      .replace(":roundParam", notification?.roundId)
      .replace(":pinpointParam", notification?.pinpointId)
      .replace(":discussionParam", notification?.discussionId);
  }
  if (isNumber(notification?.pinpointId) && isNumber(notification?.roundId)) {
    return pinpointClientRoutes?.clientPinPointIssueDiscussionRoute.path
      .replace(":param", notification?.briefId)
      .replace(":roundParam", notification?.roundId)
      .replace(":pinpointParam", notification?.pinpointId);
  }
  if (isNumber(notification?.roundId)) {
    return briefClientRoutes.clientRoundInfoRoute.path
      .replace(":param", notification?.briefId)
      .replace(":roundParam", notification?.roundId);
  }
  return briefClientRoutes.clientBriefInfoRoute.path.replace(
    ":param",
    notification?.briefId
  );
};

export const notificationManagerPath = (notification) => {
  if (isNumber(notification?.discussionId) && isNumber(notification?.roundId)) {
    return pinpointManagerRoutes.managerPinPointIssueDiscussionRoute.path
      .replace(":param", notification?.briefId)
      .replace(":roundParam", notification?.roundId)
      .replace(":pinpointParam", notification?.pinpointId)
      .replace(":discussionParam", notification?.discussionId);
  }
  if (isNumber(notification?.pinpointId) && isNumber(notification?.roundId)) {
    return pinpointManagerRoutes?.managerPinPointIssueDiscussionRoute.path
      .replace(":param", notification?.briefId)
      .replace(":roundParam", notification?.roundId)
      .replace(":pinpointParam", notification?.pinpointId);
  }
  if (isNumber(notification?.roundId)) {
    return briefManagerRoutes.managerRoundInfoRoute.path
      .replace(":param", notification?.briefId)
      .replace(":roundParam", notification?.roundId);
  }
  return briefManagerRoutes.managerBriefInfoRoute.path.replace(
    ":param",
    notification?.briefId
  );
};
