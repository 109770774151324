import { useState, useRef, useEffect } from "react";
import {
  useDoneUploadingAction,
  useStartUploadingAction,
  useUserToken,
  useQueueState,
} from "src/hooks";
import { mimeTypeImgUiHelper } from "src/uiHelper";
import { getMimeType, isUrlImg, MultiUpload } from "src/utils";
import { isEqual, uniqWith, isEmpty } from "lodash";
import { useDidMount } from "../common";

const useUploader = (input, form, resource, multiple = true) => {
  const inputRef = useRef();
  const [files, setFiles] = useState([]);
  const { onChange, onBlur, value, ...inputProps } = input;

  const { enqueue, peek, dequeue, length } = useQueueState();
  const [fileToBeUploaded, setFileToBeUploaded] = useState();
  const [progress, setProgress] = useState(0);
  const [isPaused, setPaused] = useState(false);

  const [isFetching, setFetching] = useState(true);

  const startUploading = useStartUploadingAction(form);
  const doneUploading = useDoneUploadingAction(form);

  const token = useUserToken();

  useDidMount(() => {
    MultiUpload.fetchObjects(resource, token)
      .then((objects) => {
        objects.forEach((obj) => {
          const uploadedFile = new MultiUpload(
            new File([], obj.url),
            isUrlImg(obj.url)
              ? obj.url
              : mimeTypeImgUiHelper(getMimeType(obj.url)),
            resource,
            token,
            obj
          );
          setFiles((items) => [...items, uploadedFile]);
          onBlur(multiple ? [...(value || []), uploadedFile] : uploadedFile);
        });
      })
      .finally(() => {
        setFetching(false);
      });
  });

  const loadFile = (file) =>
    new Promise((resolve, reject) => {
      if (!file.type.match("image.*")) {
        resolve(
          new MultiUpload(
            file,
            mimeTypeImgUiHelper(file.mimeType),
            resource,
            token
          )
        );
      }
      const reader = new FileReader();
      reader.onload = function (e) {
        const upload = new MultiUpload(file, e.target.result, resource, token);
        resolve(upload);
      };
      reader.onerror = () => {
        reject(
          new MultiUpload(
            file,
            mimeTypeImgUiHelper(file.mimeType),
            resource,
            token
          )
        );
      };
      reader.readAsDataURL(file);
    });

  const handleChange = (e) => {
    for (let i = 0; i < e.target.files.length; i++) {
      loadFile(e.target.files.item(i)).then((upload) => {
        if (!(value || []).some((item) => upload?._id === item?._id)) {
          console.log(upload)
          enqueue(upload);
          setFiles((items) =>
            multiple ? uniqWith([...items, upload], "_id") : upload
          );
        }
      });
    }
    //onBlur(e.target.files[0])
  };

  const trigerFileUpload = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  useEffect(() => {
    const uploadedFile = peek();
    console.log(uploadedFile, (
      !isEqual(uploadedFile?._id, fileToBeUploaded?._id) &&
      !isEmpty(uploadedFile) &&
      !uploadedFile.isUploading
    ), length)
    if (
      !isEqual(uploadedFile?._id, fileToBeUploaded?._id) &&
      !isEmpty(uploadedFile) &&
      !uploadedFile.isUploading
    ) {
      startUploading();
      uploadedFile.start();
      setPaused(false);
      uploadedFile.onProgressChanged = (uploadingSize, totalSize) => {
        setProgress((uploadingSize / totalSize) * 100);
      };
      uploadedFile.onUploadCompleted = () => {
        onBlur(multiple ? [...(value || []), uploadedFile] : uploadedFile);
        setFileToBeUploaded(() => null);
        doneUploading();
        dequeue();
      };
      uploadedFile.onResume = () => {
        setPaused(false);
      };
      uploadedFile.onPaused = () => {
        setPaused(true);
      };
      setFileToBeUploaded(uploadedFile);
    }

    // eslint-disable-next-line
  }, [fileToBeUploaded, length]);

  const restartUpload = () => {
    setPaused(false);
    if (!isEmpty(fileToBeUploaded)) {
      fileToBeUploaded.resume();
    }
  };
  const pauseUpload = () => {
    if (!isEmpty(fileToBeUploaded)) {
      fileToBeUploaded.pause();
    }
  };

  const removeFile = (file) => {
    setFiles((items) => items.filter((item) => item._id !== file._id));
    if (fileToBeUploaded && isEqual(fileToBeUploaded._id, file._id)) {
      file.abort();
      dequeue();
      setFileToBeUploaded(() => null);
    } else {
      file.remove();
      onBlur((value || []).filter((item) => item._id !== file._id));
    }
  };

  return {
    restartUpload,
    isFetching,
    pauseUpload,
    removeFile,
    inputProps,
    handleChange,
    trigerFileUpload,
    files,
    progress,
    isPaused,
    fileToBeUploaded,
    inputRef,
    uploadQueueLength: length
  };
};

export default useUploader;
