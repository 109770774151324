import { FormattedMessage } from "react-intl";

const SwitchField = ({
  input,
  label,
  size = "col-xs-12",
  meta: { touched, error, warning },
}) => {
  return (
    <div className={size}>
      <div className="form-group-switch">
        {label && <label className="switch-label">{label}</label>}
        <label className="switch">
          <input type="checkbox" {...input} checked={input.value} />
          <span className="slider round"></span>
        </label>
        <div className="form-control__danger">
          {touched && error && <FormattedMessage {...JSON.parse(error)} />}
          {touched && warning && <FormattedMessage {...JSON.parse(warning)} />}
        </div>
      </div>
    </div>
  );
};

export default SwitchField;
