import { useDispatch } from "react-redux";
import { flashError } from "src/store/actions";

const useFlashErrorAction = () => {
  const dispatch = useDispatch();

  return () => dispatch(flashError());
};

export default useFlashErrorAction;
