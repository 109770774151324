import { NotFound } from "src/components/error";
import RouteTransition from "./RouteTransition";
import { Switch as BaseSwitch, Route, Redirect } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { SplashScreen } from "../loaders";
import {memo} from "react";

const NestedSwitch = ({
  basePath,
  defaultPath,
  defaultPathCallback,
  defaultComponent,
  routes,
  ...props
}) => {
  return (
    <AnimatePresence exitBeforeEnter>
      <BaseSwitch>
        <Route
          exact
          path={basePath}
          render={() =>
            defaultPath ? (
              <Redirect from={basePath} to={defaultPath} />
            ) : (
              <SplashScreen />
            )
          }
        />
        {defaultPathCallback && (
          <Route exact path={basePath} render={defaultPathCallback} />
        )}
        {Object.keys(routes).map((key) => (
          <RouteTransition key={key} {...routes[key]} {...props} />
        ))}
        {defaultComponent && <Route component={defaultComponent} />}
        <RouteTransition component={NotFound} />
      </BaseSwitch>
    </AnimatePresence>
  );
};

export default memo(NestedSwitch);
