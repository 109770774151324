//import InternalError from "./InternalError"
import { ErrorBoundary as BaseErrorBoundary } from "react-error-boundary";
import { useClearStateAction } from "src/hooks";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  console.group();
  console.log(error);
  console.groupEnd();

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

const ErrorBoundary = ({ children }) => {
  const clearState = useClearStateAction();

  if (
    !(
      window.File &&
      window.FileReader &&
      window.FileList &&
      window.Blob &&
      window.Blob.prototype.slice
    )
  ) {
    alert(
      "Sorry! You are using an older or unsupported browser. Please update your browser"
    );
  }

  return (
    <BaseErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => clearState()}
    >
      {children}
    </BaseErrorBoundary>
  );
};

export default ErrorBoundary;
