import { useRestAPICreateResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useCreateBrief = (location) => {
  const { onSubmit, isLoading, createdItem, success } =
    useRestAPICreateResource(
      RESOURCES.BRIEF,
      ENDPOINTS.BRIEFS,
      {
        title: "BRIEF.MSG.CREATED.TITLE",
        subtitle: "BRIEF.MSG.CREATED.SUBTITLE",
      },
      location
    );

  return {
    onSubmitBrief: onSubmit,
    createdBrief: createdItem,
    isBriefCreated: success,
    isLoading,
  };
};

export default useCreateBrief;
