import { lazy } from "react";

const BriefInfo = lazy(() => import("./../containers/brief/BriefDisplay"));
const RoundInfo = lazy(() => import("./../containers/brief/RoundDisplay"));
const ProductInfo = lazy(() => import("./../containers/brief/ProductDisplay"));

export const managerBriefInfoRoute = {
  path: "/brief",
  component: BriefInfo,
};

export const managerRoundInfoRoute = {
  path: "/round/:roundParam",
  component: RoundInfo,
};

export const managerProductInfoRoute = {
  path: "/product/:roundParam",
  component: ProductInfo,
};
