import { useDispatch } from "react-redux";
import { fetchDiscussions, fetchNotifications } from "src/store/actions";

import { useEffect } from "react";
import { isEqual } from "lodash";

import { useSelector } from "react-redux";

const useLoadNotification = () => {
  const dispatch = useDispatch();

  const loadNotification = (query) => dispatch(fetchNotifications(query));
  const loadDiscussion = (query) => dispatch(fetchDiscussions(query));

  const {
    isFetchingNotification,
    isFetchingDiscussion,
    notifications,
    discussions,
  } = useSelector(
    (state) => ({
      isFetchingNotification: state.notif.isFetchingNotification,
      isFetchingDiscussion: state.notif.isFetchingDiscussion,
      notifications: state.notif.notifications,
      discussions: state.notif.discussions,
    }),
    isEqual
  );

  useEffect(() => {
    loadDiscussion();
    loadNotification();

    // eslint-disable-next-line
  }, []);

  return {
    loadNotification,
    isFetchingDiscussion,
    isFetchingNotification,
    discussions,
    notifications,
  };
};

export default useLoadNotification;
