import { useEffect } from "react";

const useDidMount = (callback) => {
  useEffect(() => {
    if (typeof callback === "function") {
      callback();
    }

    // eslint-disable-next-line
  }, []);
};

export default useDidMount;
