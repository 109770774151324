import { useRestAPIPerformActionResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useProductReadyForRevision = () => {
  const { onSubmit, isLoading } = useRestAPIPerformActionResource(
    RESOURCES.BRIEF,
    ENDPOINTS.BRIEF_READY_FOR_REVISION,
    {
      title: "PRODUCT.MSG.BRIEF_READY_FOR_REVISION.TITLE",
      subtitle: "PRODUCT.MSG.BRIEF_READY_FOR_REVISION.SUBTITLE",
    }
  );

  return { productReadyForReview: onSubmit, isLoading };
};

export default useProductReadyForRevision;
