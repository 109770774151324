import { ACTIONS } from "./../constants";

export const startUploading = (form) => ({
  type: ACTIONS.START_UPLOADING,
  form,
});

export const doneUploading = (form) => ({
  type: ACTIONS.DONE_UPLOADING,
  form,
});
