import { CALL_API, ACTIONS } from "./../constants";
import { makeCall, objectToFormData } from "./../../utils";

const api = (store) => (next) => async (action) => {
  if (!action || action.type !== CALL_API) {
    return next(action);
  }

  let { payload } = action;
  const {
    actions,
    forceUpdate,
    resource,
    endpoint,
    method,
    isFormData,
    params = {},
  } = action.meta;

  const { token } = store.getState().authentication || {};

  const dispatch = dispatchActions(next);

  if (actions.init) {
    dispatch(actions.init, params, resource);
    dispatch(ACTIONS.INIT, params, actions.init);
  }

  let headers = {};
  if (token && token.access_token) {
    headers = { Authorization: `Bearer ${token.access_token}` };
  }

  // check for form data
  if (isFormData) {
    headers["Content-Type"] = "multipart/form-data";
    payload = objectToFormData(action.payload);
  }

  makeCall(method, endpoint, payload, headers, params, { forceUpdate })
    .then((resp) => {
      dispatch(actions.success, resp.data, resource);
      dispatch(ACTIONS.SUCCEDED, resp.data, actions.success, resource);
    })
    .catch((err) => {
      console.group();
      console.log(err);
      console.group();
      if (actions.fail) {
        dispatch(actions.fail, err.response || {}, resource);
      }
      dispatch(ACTIONS.FAILED, err.response || {}, actions.fail);
    });
};

const dispatchActions =
  (next) =>
  (type, payload = {}, resource = "") =>
    next({ type, resource, payload });

export default api;
