import { FormattedMessage } from "react-intl";
import ComponentTransition from "./ComponentTransition";
//import {toAbsoluteUrl} from "src/utils"
//import {Img} from "."

const OrderDirection = ({ orderBy }) => {
  //return orderBy?.includes("asc") ? <Img src={toAbsoluteUrl("/media/icons/asc.svg")} />: <Img src={toAbsoluteUrl("/media/icons/desc.svg")}/>
  return (
    <ComponentTransition
      element="span"
      duration={0.01}
      className="badge badge-sm badge-3"
    >
      {orderBy?.includes("asc") ? (
        <FormattedMessage id="ORDER.ASC" />
      ) : (
        <FormattedMessage id="ORDER.DESC" />
      )}
    </ComponentTransition>
  );
};

export default OrderDirection;
