import { FormattedMessage, useIntl } from "react-intl";
import {
  Dropdown,
  Img,
  ObjectAttrDisplayProvider,
  ObjectAttrDisplay,
} from "src/components/common";
import { isEmpty } from "lodash";

import { useHistory } from "react-router-dom";
import * as routes from "src/modules/client/routes";
import InputFilterField from "src/components/forms/InputFilterField";
import { toAbsoluteUrl } from "src/utils";

const Header = ({
  isFetchingWorkspace,
  fetchedWorkspace,
  totalSize,
  isFetching,
  query,
  filterItems = (f) => f,
}) => {
  const history = useHistory();
  const intl = useIntl();

  const onCreateBrief = () => {
    if (!isEmpty(fetchedWorkspace)) {
      history.push(
        routes.clientWorkspaceBriefCreateRoute.path.replace(
          ":param",
          fetchedWorkspace?.id
        )
      );
    } else {
      history.push(routes.clientBriefCreateRoute.path);
    }
  };

  return (
    <div className="row spacing--page-filter">
      <div className="col-md-4 col-xs-12">
        <p className="text--title text--bold text--left">
          <ObjectAttrDisplayProvider
            object={fetchedWorkspace}
            isFetching={isFetchingWorkspace}
          >
            <ObjectAttrDisplay
              defaultVal={<FormattedMessage id="PRODUCT.TITLE" />}
              attr="name"
              className="big_text__loader"
            />
          </ObjectAttrDisplayProvider>
          <span className="divider" /> {totalSize || 0}{" "}
          <FormattedMessage id="TOTAL" />
        </p>
      </div>
      <div className="row col-md-8 col-xs-12 end-xs">
        <div className="spacing__right--extra-small spacing__down--extra-small">
          <InputFilterField
            isFetching={isFetching}
            onChange={(keyword) => filterItems({ keyword })}
            value={query?.keyword}
            icon={toAbsoluteUrl("/media/icons/search.svg")}
            placeholder={intl.formatMessage({ id: "WORKSPACE.FILTER" })}
          />
        </div>
        <div className="spacing__right--extra-small spacing__down--extra-small">
          <Dropdown>
            <Dropdown.Toggle isFetching={isFetching}>
              <FormattedMessage id="ORDER_BY" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.OrderByItem
                onOrderBy={filterItems}
                attr="status"
                query={query}
                choice={<FormattedMessage id="ORDER.BY_STATUS" />}
              />
              <Dropdown.OrderByItem
                onOrderBy={filterItems}
                active={true}
                attr="name"
                query={query}
                choice={<FormattedMessage id="ORDER.BY_NAME" />}
              />
              <Dropdown.OrderByItem
                onOrderBy={filterItems}
                attr="tag"
                query={query}
                choice={<FormattedMessage id="ORDER.BY_TAG" />}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="spacing__right--extra-small spacing__down--extra-small">
          <Dropdown>
            <Dropdown.Toggle>
              <FormattedMessage id="VIEW_AS" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                to={routes.clientCollaborationListRoute.path}
                choice={<FormattedMessage id="VIEW.WORKSPACE" />}
              >
                <Img
                  src={toAbsoluteUrl("/media/icons/workspace.svg")}
                  alt="..."
                />
              </Dropdown.Item>
              <Dropdown.Item
                to={routes.clientBriefListRoute.path}
                choice={<FormattedMessage id="VIEW.PRODUCT" />}
                active={true}
              >
                <Img
                  src={toAbsoluteUrl("/media/icons/product.svg")}
                  alt="..."
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="spacing__right--extra-small spacing__down--extra-small">
          <button className="btn btn__primary" onClick={onCreateBrief}>
            <FormattedMessage id="PRODUCT.CREATE" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
