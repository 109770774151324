import { useRestAPIPerformActionResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useDeclineBrief = () => {
  const { onSubmit, isLoading } = useRestAPIPerformActionResource(
    RESOURCES.PRODUCT,
    ENDPOINTS.BRIEF_HAS_ISSUES,
    {
      title: "PRODUCT.MSG.BRIEF_HAS_ISSUES.TITLE",
      subtitle: "PRODUCT.MSG.BRIEF_HAS_ISSUES.SUBTITLE",
    }
  );

  return { onDeclineBrief: onSubmit, isDeclineBrief: isLoading };
};

export default useDeclineBrief;
