import { useDispatch } from "react-redux";
import { clearState } from "src/store/actions";

const useClearStateAction = () => {
  const dispatch = useDispatch();

  return () => dispatch(clearState());
};

export default useClearStateAction;
