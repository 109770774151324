import { get, isEmpty, isFunction, isInteger, isFinite, isObject } from "lodash";
import { createContext, useContext } from "react";
import { Img } from ".";


const ObjectAttrDisplayContext = createContext(null);
const useObjectAttrDisplayContext = () => useContext(ObjectAttrDisplayContext);

export const ObjectAttrDisplayProvider = ({ object, isFetching, children }) => {
  const value = {
    object,
    isFetching,
  };

  return (
    <ObjectAttrDisplayContext.Provider value={value}>
      {children}
    </ObjectAttrDisplayContext.Provider>
  );
};

export const ContentLoader = ({ className }) => (
  <div className={`item-load ${className}`} />
);

export const ObjectAttrDisplay = ({
  attr,
  formatter,
  defaultVal = null,
  className,
}) => {
  const { isFetching, object } = useObjectAttrDisplayContext();

  if (isFetching) {
    return <div className={`item-load ${className}`} />;
  }

  if (isEmpty(object) && !isFetching) {
    return defaultVal;
  }

  let item = get(object, attr, null);

  if (isFinite(item) && !isInteger(item)) {
    item = item.toFixed(2);
  }

  if (isFunction(formatter)) {
    return formatter(item, object) || defaultVal;
  }


  return item || defaultVal;
};

export const ObjectImgDisplay = ({ attr, defaultVal, className }) => {
  const { isFetching, object } = useObjectAttrDisplayContext();

  if (isFetching) {
    return <div className={`img-load ${className}`} />;
  }

  if (isEmpty(object) && !isFetching) {
    return <Img src={defaultVal} />;
  }

  const item = get(object, attr, null);

  return <Img src={item || defaultVal} />;
};

export const ObjectImgSmDisplay = ({ attr, defaultVal, className }) => {
  const { isFetching, object } = useObjectAttrDisplayContext();

  if (isFetching) {
    return <div className={`img-sm-load ${className}`} />;
  }

  if (isEmpty(object) && !isFetching) {
    return <Img src={defaultVal} />;
  }

  const item = get(object, attr, null);

  return <Img src={item || defaultVal} />;
};

export const ObjectCustomList = ({
  attr,
  formatter,
  defaultVal = null,
  length = 0,
}) => {
  const { isFetching, object } = useObjectAttrDisplayContext();

  if (isFetching) {
    return (
      <div className="status_list">
        {new Array(length).fill(0).map((_, key) => (
          <div key={key} className="status_list__item">
            <div className="status_list__item__text">
              <div className="item-load nested_text__loader" />
            </div>
            <div className="status_list__item__value">
              {" "}
              <div className="item-load nested_text__loader" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (isEmpty(object) && !isFetching) {
    return defaultVal;
  }

  const item = get(object, attr, {});

  return (
    <div className="status_list">
      {isObject(item) && Object.keys(item).map((key) => (
        <div key={key} className="status_list__item">
          <div className="status_list__item__text">{formatter(key, item)}</div>
          <div className="status_list__item__value"> {item[key] || 0}</div>
        </div>
      ))}
    </div>
  );
};
