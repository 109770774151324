import { useDispatch } from "react-redux";
import { clearItems, filterPagination, fetchItems } from "src/store/actions";

import { useEffect } from "react";
import { isEqual } from "lodash";

import { useSelector } from "react-redux";

const useRestAPIFetchPaginate = (resource, ...endpoint) => {
  const dispatch = useDispatch();

  const loadItems = (params, query) =>
    dispatch(fetchItems(resource, endpoint, params, query));
  const filterItems = (payload) =>
    dispatch(filterPagination(resource, payload));

  const {
    pagination,
    query,
    isFetching = false,
  } = useSelector(
    (state) => ({
      isFetching: state?.rest?.paginateCollection[resource]?.isFetching,
      pagination: state?.rest?.paginateCollection[resource]?.pagination ?? {},
      query: state?.rest?.paginateCollection[resource]?.query ?? {},
    }),
    isEqual
  );

  useEffect(() => {
    return () => dispatch(clearItems(resource));

    // eslint-disable-next-line
  }, []);

  return { filterItems, ...pagination, query, isFetching, loadItems };
};

export default useRestAPIFetchPaginate;
