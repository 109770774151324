import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import { validate3DModel } from "src/utils";

const Info3DModel = ({ url }) => {
  const [modelDetails, setModelDetails] = useState();

  useEffect(() => {
    if (url) {
      validate3DModel(url).then((result) => {
        setModelDetails(result);
      });
    }
  }, [url]);

  if (!isEmpty(modelDetails)) {
    return (
      <div className="model-details">
        <p>
          <span>
            <FormattedMessage id="MODEL.INFO.DRAW_CALLS" />
          </span>
          <span> {modelDetails?.info?.drawCallCount}</span>{" "}
        </p>
        <p>
          <span>
            <FormattedMessage id="MODEL.INFO.ANIMATIONS" />
          </span>
          <span>{modelDetails?.info?.animationCount}</span>
        </p>
        <p>
          <span>
            <FormattedMessage id="MODEL.INFO.MATERIALS" />
          </span>
          <span>{modelDetails?.info?.materialCount}</span>
        </p>
        <p>
          <span>
            <FormattedMessage id="MODEL.INFO.VERTICES" />
          </span>
          <span>{modelDetails?.info?.totalTriangleCount}</span>
        </p>
        <p>
          <span>
            <FormattedMessage id="MODEL.INFO.TRIANGLES" />
          </span>
          <span>{modelDetails?.info?.totalVertexCount}</span>
        </p>
      </div>
    );
  }
  return null;
};

export default Info3DModel;
