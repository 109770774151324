import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import { ModelViewerEditorConfig } from "src/components/model3D";
import { Img } from "src/components/common";
import {
  Draggable3DModelProductForm,
  Info3DModel,
  Editor3DModel,
  LoadingUploadedFiles,
  Draggable3DModelLightingForm,
  ProgressBar,
} from "./components";
import { use3DModelUploader } from "src/hooks";
import ComponentTransition from "../common/ComponentTransition";
import {useState} from "react";

const InputUploadField = ({
  input,
  modelResource,
  thumbnailResource,
  helper,
  note,
  authRequired = true,
  showSubmitForm = true,
  icon,
  isLoading,
  children,
  modalTitle,
  modalFormTitle,
  size = "col-xs-12",
  className = "form-control",
  meta: { form, touched, error, warning },
  ...props
}) => {
  const { value, ...inputProps } = input;

  const [editorConfig, setEditorConfig] = useState({
    toggleLightingConfig: false
  })

  const {
    isFetching,
    restartModelUpload,
    pauseModelUpload,
    thumbnail,
    isDeleting,
    handleChange,
    removeModel,
    trigerModelUpload,
    model,
    generateThumbnailUplaod,
    uploadThumbnailAndSubmit,
    onDescriptionChange,
    modelProgress,
    isModelPaused,
    inputModelRef,
    isUploading,
    inputThumbnailRef,
  } = use3DModelUploader(input, form, modelResource, thumbnailResource);

  return (
    <div className="form-group">
      {!isEmpty(value?.model) ? (
        <div className="viewer">
          { showSubmitForm && <Draggable3DModelProductForm
            value={value}
            modalTitle={modalTitle}
            modalFormTitle={modalFormTitle}
            removeModel={removeModel}
            thumbnail={thumbnail}
            uploadThumbnailAndSubmit={uploadThumbnailAndSubmit}
            inputThumbnailRef={inputThumbnailRef}
            onDescriptionChange={onDescriptionChange}
            isLoading={isLoading || isUploading}
            generateThumbnailUplaod={generateThumbnailUplaod}
          >{ children }</Draggable3DModelProductForm>}
          <Draggable3DModelLightingForm
            editorConfig={editorConfig}
            setEditorConfig={setEditorConfig}
            value={value}
            input={input}
            form={form}
            modalTitle={<FormattedMessage id="MODEL_CONFIG.TITLE_LIGHTING" />}
            isLoading={isLoading || isUploading}
          />
          <Editor3DModel
            editorConfig={editorConfig}
            setEditorConfig={setEditorConfig}
            onRemove={removeModel}
            isDeleting={isDeleting}
            modelUrl={value?.model?.completeMultipartUploadResponse?.url}
          />
          <ModelViewerEditorConfig
            editorConfig={editorConfig}
            class="viewer"
            alt=""
            id="js-model-viewer-uploader"
            src={value?.model?.completeMultipartUploadResponse?.url}
          />
          <Info3DModel
            url={value?.model?.completeMultipartUploadResponse?.url}
          />
        </div>
      ) : (
        <>
          <div
            onClick={
              isEmpty(model) && !isFetching ? trigerModelUpload : (f) => f
            }
            className={`form-control__model__area ${
              touched && error && "has-danger"
            }`}
          >
            <ComponentTransition
              duration={0.3}
              className="form-control__model__area__placeholder"
            >
              {!isFetching ? (
                <>
                  {" "}
                  <Editor3DModel onUpload={trigerModelUpload} />{" "}
                  {isEmpty(model) && (
                    <div>
                      <Img
                        width="280"
                        height="120"
                        className="form-control__model__area__placeholder__img"
                        src={icon}
                        alt="..."
                      />
                      <p className="form-control__model__area-spacing--big-text">
                        <FormattedMessage id="UPLOAD_MODEL.TITLE" />
                      </p>
                      <p className="form-control__model__area--text--extra-small">
                        {note}
                      </p>
                    </div>
                  )}{" "}
                </>
              ) : (
                <div>
                  <LoadingUploadedFiles />
                </div>
              )}
              <ProgressBar
                restartUpload={restartModelUpload}
                pauseUpload={pauseModelUpload}
                progress={modelProgress}
                isPaused={isModelPaused}
                fileToBeUploaded={model}
              />
            </ComponentTransition>
            <input
              key={model?._id}
              ref={inputModelRef}
              type="file"
              {...inputProps}
              {...props}
              accept=".obj,.glb,.gltf,.fbx"
              onChange={handleChange}
              className={`form-control__upload`}
            />
          </div>
          <div className="form-control__danger">
            {touched && error && <FormattedMessage {...JSON.parse(error)} />}
            {touched && warning && (
              <FormattedMessage {...JSON.parse(warning)} />
            )}
          </div>
          <div className="form-control__helper">{helper}</div>
        </>
      )}
    </div>
  );
};

export default InputUploadField;
