import Modal from "src/components/common/Modal";
import { toAbsoluteUrl } from "src/utils";
import Img from "src/components/common/Img";
import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";

const Draggable3DModelProductForm = ({
  thumbnail,
  modalTitle,
  value,
  modalFormTitle,
  children,
  isLoading,
  generateThumbnailUplaod,
}) => {

  return (
    <Modal.Draggable>
      <Modal.Header>
        <Modal.Img src={toAbsoluteUrl("/media/icons/submit.svg")} />
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="model__modal">
        <div className="form-group">
          <label className="model__label">
            <FormattedMessage id="MODEL.UPLOAD_THUMBNAIL" />
          </label>
          <div className="model__img">
            <Img
              src={
                thumbnail?.src ||
                value?.thumbnail?.completeMultipartUploadResponse?.url ||
                toAbsoluteUrl("/media/icons/image.svg")
              }
              alt="..."
            />
          </div>
          <button
            type="button"
            disabled={isLoading}
            onClick={generateThumbnailUplaod}
            className={
              "btn btn__block btn__primary model__button " +
              (isLoading ? "btn__disabled" : "")
            }
          >
            <FormattedMessage id="MODEL.GENERATE_THUMBNAIL" />
          </button>
        </div>
        <div className="model__spacing" />
        <label className="model__label">{modalFormTitle}</label>
        <div className="form-group spacing__no">{children}</div>
      </Modal.Body>
      <Modal.Footer>
        <button
          disabled={isEmpty(thumbnail) || isLoading}
          type="submit"
          className={
            "btn btn--xl btn__block btn__success " +
            (isEmpty(thumbnail) || isLoading ? "btn__disabled" : "")
          }
        >
          <FormattedMessage id="MODEL.SUBMIT" />
          {isLoading && <div className={`loader sm`}></div>}
        </button>
      </Modal.Footer>
    </Modal.Draggable>
  );
};

export default Draggable3DModelProductForm;
