export const WORKSPACE = "workspace";
export const BRIEF = "brief";

export const PRODUCT = "product";
export const CLIENT = "client";
export const ROUND = "round";
export const LIBRARY = "library";
export const INVITATION = "invite";

export const COMPANY = "company";

export const ANALYTIC_SESSION_GEO_DISTRIBUTION = "analytics_session_geo_distribution";
export const ANALYTIC_SESSION_AVG = "analytics_session_avg";
export const ANALYTIC_SESSION_NUMBER = "analytics_session_number";
export const ANALYTIC_STATISTICS_BY_DEVICE = "analytics_statistcs_by_device";


export const PINPOINT = "pinpoint";
export const DISCUSSION = "discussion";

export const LIBRARY_CONFIG = "library_config";
