import {
  ComponentTransition,
  ObjectAttrDisplayProvider,
  Discussion,
  ObjectAttrDisplay,
} from "src/components/common";
import { ProfileIcon } from "src/components/common";
import moment from "moment";

const DiscussionInfo = ({ object, duration, isFetching, currentUser }) => {
  return (
    <ComponentTransition
      className="col-xs-12 spacing__no spacing--b-1"
      duration={duration}
    >
      <ObjectAttrDisplayProvider object={object} isFetching={isFetching}>
        <Discussion
          position={object?.user?.id !== currentUser?.id ? "left" : "right"}
        >
          <Discussion.Header>
            <Discussion.HeaderIcon>
              <ObjectAttrDisplay
                attr="user"
                className="icon__loader"
                formatter={() => <ProfileIcon />}
              />
            </Discussion.HeaderIcon>
            <Discussion.HeaderInfo>
              <Discussion.HeaderTitle>
                <ObjectAttrDisplay
                  attr="user"
                  formatter={(user) => (
                    <>
                      {user?.firstName} {user?.lastName}
                    </>
                  )}
                />
              </Discussion.HeaderTitle>
              <Discussion.HeaderSubTitle>
                <ObjectAttrDisplay
                  attr="createdAt"
                  formatter={(date) => moment(date).fromNow()}
                />
              </Discussion.HeaderSubTitle>
            </Discussion.HeaderInfo>
          </Discussion.Header>
          <Discussion.Body>
            <ObjectAttrDisplay attr="text" />
          </Discussion.Body>
        </Discussion>
      </ObjectAttrDisplayProvider>
    </ComponentTransition>
  );
};

export default DiscussionInfo;
