const NEUTRAL = "legacy";
const SUNNY_FOREST = "https://modelviewer.dev/shared-assets/environments/spruit_sunrise_1k_HDR.hdr"
//https://modelviewer.dev/shared-assets/environments/music_hall_01_1k.hdr";

export const ENVIRONMENT_LIST = {
  [NEUTRAL]: "INDUSTRY.OPTION.NEUTRAL",
  [SUNNY_FOREST]: "INDUSTRY.OPTION.SUNNY_FOREST",
};

export const environmentUIHelper = (intl) =>
  Object.keys(ENVIRONMENT_LIST).map((environment) => ({
    label: intl.formatMessage({ id: ENVIRONMENT_LIST[environment] }),
    value: environment,
  }));
