import { useSelector, shallowEqual } from "react-redux";

const useIsFormUplading = (form) => {
  const { uploading } = useSelector(
    (state) => ({ uploading: state?.form[form]?.uploading }),
    shallowEqual
  );

  return uploading;
};

export default useIsFormUplading;
