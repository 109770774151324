import { useEffect, useLayoutEffect, useState } from "react";

const useModelViewerLoader = ({ id }) => {
  const [isMounted, setMounted] = useState(false);

  useLayoutEffect(() => {
    const progressBarContainer = document.getElementById(
      "js-progressbar-container"
    );
    if (isMounted && progressBarContainer) {
      progressBarContainer.style.display = "none";
    }
  });

  useEffect(() => {
    const modelViewerContainer = document.getElementById(id);
    if (modelViewerContainer) {
      modelViewerContainer.addEventListener("model-visibility", () => {
        setMounted(true);
      });
    }
    if (modelViewerContainer && !isMounted) {
      modelViewerContainer.style.filter = "blur(11px)";
      modelViewerContainer.style.backgroundColor = "rgb(243, 243, 243)";
      const controls = document.getElementById("js-controls");
      controls.style.opacity = 0;
      const modelViewerVariants = document.querySelector("model-viewer");

      const progressBarContainer = document.getElementById(
        "js-progressbar-container"
      );
      const progressBar = document.getElementById("js-progressbar");
      const progressNumber = document.getElementById("js-progress-number");

      //const isIPhone = navigator.userAgent.includes('iPhone');
      ///const isIPad = navigator.userAgent.includes('iPad') || navigator.userAgent.includes('Macintosh');
      //const isWindows = navigator.userAgent.includes('Windows');
      //const isAndroid = navigator.userAgent.includes('Android');
      //if(isIPhone) elementWidth = 41;
      //if(isIPad) elementWidth = 41;
      //if(isWindows) elementWidth = 15;
      //if(isAndroid) elementWidth = 41;

      modelViewerVariants.addEventListener("progress", (p) => {
        progressBar.setAttribute(
          "style",
          `width: ${(p.detail.totalProgress * 100).toFixed(0)}%`
        );
        modelViewerContainer.style.backgroundColor = "rgb(243, 243, 243)";
        progressNumber.innerHTML = (p.detail.totalProgress * 100).toFixed(0);
        if (p.detail.totalProgress >= 1) {
          progressBarContainer.style.display = "none";
          modelViewerContainer.style.filter = "blur(0px)";
          controls.style.opacity = 1;
          setMounted(true);
        }
      });

      modelViewerVariants.addEventListener("model-visibility", () => {
        progressBarContainer.style.display = "none";
        modelViewerContainer.style.filter = "blur(0px)";
        controls.style.opacity = 1;
        setMounted(true);
      });

      if (isMounted) {
        progressBarContainer.style.display = "none";
        modelViewerContainer.style.filter = "blur(0px)";
        controls.style.opacity = 1;
      }
    }
  });

  return isMounted;
};

export default useModelViewerLoader;
