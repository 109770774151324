import { useState, useEffect, useRef, useMemo } from "react";
import {
  useDoneUploadingAction,
  useStartUploadingAction,
  useUserToken,
} from "src/hooks";
import mime from "mime-types";
import { MultiUpload, toAbsoluteUrl } from "src/utils";
import { isEmpty, isString } from "lodash";
import { useDidMount } from "../common";
import useIsFormUplading from "./useIsFormUplading";

const useHdrEnvironmentUploader = (input, form, hdrEnvironmentResource, onChangeEnvironment=f=>f) => {
  const inputHdrEnvironmentRef = useRef();

  const [hdrEnvironment, setHdrEnvironment] = useState(null)

  const [progress, setHdrEnvironmentProgress] = useState(0);
  const [isPaused, setHdrEnvironmentPaused] = useState(false);
  const [isFetching, setFetching] = useState(true);
  const [isDeleting, setHdrEnvironmentDeleting] = useState(false);

  const startUploading = useStartUploadingAction(form);
  const doneUploading = useDoneUploadingAction(form);
  const isUploading = useIsFormUplading(form);

  const token = useUserToken();

  const { onBlur, value } = input;

  const hasCustomHdrEnvironment = useMemo(() => {
    if (isString(value?.customHdrEnvironment?.completeMultipartUploadResponse?.url)) {
      return true
    }
    return false
  }, [value?.customHdrEnvironment])

  const [hdrEnvironmentPreView, setHdrEnvironmentPreView] = useState(toAbsoluteUrl("/media/icons/image.svg"))

  useEffect(() => {
    const viewer = document.querySelector("#js-model-viewer-uploader");
    
    const handleEnvironmentChange = () => {
      viewer
        .toBlob()
        .then((blob) => {
          const file = new File([blob], `thumbnail.${mime.extension(blob.type)}`);
          const reader = new FileReader();
          reader.onload = function (e) {
            setHdrEnvironmentPreView(() => e.target.result);
          };
          reader.onerror = () => {
            setHdrEnvironmentPreView(toAbsoluteUrl("/media/icons/image.svg"));
          };
          reader.readAsDataURL(file);
        }).finally(() => {
          viewer.addEventListener('environment-change', handleEnvironmentChange);
        });
    };

    handleEnvironmentChange()

    viewer.addEventListener('load', handleEnvironmentChange);

    // Cleanup the event listener when the component unmounts
    return () => {
      viewer.removeEventListener('environment-change', handleEnvironmentChange);
    };
  }, []);

  useDidMount(() => {
    MultiUpload.fetchObjects(hdrEnvironmentResource, token)
      .then((objects) => {
        objects.forEach((obj) => {
          const uploadedFile = new MultiUpload(
            new File([], "test.jpg"),
            null,
            hdrEnvironmentResource,
            token,
            obj
          );
          setHdrEnvironment(uploadedFile)
          onChangeEnvironment(uploadedFile?.completeMultipartUploadResponse?.url)
          onBlur({ ...value, customHdrEnvironment: uploadedFile });
        });
      })
      .finally(() => {
        setFetching(false);
      });
  });

  const handleHdrEnvironmentChange = (e) => {
    const uploadedFile = new MultiUpload(e.target.files[0], null, hdrEnvironmentResource, token)
    setHdrEnvironment(uploadedFile)
    startUploading();
    uploadedFile.start();
    uploadedFile.onProgressChanged = (uploadingSize, totalSize) => {
      setHdrEnvironmentProgress((uploadingSize / totalSize) * 100);
    };
    uploadedFile.onResume = () => {
      setHdrEnvironmentPaused(false);
    };
    uploadedFile.onPaused = () => {
      setHdrEnvironmentPaused(true);
    };
    uploadedFile.onUploadCompleted = () => {
      doneUploading();
      onChangeEnvironment(uploadedFile?.completeMultipartUploadResponse?.url)
      onBlur({ ...value, customHdrEnvironment: uploadedFile });
      setHdrEnvironment(uploadedFile);
      setHdrEnvironmentProgress(0);
    };
  };

  const trigerHdrEnvironmentUpload = () => {
    if (inputHdrEnvironmentRef && inputHdrEnvironmentRef.current) {
      inputHdrEnvironmentRef.current.click();
    }
  };

  const restartHdrEnvironmentUpload = () => {
    if (!isEmpty(hdrEnvironment)) {
      hdrEnvironment.resume();
      setHdrEnvironmentPaused(false);
    }
  };

  const pauseHdrEnvironmentUpload = () => {
    if (!isEmpty(hdrEnvironment)) {
      hdrEnvironment.pause();
      setHdrEnvironmentPaused(true);
    }
  };

  const onReUpload = () => {
    if (!isEmpty(value.hdrEnvironment)) {
      value.hdrEnvironment.remove();
      value.hdrEnvironment.onUploadRemoved = () => {
        onBlur({ ...value, customHdrEnvironment: null });
        setHdrEnvironmentPaused(false);
        trigerHdrEnvironmentUpload();
      };
    }
  };

  const removeHdrEnvironment = () => {
    setHdrEnvironmentDeleting(true);
    if (!isEmpty(hdrEnvironment)) {
      hdrEnvironment.abort();
      setHdrEnvironment(null);
      setHdrEnvironmentDeleting(false);
    }
    if (!isEmpty(value.customHdrEnvironment)) {
      value.customHdrEnvironment.remove();
      value.customHdrEnvironment.onUploadRemoved = () => {
        onBlur({ ...value, customHdrEnvironment: null });
        onChangeEnvironment(null)
        setHdrEnvironmentDeleting(false);
      };
    }
    setHdrEnvironmentProgress(0);
    setHdrEnvironmentPaused(false);
  };

  return {
    restartHdrEnvironmentUpload,
    pauseHdrEnvironmentUpload,
    removeHdrEnvironment,
    isDeleting,
    handleHdrEnvironmentChange,
    onReUpload,
    trigerHdrEnvironmentUpload,
    hdrEnvironment,
    progress,
    isFetching,
    isPaused,
    hdrEnvironmentPreView,
    inputHdrEnvironmentRef,
    isUploading,
    hasCustomHdrEnvironment
  };
};

export default useHdrEnvironmentUploader;
