import PinPointIssues from "./../containers/pinpoint/PinPointIssues";
import PinPointDiscussions from "./../containers/pinpoint/PinPointDiscussions";

export const managerPinPointRoundIssueRoute = {
  path: "/issue",
  component: PinPointIssues,
};

export const managerPinPointIssueDiscussionRoute = {
  path: "/pinpoint/:pinpointParam",
  component: PinPointDiscussions,
};
