export const PENDING = 1;
export const ISSUE = 2;

export const TODO = 3;
export const ACTIVE = 4;
export const REVIEW = 5;
export const REVISION = 6;
export const CLOSED = 7;

export const CLIENT_STATUS_LIST = {
  [parseInt(ISSUE)]: "STATUS.CLIENT.REVIEW",
  [parseInt(PENDING)]: "STATUS.CLIENT.PENDING",
  [parseInt(TODO)]: "STATUS.CLIENT.TODO",
  [parseInt(ACTIVE)]: "STATUS.CLIENT.ACTIVE",
  [parseInt(REVIEW)]: "STATUS.CLIENT.REVIEW",
  [parseInt(REVISION)]: "STATUS.CLIENT.ISSUE",
  [parseInt(CLOSED)]: "STATUS.CLIENT.CLOSED",
};

export const MANAGER_STATUS_LIST = {
  [parseInt(ISSUE)]: "STATUS.MANAGER.REVIEW",
  [parseInt(PENDING)]: "STATUS.MANAGER.PENDING",
  [parseInt(TODO)]: "STATUS.MANAGER.TODO",
  [parseInt(ACTIVE)]: "STATUS.MANAGER.ACTIVE",
  [parseInt(REVIEW)]: "STATUS.MANAGER.REVIEW",
  [parseInt(REVISION)]: "STATUS.MANAGER.TO_CORRECT",
  [parseInt(CLOSED)]: "STATUS.MANAGER.CLOSED",
};

export const DEFAULT_STATUS_LIST = {
  [parseInt(PENDING)]: "STATUS.PENDING",
  [parseInt(TODO)]: "STATUS.TODO",
  [parseInt(ACTIVE)]: "STATUS.ACTIVE",
  [parseInt(REVIEW)]: "STATUS.REVIEW",
  [parseInt(REVISION)]: "STATUS.REVISION",
  [parseInt(CLOSED)]: "STATUS.CLOSED",
};
