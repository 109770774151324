import { ContentLoader } from "../common";
import { isNumber, isString } from "lodash"
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";

import { useMemo, useRef } from "react";

import useModelViewerLoader from "src/hooks/3dmodel/useModelViewerLoader";

const MODEL_VIEWER_ID = "js-model-viewer-editor";

const ModelViewerEditorConfig = ({
  isFetching,
  qrcode,
  src,
  className,
  cameraOrbit,
  poster,
  editorConfig,
  id = MODEL_VIEWER_ID,
}) => {
  const ref = useRef();

  const modelOptions = useMemo(() => {
    const options = {}
    if (isNumber(editorConfig?.shadowIntensity)) {
      options["shadow-intensity"] = editorConfig.shadowIntensity
    }
    if (isNumber(editorConfig?.exposure)) {
      options["exposure"] = editorConfig.exposure
    }
    if (isNumber(editorConfig?.shadowSoftness)) {
      options["shadow-softness"] = editorConfig.shadowSoftness
    }
    if (isString(editorConfig?.environmentImage)) {
      options["environment-image"] = editorConfig.environmentImage
    }
    return options
  }, [editorConfig])

  useModelViewerLoader({ id });

  if (isFetching) {
    return <ContentLoader className={className} />;
  }

  console.log(modelOptions, editorConfig)

  return (
    <div ref={ref} className="model-viewer model-bg">
      <model-viewer
        id={id}
        key={src}
        poster={poster}
        class={className}
        alt=""
        src={src}
        loading="lazy"
        style={{
          backgroundColor: "#F3F3F3",
          width: ref?.current?.clientWidth,
          height: ref?.current?.clientHeight,
        }}
        camera-controls
        {...modelOptions}
        camera-orbit={cameraOrbit}
      >
        {/*<div id="button-load" slot="poster">Load 3D Model</div>
      <div id="lazy-load-poster" style={{ backgroundImage: (poster || toAbsoluteUrl("/media/svg/poster.svg")) }} slot="poster"></div>*/}
        <Controls qrcode={qrcode} />
      </model-viewer>
      <ProgressBar />
    </div>
  );
};

export default ModelViewerEditorConfig;
