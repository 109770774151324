import { useIntl } from "react-intl";

const Paginator = ({ page, pages, onUpdatePage = (f) => f }) => {
  const intl = useIntl();

  if (!pages || pages <= 1) {
    return null;
  }

  return (
    <div className="row col-xs-12 end-xs">
      <div className="spacing--medium" />
      <div className="pagination">
        <ul>
          <li>
            <div
              role="menuitem"
              onClick={() => onUpdatePage(Math.max(page - 1, 1))}
            >
              {intl.formatMessage({ id: "PREVIOUS" })}
            </div>
          </li>

          {new Array(pages).fill(null).map((_, pageNbr) => (
            <li key={pageNbr}>
              <div
                className={page === pageNbr + 1 ? "active" : ""}
                onClick={() => onUpdatePage(pageNbr + 1)}
                role="menuitem"
              >
                {pageNbr + 1}
              </div>
            </li>
          ))}

          <li>
            <div
              role="menuitem"
              onClick={() => onUpdatePage(Math.min(page + 1, pages))}
            >
              {intl.formatMessage({ id: "NEXT" })}
            </div>
          </li>
        </ul>
      </div>
      <div className="spacing--medium" />
    </div>
  );
};

export default Paginator;
