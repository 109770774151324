import { useRef, useMemo } from "react";
import useModelViewerLoader from "src/hooks/3dmodel/useModelViewerLoader";
import { ContentLoader } from "../common/ObjectPath";
import { isNumber, isString } from "lodash"
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";

const MODEL_VIEWER_ID = "js-model-viewer";

const ModelViewer = ({
  src,
  qrcode,
  isFetching,
  className,
  poster,
  cameraOrbit,
  id = MODEL_VIEWER_ID,
  ...props
}) => {
  const ref = useRef();
  useModelViewerLoader({ id });

  const modelOptions = useMemo(() => {
    const options = {}
    if (isNumber(props?.shadowIntensity)) {
      options["shadow-intensity"] = props?.shadowIntensity
    }
    if (isNumber(props?.exposure)) {
      options["exposure"] = props?.exposure
    }
    if (isNumber(props?.shadowSoftness)) {
      options["shadow-softness"] = props?.shadowSoftness
    }
    if (isString(props?.environmentImage)) {
      options["environment-image"] = props?.environmentImage
    }
    return options
  }, [props])

  if (isFetching) {
    return <ContentLoader className={className} />;
  }

  return (
    <div ref={ref} className="model-viewer model-bg">
      <model-viewer
        id={id}
        key={src}
        poster={poster}
        class={className}
        alt=""
        src={src}
        loading="lazy"
        style={{
          backgroundColor: "#F3F3F3",
          width: ref?.current?.clientWidth,
          height: ref?.current?.clientHeight,
        }}
        camera-controls
        {...modelOptions}
        camera-orbit={cameraOrbit}
      >
        {/*<div id="button-load" slot="poster">Load 3D Model</div>
      <div id="lazy-load-poster" style={{ backgroundImage: (poster || toAbsoluteUrl("/media/svg/poster.svg")) }} slot="poster"></div>*/}
        <Controls qrcode={qrcode} />
      </model-viewer>
      <ProgressBar />
    </div>
  );
};

export default ModelViewer;
