const elem = document.documentElement;

const IsFullScreen = () =>
  !!(
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  );

export const toggleFullscreen = () => {
  if (IsFullScreen()) {
    closeFullscreen();
  } else {
    openFullscreen();
  }
};

const HEIGHT = window.innerHeight;

const openFullscreen = () => {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
  elem.querySelector("model-viewer").parentElement.style =
    "width: 100%;position: fixed;z-index: 99999999999999;top: 0;left: 0;right: 0;bottom: 0;height: 100%;";
  console.log(HEIGHT, window.outerHeight);
  elem.querySelector(
    "model-viewer"
  ).style = `position: absolute; top: 0;left: 0;right: 0;bottom: 0;position: absolute;height: ${window.screen.height}px;`;
};

const closeFullscreen = () => {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE11 */
    document.msExitFullscreen();
  }
  elem.querySelector("model-viewer").parentElement.style = "";
  elem.querySelector("model-viewer").style = "";
};
