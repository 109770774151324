import { useState, useRef } from "react";
import {
  useDoneUploadingAction,
  useStartUploadingAction,
  useUserToken,
} from "src/hooks";
import { MultiUpload } from "src/utils";
import mime from "mime-types";
import { isEmpty } from "lodash";
import { useDidMount } from "../common";
import useIsFormUplading from "./useIsFormUplading";

const use3DModelUploader = (input, form, modelResource, thumbnailResource) => {
  const inputModelRef = useRef();

  const [model, setModel] = useState();
  const [thumbnail, setThumbnail] = useState();
  const [modelProgress, setModelProgress] = useState(0);
  const [isModelPaused, setModelPaused] = useState(false);
  const [isFetching, setFetching] = useState(true);
  const [isDeleting, setModelDeleting] = useState(false);

  const startUploading = useStartUploadingAction(form);
  const doneUploading = useDoneUploadingAction(form);
  const isUploading = useIsFormUplading(form);

  const token = useUserToken();

  const { onChange, onBlur, value } = input;

  useDidMount(() => {
    MultiUpload.fetchObjects(thumbnailResource, token)
      .then((objects) => {
        objects.forEach((obj) => {
          const uploadedFile = new MultiUpload(
            new File([], "test.jpg"),
            null,
            modelResource,
            token,
            obj
          );
          uploadedFile.remove()
        });
      })
      .finally(() => {
        setFetching(false);
      });

    MultiUpload.fetchObjects(modelResource, token)
      .then((objects) => {
        objects.forEach((obj) => {
          const uploadedFile = new MultiUpload(
            new File([], "test.jpg"),
            null,
            modelResource,
            token,
            obj
          );
          onBlur({ model: uploadedFile });
        });
      })
      .finally(() => {
        setFetching(false);
      });
  });

  const handleUpload = (uploadedFile) => {
    setModel(uploadedFile);
    startUploading();
    uploadedFile.start();
    uploadedFile.onProgressChanged = (uploadingSize, totalSize) => {
      setModelProgress((uploadingSize / totalSize) * 100);
    };
    uploadedFile.onResume = () => {
      setModelPaused(false);
    };
    uploadedFile.onPaused = () => {
      setModelPaused(true);
    };
    return uploadedFile;
  };

  const handleChange = (e) => {
    const uploadedFile = handleUpload(
      new MultiUpload(e.target.files[0], null, modelResource, token)
    );
    uploadedFile.onUploadCompleted = () => {
      doneUploading();
      onBlur({ model: uploadedFile });
      setModel(null);
      setModelProgress(0);
    };
  };

  const trigerModelUpload = () => {
    if (inputModelRef && inputModelRef.current) {
      inputModelRef.current.click();
    }
  };

  const restartModelUpload = () => {
    if (!isEmpty(model)) {
      model.resume();
      setModelPaused(false);
    }
  };

  const pauseModelUpload = () => {
    if (!isEmpty(model)) {
      model.pause();
      setModelPaused(true);
    }
  };

  const onReUpload = () => {
    if (!isEmpty(value.model)) {
      value.model.remove();
      value.model.onUploadRemoved = () => {
        setThumbnail(null);
        onBlur({});
        setModelPaused(false);
        trigerModelUpload();
      };
    }
  };

  const removeModel = () => {
    const viewer = document.querySelector("#js-model-viewer-uploader");
    viewer.style.filter = "blur(11px)";
    setModelDeleting(true);
    if (!isEmpty(model)) {
      model.abort();
      setModel(null);
      setModelDeleting(false);
      viewer.style.filter = "blur(0px)";
    } else if (!isEmpty(value.model)) {
      value.model.remove();
      value.model.onUploadRemoved = () => {
        setThumbnail(null);
        onBlur({});
        setModelDeleting(false);
        viewer.style.filter = "blur(0px)";
      };
    }
    setModelProgress(0);
    setModelPaused(false);
  };

  const generateThumbnailUplaod = () => {
    const viewer = document.querySelector("#js-model-viewer-uploader");
    viewer
      .toBlob()
      .then((blob) => {
        const file = new File([blob], `thumbnail.${mime.extension(blob.type)}`);
        const reader = new FileReader();
        reader.onload = function (e) {
          const thumbnailUploader = new MultiUpload(
            file,
            e.target.result,
            thumbnailResource,
            token
          );
          setThumbnail(() => thumbnailUploader);
          const cameraOrbit = viewer.getCameraOrbit();
          onBlur({
            ...(value || {}),
            thumbnail: thumbnailUploader,
            thumbnailStartUploadCallback: () => handleUpload(thumbnailUploader),
            thumbnailCompleteUploadCallback: () => doneUploading(),
            cameraOrbit: cameraOrbit.toString(),
          });
        };
        reader.onerror = () => {
          setThumbnail(null);
        };
        reader.readAsDataURL(file);
      })
      .then(() => {
        viewer.style.filter = "blur(0px)";
      });
  };

  const onDescriptionChange = (e) => {
    onChange({ ...(value || {}), description: e.currentTarget.value });
  };

  return {
    restartModelUpload,
    pauseModelUpload,
    removeModel,
    isDeleting,
    handleChange,
    onReUpload,
    trigerModelUpload,
    model,
    generateThumbnailUplaod,
    onDescriptionChange,
    modelProgress,
    isFetching,
    isModelPaused,
    thumbnail,
    inputModelRef,
    isUploading,
  };
};

export default use3DModelUploader;
