import { useRestAPIPaginateCollection } from "src/hooks";
import { ENDPOINTS, RESOURCES } from "src/store/constants";

const useIssuePagination = () => {
  const {
    loadItems,
    isFetching: isFetchingPinPoint,
    items = [],
    ...props
  } = useRestAPIPaginateCollection(RESOURCES.PINPOINT, ENDPOINTS.PINPOINTS);

  return {
    onFetch: loadItems,
    pinpoints: items,
    isFetchingPinPoint,
    ...props,
  };
};

export default useIssuePagination;
