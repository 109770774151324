import React from "react";
import { AR, EN, FR, useLang } from "./I18n";
import { IntlProvider } from "react-intl";

import frMessages from "./locales/fr/translation.json";
import arMessages from "./locales/ar/translation.json";
import enMessages from "./locales/en/translation.json";

const allMessages = {
  [FR]: frMessages,
  [AR]: arMessages,
  [EN]: enMessages,
};

export const I18nProvider = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};
