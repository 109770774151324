import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  ProfileIcon,
  Issue,
  ComponentTransition,
  ObjectAttrDisplayProvider,
  ObjectAttrDisplay,
} from "src/components/common";
import { toAbsoluteUrl } from "src/utils";
import moment from "moment";
import { pinpointRoutes, workspacePinpointRoutes } from "../../routes";

const PinPointIssueInfo = (props) => {
  const {
    onToggle,
    object,
    duration,
    isFetching,
    briefParam,
    workspaceParam,
    roundParam,
    viewAsList = false,
  } = props;

  const history = useHistory();
  const goToDiscussion = () => {
    if (workspaceParam && briefParam && roundParam) {
      history.push({
        pathname:
          workspacePinpointRoutes.managerPinPointIssueDiscussionRoute.path
            .replace(":param", briefParam)
            .replace(":roundParam", roundParam)
            .replace(":workspaceParam", workspaceParam)
            .replace(":pinpointParam", object?.id),
        state: { doNotAnnimate: true },
      });
    } else if (briefParam && roundParam) {
      history.push({
        pathname: pinpointRoutes.managerPinPointIssueDiscussionRoute.path
          .replace(":param", briefParam)
          .replace(":roundParam", roundParam)
          .replace(":pinpointParam", object?.id),
        state: { doNotAnnimate: true },
      });
    }
  };

  return (
    <ComponentTransition className="col-xs-12 spacing__no" duration={duration}>
      <ObjectAttrDisplayProvider object={object} isFetching={isFetching}>
        <Issue className="content__divider" onClick={goToDiscussion}>
          <Issue.Header className={viewAsList ? "issue__divider" : ""}>
            <Issue.HeaderIcon>
              <ObjectAttrDisplay
                attr="user"
                className="icon__loader"
                formatter={() => <ProfileIcon />}
              />
            </Issue.HeaderIcon>
            <Issue.HeaderInfo>
              <Issue.HeaderTitle>
                <ObjectAttrDisplay
                  attr="user"
                  formatter={(user) => (
                    <>
                      {user?.firstName} {user?.lastName}
                    </>
                  )}
                />
              </Issue.HeaderTitle>
              <Issue.HeaderSubTitle>
                <ObjectAttrDisplay
                  attr="createdAt"
                  formatter={(date) => moment(date).fromNow()}
                />
              </Issue.HeaderSubTitle>
            </Issue.HeaderInfo>
            <Issue.Toolbar>
              {viewAsList ? (
                <>
                  <Issue.ToolbarItem>
                    <FormattedMessage id="ISSUE.TITLE" /> #{" "}
                    <ObjectAttrDisplay
                      className="small_text__loader"
                      attr="pinpointNumber"
                      defaultVal={0}
                    />
                  </Issue.ToolbarItem>
                  <Issue.ToolbarIcon
                    src={toAbsoluteUrl("/media/icons/comment.svg")}
                  />
                </>
              ) : (
                <Issue.ToolbarIcon
                  background="muted"
                  onClick={onToggle}
                  src={toAbsoluteUrl("/media/icons/close.svg")}
                />
              )}
            </Issue.Toolbar>
          </Issue.Header>
          <Issue.Body>
            <ObjectAttrDisplay attr="comment" />
          </Issue.Body>
        </Issue>
      </ObjectAttrDisplayProvider>
    </ComponentTransition>
  );
};

export default PinPointIssueInfo;
