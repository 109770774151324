import {
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { useOutsideAlerter } from "src/hooks";
import { AnimatePresence } from "framer-motion";
import { Img, OrderDirection } from ".";
import ComponentTransition from "./ComponentTransition";
import { v4 as uuidv4 } from "uuid";

const DropdownContext = createContext(null);
const useDropdownContext = () => useContext(DropdownContext);

const Dropdown = ({
  children,
  dropdownId = uuidv4(),
  className = "dropdown",
}) => {
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(false);

  const value = {
    selectedItem,
    setSelectedItem,
    show,
    dropdownId,
    setShow,
  };

  return (
    <DropdownContext.Provider value={value}>
      <div className={className}>{children}</div>
    </DropdownContext.Provider>
  );
};

const ToggleAction = ({ children, props }) => {
  const { setShow, dropdownId, show } = useDropdownContext();

  return (
    <button
      onClick={() => setShow(!show)}
      className="btn btn__dropdown dropdown-toggle"
      id={dropdownId}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};

const Toggle = ({ children, defaultItem, props }) => {
  const { setShow, show, selectedItem, dropdownId } = useDropdownContext();

  return (
    <button
      onClick={() => setShow(!show)}
      className="btn btn__dropdown dropdown-toggle"
      id={dropdownId}
      type="button"
      {...props}
    >
      {children}{" "}
      <AnimatePresence exitBeforeEnter>
        <ComponentTransition
          duration={0.2}
          element="span"
          className="text--bold"
        >
          {selectedItem ? selectedItem : defaultItem}
        </ComponentTransition>
      </AnimatePresence>
    </button>
  );
};

const ToggleComponent = ({ children, props }) => {
  const { setShow, show, dropdownId } = useDropdownContext();

  return cloneElement(children, {
    ...props,
    onClick: () => setShow(!show),
    id: dropdownId,
    className: "dropdown-toggle-img",
  });
};

const ToggleImg = ({ src, props }) => {
  const { setShow, show, dropdownId } = useDropdownContext();

  return (
    <Img
      src={src}
      alt="..."
      onClick={() => setShow(!show)}
      className="dropdown-toggle-img"
      id={dropdownId}
      {...props}
    />
  );
};

const Menu = ({ children, ...props }) => {
  const { show, setShow, dropdownId } = useDropdownContext();

  const ref = useRef();

  useOutsideAlerter(ref, dropdownId, () => setShow(false));

  return (
    <div ref={ref} {...props} className={`dropdown-menu ${show ? "show" : ""}`}>
      <ul>{children}</ul>
    </div>
  );
};

const Action = ({ children, ...props }) => {
  const { show, setShow, dropdownId } = useDropdownContext();

  const ref = useRef();

  useOutsideAlerter(ref, dropdownId, () => setShow(false));

  return (
    <div
      ref={ref}
      {...props}
      className={`dropdown-menu dropdown-menu-action ${show ? "show" : ""}`}
    >
      <ul>{children}</ul>
    </div>
  );
};

const List = ({ children, ...props }) => {
  const { show, setShow, dropdownId } = useDropdownContext();

  const ref = useRef();

  useOutsideAlerter(ref, dropdownId, () => setShow(false));

  return (
    <ul
      ref={ref}
      className={`dropdown-menu dropdown-menu-list ${show ? "show" : ""}`}
      {...props}
    >
      {children}
    </ul>
  );
};

const ListItem = ({
  children,
  active = false,
  onClick,
  to,
  choice,
  ...props
}) => {
  const history = useHistory();
  const { setShow } = useDropdownContext();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (to) {
      history.push(to);
    }
    setShow(false);
  };

  return (
    <li onClick={handleClick} {...props}>
      {children}
    </li>
  );
};

const Divider = () => <hr />;

const LinkMenu = ({ children, ...props }) => {
  const { show, setShow, dropdownId } = useDropdownContext();

  const ref = useRef();

  useOutsideAlerter(ref, dropdownId, () => setShow(false));

  return (
    <ul
      ref={ref}
      className={`dropdown-menu dropdown-menu-link ${show ? "show" : ""}`}
      {...props}
    >
      {children}
    </ul>
  );
};

const Item = ({ children, className, active = false, onClick, to, choice, ...props }) => {
  const history = useHistory();
  const { setShow, setSelectedItem, selectedItem } = useDropdownContext();

  useEffect(() => {
    if (active) {
      setSelectedItem(choice);
    }
  }, [active, choice, setSelectedItem]);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (to) {
      history.push(to);
    }
    if (selectedItem !== children) {
      setShow(false);
      setSelectedItem(choice);
    }
  };

  return (
    <li>
      <span className={ "dropdown-item " + className } onClick={handleClick} {...props}>
        {children} {choice}
      </span>
    </li>
  );
};

const OrderByItem = ({
  children,
  active = false,
  onOrderBy,
  query,
  attr,
  choice,
  ...props
}) => {
  const { setShow, setSelectedItem, selectedItem } = useDropdownContext();

  useEffect(() => {
    if (
      (!isEmpty(query?.orderby) && query?.orderby?.includes(attr)) ||
      (isEmpty(query?.orderby) && active)
    ) {
      setSelectedItem(
        <>
          {choice} <OrderDirection orderBy={query?.orderby} />
        </>
      );
    }
    // eslint-disable-next-line
  }, [query, attr, active]);

  const handleClick = () => {
    if (onOrderBy) {
      onOrderBy({
        orderby: `${attr}__${query?.orderby?.includes("asc") ? "desc" : "asc"}`,
      });
    }
    if (selectedItem !== children) {
      setShow(false);
      setSelectedItem(choice);
    }
  };

  return (
    <li>
      <span className="dropdown-item" onClick={handleClick} {...props}>
        {children} {choice}
      </span>
    </li>
  );
};

const ListHeader = ({ title, icon }) => {
  return (
    <li className="dropdown-list-header">
      <div className="dropdown-list-header-content">
        <span className="dropdown-list-header-content__title">{title}</span>
        <Img
          className="dropdown-list-header-content__icon"
          src={icon}
          alt="..."
        />
      </div>
    </li>
  );
};

const EmptyListItem = ({ icon, title, subtitle, to, onClick, ...props }) => {
  const history = useHistory();
  const { setShow } = useDropdownContext();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (to) {
      history.push(to);
    }
    setShow(false);
  };

  return (
    <li className="dropdown-list-no-item" onClick={handleClick} {...props}>
      <div>
        <Img className="dropdown-list-no-item__icon" src={icon} alt="..." />
        <h6 className="dropdown-list-no-item__title">{title}</h6>
        <p className="dropdown-list-no-item__description">{subtitle}</p>
      </div>
    </li>
  );
};

const Link = ({ children, to, onClick, ...props }) => {
  const history = useHistory();
  const { setShow } = useDropdownContext();

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (to) {
      history.push(to);
    }
    setShow(false);
  };

  return (
    <li>
      <span className="dropdown-item" onClick={handleClick} {...props}>
        {children}
      </span>
    </li>
  );
};

Dropdown.Action = Action;
Dropdown.Toggle = Toggle;
Dropdown.ToggleAction = ToggleAction;
Dropdown.Menu = Menu;
Dropdown.Divider = Divider;
Dropdown.LinkMenu = LinkMenu;
Dropdown.Item = Item;
Dropdown.OrderByItem = OrderByItem;
Dropdown.Link = Link;
Dropdown.List = List;
Dropdown.ListHeader = ListHeader;
Dropdown.ListItem = ListItem;
Dropdown.EmptyListItem = EmptyListItem;
Dropdown.ToggleComponent = ToggleComponent;
Dropdown.ToggleImg = ToggleImg;

export default Dropdown;
