import { combineReducers } from "redux";

// app reducers
import authenticationReducer from "./authenticationReducer";
import commonReducer from "./commonReducer";
import restApiReducer from "./restApiReducer";
import formReducer from "./formReducerExtension";
import notificationReducer from "./notificationReducer";

// combine app reducers
export default combineReducers({
  form: formReducer,
  notif: notificationReducer,
  rest: restApiReducer,
  authentication: authenticationReducer,
  common: commonReducer,
});
