import {
  clientBriefShowRoute,
  clientWorkspaceBriefShowRoute,
} from "src/modules/client/routes";
import { combinePathRoutes } from "src/utils";

import * as clientBriefRoutes from "./briefRoutes";
import * as clientPinpointRoutes from "./pinpointRoutes";

export const briefRoutes = combinePathRoutes(
  { path: clientBriefShowRoute.path },
  clientBriefRoutes
);
export const pinpointRoutes = combinePathRoutes(
  { path: briefRoutes.clientRoundInfoRoute.path },
  clientPinpointRoutes
);

export const workspaceBriefRoutes = combinePathRoutes(
  { path: clientWorkspaceBriefShowRoute.path },
  clientBriefRoutes
);
export const workspacePinpointRoutes = combinePathRoutes(
  { path: workspaceBriefRoutes.clientRoundInfoRoute.path },
  clientPinpointRoutes
);
