import { createContext, useContext, useState } from "react";

const CardContext = createContext(null);
const useCardContext = () => useContext(CardContext);

const Card = ({ children }) => {
  const [show, setShow] = useState(true);

  const value = {
    show,
    setShow,
  };

  return (
    <CardContext.Provider value={value}>
      <div className="card">{children}</div>
    </CardContext.Provider>
  );
};

const Toolbar = ({ children, className, ...props }) => (
  <div className={`card__toolbar ${className}`} {...props}>
    {children}
  </div>
);

const Header = ({ children, className, ...props }) => (
  <div className={`card__header ${className}`} {...props}>
    {children}
  </div>
);

const HeaderIconText = ({ children, className, ...props }) => (
  <div className={`card__header__icon ${className}`} {...props}>
    <span className="card__header__icon__text">{children}</span>
  </div>
);

const HeaderInfo = ({ children, className, ...props }) => (
  <div className={`card__header__info ${className}`} {...props}>
    {children}
  </div>
);

const HeaderTitle = ({ children, className, ...props }) => (
  <div className={`card__header__info__title ${className}`} {...props}>
    {children}
  </div>
);

const HeaderSubTitle = ({ children, className, ...props }) => (
  <div className={`card__header__info__subtitle ${className}`} {...props}>
    {children}
  </div>
);

const Footer = ({ children, className, ...props }) => (
  <div
    className={`card__footer spacing--big-small border--top ${className}`}
    {...props}
  >
    {children}
  </div>
);

const FooterItem = ({ children, className, ...props }) => (
  <div className={`card__footer__text ${className}`} {...props}>
    {children}
  </div>
);

const Body = ({ children, ...props }) => {
  const { show } = useCardContext();

  return (
    <div className={`card__body ${show ? "show" : ""}`} {...props}>
      {children}
    </div>
  );
};

Card.Header = Header;
Card.Toolbar = Toolbar;
Card.HeaderInfo = HeaderInfo;
Card.HeaderIconText = HeaderIconText;
Card.HeaderSubTitle = HeaderSubTitle;
Card.HeaderTitle = HeaderTitle;
Card.Body = Body;
Card.Footer = Footer;
Card.FooterItem = FooterItem;

export default Card;
