import { ACTIONS } from "./../constants";

const initialState = {
  isLoading: false,
  isLastActionSuccessfull: false,
  error: {
    dispatchedAction: null,
    payload: null,
  },
  errorQueue: [],
};

const flashMessageReducer = (state = initialState, action) => {
  const { payload, type, dispatchedAction } = action;

  let newState = state;

  if (ACTIONS.CLEAR_STATE === type) {
    newState = { ...state, ...initialState };
  } else if (ACTIONS.FLASH_ERROR === type) {
    newState = {
      ...state,
      errorQueue: state.errorQueue.length
        ? state.errorQueue.slice(1)
        : state.errorQueue,
    };
  } else if (ACTIONS.FAILED === type) {
    const error = { dispatchedAction, payload };
    newState = { ...state, errorQueue: [...state.errorQueue, error] };
  }

  if (newState?.errorQueue?.length) {
    newState = { ...newState, error: newState.errorQueue[0] };
  } else {
    newState = {
      ...newState,
      error: { dispatchedAction: null, payload: null },
    };
  }

  return newState;
};

export default flashMessageReducer;
