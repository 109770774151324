import { FormattedMessage } from "react-intl";

const ProgressBar = () => {
  return (
    <>
      <div className="model-viewer__progress-bar" id="js-progressbar-container">
        <div className="model-viewer__progress-bar__title">
          <span>
            <FormattedMessage id="LOADING" />
          </span>{" "}
          <span
            className="model-viewer__progress-bar__title__indicator"
            id="js-progress-number"
          >
            0
          </span>{" "}
          <span className="model-viewer__progress-bar__title__indicator">
            %
          </span>
        </div>
        <div className="model-viewer__progress-bar__section">
          <div className="progress">
            <div
              className="progress__bar progress__bar__success"
              role="progressbar"
              id="js-progressbar"
              style={{ width: `0%` }}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
