import { FormattedMessage } from "react-intl";
import { MANAGER_STATUS_LIST } from "src/uiHelper/status";

const FormattedStatus = ({ className = "status", status = 0 }) => {
  return (
    <span className={`${className} ${className}-${status}`}>
      <FormattedMessage
        id={MANAGER_STATUS_LIST[status] || "STATUS.MANAGER.PENDING"}
      />
    </span>
  );
};

export default FormattedStatus;
