import { useSelector as useBaseSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

export const useSelector = (callback) =>
  useBaseSelector(callback, shallowEqual);

export const useCurrentUser = () => {
  const { currentUser } = useSelector((state) => ({
    currentUser: state.authentication.currentUser,
  }));

  return currentUser;
};

export const useCurrentUserPermission = () => {
  const { permissions, isSuperAdmin, isClient, isManager } = useSelector((state) => ({
    permissions: new Set(Array.isArray(state.authentication.permissions) ? state.authentication.permissions : []),
    isSuperAdmin: state.authentication.isSuperAdmin,
    isClient: state.authentication.isClient,
    isManager: state.authentication.isManager,
  }));

  const hasPermission = (permission) => {
    if (permissions instanceof Set){
      return permissions.has(permission)
    }
    return false
  } 

  return { permissions, hasPermission, isSuperAdmin, isClient, isManager }
};

export const useWaitForLogin = () => {
  const { waitForLogin } = useSelector((state) => ({
    waitForLogin: state.authentication.waitForLogin,
  }));

  return waitForLogin;
};

export const useWaitForRegistration = () => {
  const { waitForRegistration } = useSelector((state) => ({
    waitForRegistration: state.authentication.waitForRegistration,
    registrationVerfication: state.authentication.registrationVerfication
  }));

  return waitForRegistration;
};

export const useWaitForForgetPassword = () => {
  const { waitForForgetPassword } = useSelector((state) => ({
    waitForForgetPassword: state.authentication.waitForForgetPassword,
  }));

  return waitForForgetPassword;
};

export const useSuccessfulLogin = () => {
  const { successfulLogin } = useSelector((state) => ({
    successfulLogin: state.authentication.successfulLogin,
  }));

  return successfulLogin;
};

export const useOnLoginRedirection = (location) => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();

  useEffect(() => {
    if (isAuthenticated) {
      history.push(location);
    }
  }, [history, isAuthenticated, location]);

  return null;
};

export const useOnLogoutRedirection = (location) => {
  const isAuthenticated = useIsAuthenticated();
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push(location);
    }
  }, [history, isAuthenticated, location]);

  return null;
};

export const useSuccessfulRegistration = () => {
  const { successfulRegistration } = useSelector((state) => ({
    successfulRegistration: state.authentication.successfulRegistration,
  }));

  return successfulRegistration;
};

export const useSuccessfulForgetPassword = () => {
  const { successfulForgetPassword } = useSelector((state) => ({
    successfulForgetPassword: state.authentication.successfulForgetPassword,
  }));

  return successfulForgetPassword;
};

export const useIsSuperUser = () => {
  const { isSuperUser } = useSelector((state) => ({
    isSuperUser: state.authentication.isSuperUser,
  }));

  return isSuperUser;
};

export const useIsAuthenticated = () => {
  const { isAuthenticated } = useSelector((state) => ({
    isAuthenticated: state.authentication.isAuthenticated,
  }));

  return isAuthenticated;
};

export const useRedirectIfIsNotClient = (location) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const isClient = useSelector(state => state.authentication.isClient);
  const history = useHistory();

  useEffect(() => {
    if (!isClient || !isAuthenticated) {
      history.push(location);
    }
  }, [isClient, isAuthenticated, history, location]);
};

export const useRedirectIfIsNotManager = (location) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const isManager = useSelector(state => state.authentication.isManager);
  const history = useHistory();

  useEffect(() => {
    if (!isManager || !isAuthenticated) {
      history.push(location);
    }
  }, [isManager, isAuthenticated, history, location]);
};

export const useRedirectIfIsClient = (location) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const isClient = useSelector(state => state.authentication.isClient);
  const history = useHistory();

  useEffect(() => {
    if (isClient && isAuthenticated) {
      history.push(location);
    }
  }, [isClient, isAuthenticated, history, location]);
};

export const useRedirectIfIsManager = (location) => {
  const isAuthenticated = useSelector(state => state.authentication.isAuthenticated);
  const isManager = useSelector(state => state.authentication.isManager);
  const history = useHistory();

  useEffect(() => {
    if (isManager && isAuthenticated) {
      history.push(location);
    }
  }, [isManager, isAuthenticated, history, location]);
};

export const useUserToken = () => {
  const { token } = useSelector((state) => ({
    token: state.authentication.token,
  }));

  return token;
};
