/* eslint-disable no-useless-escape */

import { toAbsoluteUrl } from "src/utils";

export const MIME_TYPE = {
  "text/css": toAbsoluteUrl("/media/files/css.svg"),
  "text/csv": toAbsoluteUrl("/media/files/csv.svg"),
  "application/msword|application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    toAbsoluteUrl("/media/files/doc.svg"),
  "text/html": toAbsoluteUrl("/media/files/html.svg"),
  "text/javascript": toAbsoluteUrl("/media/files/javascript.svg"),
  "video/mp4": toAbsoluteUrl("media/files/mp4.svg"),
  "application/pdf": toAbsoluteUrl("/media/files/pdf.svg"),
  "application/zip": toAbsoluteUrl("/media/files/zip.svg"),
  xml: toAbsoluteUrl("/media/files/xml.svg"),
  ".*": toAbsoluteUrl("/media/files/any.svg"),
};

export const IMG_FILE = toAbsoluteUrl("/media/files/img.svg");

export const ANY_FILE = toAbsoluteUrl("/media/files/any.svg");

export const mimeTypeImgUiHelper = (mimeType) =>
  MIME_TYPE[
    Object.keys(MIME_TYPE).find((mimeTypePattern) =>
      new RegExp(mimeTypePattern).test(mimeType)
    ) || ANY_FILE
  ];
