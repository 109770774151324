import { useRestAPIPaginateCollection } from "src/hooks";
import { ENDPOINTS, RESOURCES } from "src/store/constants";

const useCompanyPagination = () => {
  const { loadItems, items, ...paginationProps } = useRestAPIPaginateCollection(
    RESOURCES.COMPANY,
    ENDPOINTS.COMPANYS
  );

  return { loadItems, items, ...paginationProps };
};

export default useCompanyPagination;
