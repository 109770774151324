import { NotFound, PermissionDenied } from "src/components/error";
import RouteTransition from "./RouteTransition";
import { Switch as BaseSwitch, Route, Redirect } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { memo, useMemo } from "react";
import { useCurrentUserPermission } from "src/hooks";

const Switch = ({
  basePath,
  defaultComponent,
  defaultPath,
  defaultPermission,
  routes,
  defaultRoutes = [],
  checkPermission = false,
  ...props
}) => {
  const { hasPermission, isSuperAdmin } = useCurrentUserPermission();

  const checkedDefaultPath = useMemo(() => {
    if (!checkPermission) {
      return defaultPath;
    }
    if (isSuperAdmin || hasPermission(defaultPermission)) {
      return defaultPath;
    }
    for (let defaultRoute of defaultRoutes){
      if (hasPermission(defaultRoute.permission)) {
        return defaultRoute.path;
      }
    }
    return defaultPath;
  }, [
    checkPermission,
    defaultRoutes,
    isSuperAdmin,
    defaultPath,
    hasPermission,
    defaultPermission,
  ]);

  return (
    <AnimatePresence exitBeforeEnter>
      <BaseSwitch>
        {basePath && (
          <Route
            exact
            path={basePath}
            render={() => <Redirect from={basePath} to={checkedDefaultPath} />}
          />
        )}
        {defaultComponent && (
          <Route exact path={basePath} render={defaultComponent} />
        )}
        {checkPermission
          ? Object.keys(routes).map((key) => ((isSuperAdmin || hasPermission(routes[key]?.permission)) ? (
                <RouteTransition key={key} {...routes[key]} {...props} />
              ) : (
          <RouteTransition key={key} {...routes[key]} component={PermissionDenied} />
              )
            ))
          : Object.keys(routes).map((key) => (
              <RouteTransition key={key} {...routes[key]} {...props} />
            ))}
        <RouteTransition component={NotFound} />
      </BaseSwitch>
    </AnimatePresence>
  );
};

export default memo(Switch);
