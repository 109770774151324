import { useState } from "react";
import { subDays, format } from "date-fns";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Button } from ".";

const RangeDatePicker = ({ onChange = (f) => f }) => {
  const [range, setRange] = useState({
    endDate: new Date(),
    startDate: subDays(new Date(), 7),
  });
  const [state, setState] = useState([
    {
      ...range,
      key: "selection",
    },
  ]);

  const buttonLabel = `${format(range.startDate, 'dd LLL yyyy')} - ${format(range.endDate, 'dd LLL yyyy')}`;

  const [isPickerVisible, setIsPickerVisible] = useState(false);

  const onChangeRange = (item) => {
    setRange({ ...item.selection });
    setState([item.selection]);
    onChange(item.selection);
  };

  return (
    <div className="range-picker__container">
      <Button onClick={() => setIsPickerVisible(!isPickerVisible)}>
        {buttonLabel}
      </Button>
      {isPickerVisible && (
        <div className="range-picker--show">
          <DateRangePicker
            onChange={onChangeRange}
            showSelectionPreview={false}
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            maxDate={new Date()}
          />
        </div>
      )}
    </div>
  );
};

export default RangeDatePicker;
