import { useState } from "react";
import { isString } from "lodash";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

import eyeIcon from "src/assets/img/eye.svg";
import noEyeIcon from "src/assets/img/no-eye.svg";
import { Img } from "../common";

const InputField = ({
  input,
  helper,
  required = true,
  label,
  icon,
  placeholder,
  size = "col-xs-12",
  className = "form-control",
  type,
  meta: { touched, error, warning },
  ...props
}) => {
  const intl = useIntl();
  const [inputType, setInputType] = useState(type);

  const onPickOnPassword = () => {
    if (inputType === "text") {
      setInputType("password");
    } else {
      setInputType("text");
    }
  };

  return (
    <div className={size}>
      {label && (
        <label className="control-label">
          {label} {!required && <FormattedMessage id="FIELD.OPTIONAL" />}
        </label>
      )}
      <div className="form-group">
        <div className="form-group-input">
          {icon && (
            <div className="form-group-input__addon">
              <Img src={icon} alt="..." />
            </div>
          )}
          <input
            {...input}
            {...props}
            className={`form-control ${touched && error && "has-danger"}`}
            type={inputType}
            placeholder={
              placeholder +
              (!required ? intl.formatMessage({ id: "FIELD.OPTIONAL" }) : "")
            }
            autoComplete="off"
          />
          {type === "password" && (
            <div onClick={onPickOnPassword} className="form-control__password">
              <Img src={inputType !== "text" ? eyeIcon : noEyeIcon} alt="..." />
            </div>
          )}
        </div>
        <div className="form-control__danger">
          {touched && isString(error) && (
            <FormattedMessage {...JSON.parse(error)} />
          )}
          {touched && warning && <FormattedMessage {...JSON.parse(warning)} />}
        </div>
        <div className="form-control__helper">{helper}</div>
      </div>
    </div>
  );
};

InputField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

export default InputField;
