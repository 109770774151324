import { useRestAPIPerformActionResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useCancelDeclineProduct = () => {
  const { onSubmit, isLoading } = useRestAPIPerformActionResource(
    RESOURCES.BRIEF,
    ENDPOINTS.BRIEF_ISSUE_CANCEL
  );

  return {
    onCancelDeclineProduct: onSubmit,
    isCancelDeclineProduct: isLoading,
  };
};

export default useCancelDeclineProduct;
