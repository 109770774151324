import { useEffect, useState } from "react";
import { isEqual, isFunction } from "lodash";

import Elements from "./Elements";
import ShowMore from "./ShowMore";

const LoadMore = ({
  itemComponent,
  noDataComponent,

  isFetching = false,

  items = [],

  onFetch,
  params,
  query,
  page,
  pages,
  totalSize,
  itemPerPage = 10,

  ...props
}) => {
  const [latestQuery, setLatestQuery] = useState(undefined);

  useEffect(() => {
    if (!isEqual(query, latestQuery) && isFunction(onFetch)) {
      onFetch(params, query);
      setLatestQuery(query);
    }

    // eslint-disable-next-line
  }, [onFetch, query]);

  const onUpdatePage = (page) => {
    onFetch(params, { ...query, page });
  };

  return (
    <div className="paginate--section">
      <Elements
        isFetching={isFetching}
        items={items}
        itemComponent={itemComponent}
        noDataComponent={noDataComponent}
        {...props}
      />
      <ShowMore
        page={page}
        pages={pages}
        isFetching={isFetching}
        totalSize={totalSize}
        itemPerPage={itemPerPage}
        onUpdatePage={onUpdatePage}
      />
    </div>
  );
};

export default LoadMore;
