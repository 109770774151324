import {
  managerBriefShowRoute,
  managerWorkspaceBriefShowRoute,
} from "src/modules/manager/routes";
import { combinePathRoutes } from "src/utils";

import * as managerBriefRoutes from "./briefRoutes";
import * as managerPinpointRoutes from "./pinpointRoutes";

export const briefRoutes = combinePathRoutes(
  { path: managerBriefShowRoute.path },
  managerBriefRoutes
);
export const pinpointRoutes = combinePathRoutes(
  { path: briefRoutes.managerRoundInfoRoute.path },
  managerPinpointRoutes
);

export const workspaceBriefRoutes = combinePathRoutes(
  { path: managerWorkspaceBriefShowRoute.path },
  managerBriefRoutes
);
export const workspacePinpointRoutes = combinePathRoutes(
  { path: workspaceBriefRoutes.managerRoundInfoRoute.path },
  managerPinpointRoutes
);
