import { lazy } from "react";
import { CLIENT_PERMISSIONS as PERMISSIONS } from "src/uiHelper/permission";

const CollaborationList = lazy(() =>
  import("./pages/collaboration/ListWorkspace")
);
const BriefList = lazy(() => import("./pages/collaboration/ListBrief"));

const BriefCreate = lazy(() => import("./pages/collaboration/CreateBrief"));
const BriefEdit = lazy(() => import("./pages/collaboration/EditBrief"));
const BriefShow = lazy(() => import("./pages/collaboration/ShowBrief"));
const ShowBriefWithWorkspace = lazy(() =>
  import("./pages/collaboration/ShowBriefWithWorkspace")
);

const WorkspaceCreateAndEdit = lazy(() =>
  import("./pages/collaboration/CreateAndEditWorkspace")
);

const LibraryList = lazy(() => import("./pages/library/List"));
const LibraryConfig = lazy(() => import("./pages/library/Config"));
const UploaadProduct = lazy(() => import("./pages/library/UploadProduct"));

const PeopleList = lazy(() => import("./pages/people/List"));
const PeopleCreateAndEdit = lazy(() => import("./pages/people/CreateAndEdit"));

const Analytics = lazy(() => import("./pages/analytics/Index"));

export const clientAnalyticsRoute = {
  path: "/client/analytics",
  component: Analytics,
  permission: PERMISSIONS.ANALYTICS_READ,
};

export const clientLibraryConfigRoute = {
  path: "/client/library/:param/config",
  component: LibraryConfig,
  permission: PERMISSIONS.LIBRARY_CONFIG,
};

export const clientProductUploadRoute = {
  path: "/client/library/upload-product",
  component: UploaadProduct,
  permission: PERMISSIONS.LIBRARY_UPLOAD_PRODUCT,
};

export const clientLibraryListRoute = {
  path: "/client/library",
  component: LibraryList,
  permission: PERMISSIONS.LIBRARY_LIST,
};

export const clientPeopleCreateRoute = {
  path: "/client/people/new",
  component: PeopleCreateAndEdit,
  permission: PERMISSIONS.PEOPLE_CREATE,
};

export const clientPeopleEditRoute = {
  path: "/client/people/:param/edit",
  component: PeopleCreateAndEdit,
  permission: PERMISSIONS.PEOPLE_EDIT,
};

export const clientPeopleListRoute = {
  path: "/client/people",
  component: PeopleList,
  permission: PERMISSIONS.PEOPLE_LIST,
};

export const clientBriefCreateRoute = {
  path: "/client/collaboration/brief/new",
  component: BriefCreate,
  permission: PERMISSIONS.BRIEF_CREATE,
};

export const clientWorkspaceBriefShowRoute = {
  path: "/client/collaboration/workspace/:workspaceParam/brief/:param/show",
  component: ShowBriefWithWorkspace,
  permission: PERMISSIONS.WORKSPACE_BRIEF_SHOW,
};

export const clientWorkspaceBriefCreateRoute = {
  path: "/client/collaboration/workspace/:param/brief/new",
  component: BriefCreate,
  permission: PERMISSIONS.WORKSPACE_BRIEF_CREATE,
};

export const clientWorkspaceCreateRoute = {
  path: "/client/collaboration/workspace/new",
  component: WorkspaceCreateAndEdit,
  permission: PERMISSIONS.WORKSPACE_CREATE,
};

export const clientWorkspaceBriefListRoute = {
  path: "/client/collaboration/workspace/:param/brief",
  component: BriefList,
  permission: PERMISSIONS.WORKSPACE_BRIEF_LIST,
};

export const clientWorkspaceEditRoute = {
  path: "/client/collaboration/workspace/:param/edit",
  component: WorkspaceCreateAndEdit,
  permission: PERMISSIONS.WORKSPACE_EDIT,
};

export const clientBriefEditRoute = {
  path: "/client/collaboration/brief/:param/edit",
  component: BriefEdit,
  permission: PERMISSIONS.BRIEF_EDIT,
};

export const clientBriefShowRoute = {
  path: "/client/collaboration/brief/:param/show",
  component: BriefShow,
  permission: PERMISSIONS.BRIEF_SHOW,
};

export const clientBriefListRoute = {
  path: "/client/collaboration/brief",
  component: BriefList,
  permission: PERMISSIONS.BRIEF_LIST,
};

export const clientCollaborationListRoute = {
  path: "/client/collaboration",
  component: CollaborationList,
  permission: PERMISSIONS.COLLABORATION_LIST,
};

