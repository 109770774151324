import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  signup,
  login,
  logout,
  verifyRegistration,
  resetPasswordVerification,
  resetPassword,
} from "src/store/actions";

export const useLoginAction = () => {
  const dispatch = useDispatch();

  return (payload) => dispatch(login(payload));
};

export const useRegistrationAction = () => {
  const dispatch = useDispatch();

  return (token, payload) => dispatch(signup(token, payload));
};

export const useRegistrationVerification = (params) => {
  const dispatch = useDispatch();
  const [verificationSent, setVerification] = useState(false);

  useEffect(() => {
    if (!verificationSent) {
      dispatch(verifyRegistration(params));
      setVerification(true);
    }

    // eslint-disable-next-line
  }, [params]);

  const { waitForRegistrationVerification, registrationVerfication } =
    useSelector((state) => ({
      waitForRegistrationVerification:
        state.authentication.waitForRegistrationVerification,
        registrationVerfication: state.authentication.registrationVerfication,
    }));

  return {
    waitForRegistrationVerification,
    registrationVerfication,
    isRegistrationVerfied:
      verificationSent &&
      !waitForRegistrationVerification &&
      isEmpty(registrationVerfication),
  };
};

export const useRestPasswordVerification = (params) => {
  const dispatch = useDispatch();
  const [verificationSent, setVerification] = useState(false);

  useEffect(() => {
    if (!verificationSent) {
      dispatch(resetPasswordVerification(params));
      setVerification(true);
    }

    // eslint-disable-next-line
  }, [params]);

  const {
    waitForResetPasswordVerification,
    successfulResetPasswordVerification,
  } = useSelector((state) => ({
    waitForResetPasswordVerification:
      state.authentication.waitForResetPasswordVerification,
    successfulResetPasswordVerification:
      state.authentication.successfulResetPasswordVerification,
  }));

  return {
    waitForResetPasswordVerification,
    successfulResetPasswordVerification:
      verificationSent &&
      !waitForResetPasswordVerification &&
      successfulResetPasswordVerification,
  };
};

export const useForgetPasswordAction = () => {
  const dispatch = useDispatch();

  return (payload) => dispatch(forgotPassword(payload));
};

export const useResetPasswordAction = () => {
  const dispatch = useDispatch();

  return (token, payload) => dispatch(resetPassword(token, payload));
};

export const useLogoutAction = () => {
  const dispatch = useDispatch();

  return () => dispatch(logout());
};
