import isEqual from "date-fns/isEqual";
import { ACTIONS, RESOURCES } from "./../../constants";

const initialState = Object.keys(RESOURCES).reduce(
  (acc, key) => ({
    ...acc,
    [RESOURCES[key]]: {
      query: {},
      pagination: {
        pages: 0,
        page: 0,
        itemPerPage: 10,
        items: [],
        allItems: [],
        totalItems: 0,
      },
      isFetching: false,
    },
  }),
  {}
);

const restAPIPaginationReducer = (state = initialState, action) => {
  const { payload, type, resource } = action;
  switch (type) {
    case ACTIONS.FILTER_PAGINATION: {
      return { ...state, [resource]: { ...state[resource], query: payload } };
    }
    case ACTIONS.CLEAR_PAGINATION_ITEM: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          pagination: {
            pages: 0,
            page: 0,
            allItems: [],
            itemPerPage: 10,
            items: [],
            totalItems: 0,
          },
          query: {},
          isFetching: false,
        },
      };
    }
    case ACTIONS.FETCH_ITEMS_INIT: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          isFetching: true,
        },
      };
    }
    case ACTIONS.FETCH_ITEMS_SUCCEDED: {
      const {
        paginate: {
          page = 0,
          totalPages: pages = 0,
          itemPerPage = 10,
          totalItems = 0,
        } = {},
        items = [],
      } = payload;
      const allItems = items;
      const pagination = { items, allItems, page, pages, itemPerPage, totalItems }
      if (isEqual(pagination, state[resource]?.pagination)){
        return state
      }
      return {
        ...state,
        [resource]: {
          ...state[resource],
          pagination,
          isFetching: false,
        },
      };
    }
    case ACTIONS.FETCH_ITEMS_FAILED: {
      return {
        ...state,
        [resource]: { ...state[resource], isFetching: false },
      };
    }
    case ACTIONS.CREATE_ITEM_SUCCEDED: {
      const items = [...state[resource]?.pagination?.items, payload];
      return {
        ...state,
        [resource]: {
          ...state[resource],
          items,
          pagination: { ...state[resource]?.pagination, items },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default restAPIPaginationReducer;
