import { toAbsoluteUrl } from "src/utils";
import { useIntl } from "react-intl";
import {
  InputField,
  InputCreatableSelectField,
  InputUploadField,
  InputSelectField,
} from "src/components/forms";
import { validations } from "src/utils";
import { reduxForm, Field, Form } from "redux-form";
import { useIsFormUplading } from "src/hooks";
import InputTextareaField from "src/components/forms/InputTextareaField";
import { workspaceUIHelper } from "src/uiHelper/workspace";
import { useToolbarContext } from "src/layouts/context/ToolbarContext";
import { useEffect } from "react";
import { S3_SUB_DIR } from "src/store/constants";
import { validateBriefUid } from "src/uiHelper";
const maxLength200 = validations.maxLength(200);

const BriefForm = (props) => {
  const { handleSubmit, form } = props;
  const intl = useIntl();

  const toolbarProps = useToolbarContext();
  const uploading = useIsFormUplading(form);

  useEffect(() => {
    toolbarProps.setDisableBtn(uploading);

    // eslint-disable-next-line
  }, [uploading]);

  return (
    <Form className="spacing--big" onSubmit={handleSubmit}>
      <div className="row middle-md middle-xl middle-xs">
        <div className="col-xs-10 row">
          <div className="col-md-6 col-xs-12">
            <div className="text--left spacing__left--extra-small">
              <h1 className="text--title text--bold text--secondary">
                {intl.formatMessage({ id: "BRIEF_CREATE.DETAIL" })}
              </h1>
            </div>

            <div className="spacing--extra-small" />
            <div className="row">
              <Field
                name="name"
                type="text"
                size="col-xs-12 col-md-6"
                component={InputField}
                placeholder={intl.formatMessage({ id: "FIELD.PRODUCT_NAME" })}
                validate={[validations.required, maxLength200]}
              />
              <Field
                name="workspaceId"
                size="col-xs-12 col-md-6"
                onLoadedData={workspaceUIHelper}
                component={InputSelectField}
                placeholder={intl.formatMessage({
                  id: "FIELD.BRIEF_WORKSPACE",
                })}
                validate={[validations.required]}
                initialValue={7}
              />
              <Field
                name="uid"
                type="text"
                size="col-xs-12 col-md-6"
                component={InputField}
                placeholder={intl.formatMessage({ id: "FIELD.BRIEF_UID" })}
                validate={[validations.required, maxLength200]}
              />
              <Field
                name="tags"
                required={false}
                size="col-xs-12 col-md-6"
                icon={toAbsoluteUrl("/media/icons/tag.svg")}
                component={InputCreatableSelectField}
                placeholder={intl.formatMessage({ id: "FIELD.BRIEF_TAGS" })}
              />
              <Field
                name="endpoint"
                type="text"
                required={false}
                component={InputField}
                placeholder={intl.formatMessage({ id: "FIELD.BRIEF_URL" })}
                validate={[validations.validUrl]}
              />

              <Field
                name="width"
                type="number"
                size="col-xs-12 col-md-4"
                component={InputField}
                placeholder={intl.formatMessage({ id: "FIELD.BRIEF_WIDTH" })}
                validate={[validations.required]}
              />
              <Field
                name="length"
                type="number"
                size="col-xs-12 col-md-4"
                component={InputField}
                placeholder={intl.formatMessage({ id: "FIELD.BRIEF_LENGTH" })}
                validate={[validations.required]}
              />
              <Field
                name="height"
                type="number"
                size="col-xs-12 col-md-4"
                component={InputField}
                placeholder={intl.formatMessage({ id: "FIELD.BRIEF_HEIGHT" })}
                validate={[validations.required]}
              />
              <Field
                name="description"
                type="text"
                required={false}
                component={InputTextareaField}
                placeholder={intl.formatMessage({
                  id: "FIELD.BRIEF_DESCRIPTION",
                })}
              />
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <div className="text--left spacing__left--extra-small">
              <h1 className="text--title text--bold text--secondary">
                {intl.formatMessage({ id: "BRIEF_CREATE.UPLOAD" })}
              </h1>
            </div>

            <div className="spacing--extra-small" />
            <div className="row">
              <Field
                name="uploadedImgs"
                multiple={true}
                folder={S3_SUB_DIR.S3_BRIEF_IMGS_PATH}
                icon={toAbsoluteUrl("/media/icons/image.svg")}
                component={InputUploadField}
                accept="image/*"
                label={intl.formatMessage({ id: "UPLOAD_IMG.TITLE" })}
                note={intl.formatMessage({ id: "FIELD.BRIEF_UPLOAD_IMG" })}
                validate={[validations.required]}
              />
              <Field
                name="uploadedFiles"
                multiple={true}
                folder={S3_SUB_DIR.S3_BRIEF_FILES_PATH}
                accept="application/*,text/*"
                icon={toAbsoluteUrl("/media/icons/document-upload.svg")}
                component={InputUploadField}
                label={intl.formatMessage({ id: "UPLOAD_FILE.TITLE" })}
                note={intl.formatMessage({ id: "FIELD.BRIEF_UPLOAD_FILE" })}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        ref={toolbarProps.buttonRef}
        className="hidden"
        type="submit"
      ></button>
    </Form>
  );
};

export default reduxForm({
  form: "brief", // a unique identifier for this form
  enableReinitialize: true,
  //asyncValidate: validateBriefUid,
  //asyncBlurFields: ["uid"],
})(BriefForm);
