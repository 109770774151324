import { createContext, useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useOutsideAlerter } from "src/hooks";
import { Img } from ".";
import moment from "moment";
import ComponentTransition from "./ComponentTransition";
import { v4 as uuidv4 } from "uuid";

const NotifContext = createContext(null);
const useNotifContext = () => useContext(NotifContext);

const Notif = ({ children, notifId = uuidv4() }) => {
  const [show, setShow] = useState(false);

  const value = {
    show,
    notifId,
    setShow,
  };

  return (
    <NotifContext.Provider value={value}>
      <div className="notification">{children}</div>
    </NotifContext.Provider>
  );
};

const Toggle = ({ children, props }) => {
  const { setShow, show, notifId } = useNotifContext();

  return (
    <button
      onClick={() => setShow(!show)}
      className="btn btn__dropdown dropdown-toggle"
      type="button"
      id={notifId}
      {...props}
    >
      {children}
    </button>
  );
};

const ToggleImg = ({ src, onClick, props }) => {
  const { setShow, show, notifId } = useNotifContext();

  const handleClick = () => {
    setShow(!show);
    onClick && onClick();
  };

  return (
    <Img
      src={src}
      alt="..."
      onClick={handleClick}
      className="dropdown-toggle-img"
      id={notifId}
      {...props}
    />
  );
};

const List = ({ children, ...props }) => {
  const { show, notifId, setShow } = useNotifContext();

  const ref = useRef();

  useOutsideAlerter(ref, notifId, () => setShow(false));

  return (
    <ul
      ref={ref}
      className={`menu dropdown-notification-list ${show ? "show" : ""}`}
      {...props}
      aria-labelledby="js-dropdownMenuButton"
    >
      {children}
    </ul>
  );
};

const ListHeader = ({ title, icon }) => {
  return (
    <li className="dropdown-list-header">
      <div className="dropdown-list-header-content">
        <span className="dropdown-list-header-content__title">{title}</span>
        <Img
          className="dropdown-list-header-content__icon"
          src={icon}
          alt="..."
        />
      </div>
    </li>
  );
};

const EmptyListItem = ({ icon, title, subtitle, to, onClick, ...props }) => {
  return (
    <li className="dropdown-list-no-item" {...props}>
      <div>
        <Img className="dropdown-list-no-item__icon" src={icon} alt="..." />
        <h6 className="dropdown-list-no-item__title">{title}</h6>
        <p className="dropdown-list-no-item__description">{subtitle}</p>
      </div>
    </li>
  );
};

const ListItems = ({ children }) => (
  <ul className="notification-list">{children}</ul>
);

const Item = ({ to, onClick, icon, title, seen, message, date, ...props }) => {
  const history = useHistory();
  const { setShow } = useNotifContext();

  const handleClick = () => {
    if (onClick && !seen) {
      onClick();
    }
    if (to) {
      history.push(to);
    }
    setShow(false);
  };

  return (
    <ComponentTransition
      element="li"
      className={"notification-item " + (!seen && "notification-unseen")}
      onClick={handleClick}
      {...props}
    >
      <div className="notification-item__icon">
        <Img src={icon} />
      </div>

      <div className="notification-item__info">
        <div className="notification-item__info__title">{title}</div>
        <div className="notification-item__info__body">{message}</div>
        <div className="notification-item__info__footer">
          {moment(date).fromNow()}
        </div>
      </div>
      {!seen && <div className="notification-item__unseen"></div>}
    </ComponentTransition>
  );
};

Notif.Toggle = Toggle;
Notif.ToggleImg = ToggleImg;
Notif.Item = Item;
Notif.ListItems = ListItems;
Notif.List = List;
Notif.ListHeader = ListHeader;
Notif.EmptyListItem = EmptyListItem;

export default Notif;
