import { toAbsoluteUrl } from "src/utils";
import { useIntl } from "react-intl";
import {
  InputField,
  InputCreatableSelectField,
  SubmitButton,
} from "src/components/forms";
import { validations } from "src/utils";
import { useToolbarContext } from "src/layouts/context/ToolbarContext";
import { reduxForm, Field, Form } from "redux-form";
import { useEffect } from "react";
import { Img } from "src/components/common";
import { validateWorkspaceName } from "src/uiHelper";
const maxLength200 = validations.maxLength(200);

const CreateWorkspaceForm = (props) => {
  const { handleSubmit, isLoading, submitting } = props;
  const intl = useIntl();

  const toolbarProps = useToolbarContext();

  useEffect(() => {
    if (toolbarProps) {
      toolbarProps.setShow(true);
      toolbarProps.setTitle(intl.formatMessage({ id: "WORKSPACE.TITLE" }));
      toolbarProps.setButtonText("");
    }

    return () => {
      toolbarProps.setShow(false);
      toolbarProps.setTitle("");
      toolbarProps.setButtonText("");
    };

    // eslint-disable-next-line
  }, [toolbarProps]);

  return (
    <Form className="spacing--big" onSubmit={handleSubmit}>
      <div className="row middle-md middle-xl middle-xs">
        <div className="col-md-offset-4 col-md-4 col-xs-12">
          <div className="text--center">
            <Img
              className="img-center"
              height="160"
              width="206"
              src={toAbsoluteUrl("/media/svg/workspace.svg")}
              alt="..."
            />
            <p className="spacing--extra-small text--title text--center text--bold text--secondary">
              {intl.formatMessage({ id: "WORKSPACE_CREATE.TITLE" })}
            </p>
            <p className="spacing--extra-small text--muted text--description text__width--medium--center">
              {intl.formatMessage({ id: "WORKSPACE_CREATE.DESC" })}
            </p>
          </div>

          <div className="spacing--extra-small" />

          <Field
            name="name"
            type="text"
            icon={toAbsoluteUrl("/media/icons/workspace.svg")}
            component={InputField}
            placeholder={intl.formatMessage({ id: "FIELD.WORKSPACE_NAME" })}
            validate={[validations.required, maxLength200]}
          />

          <Field
            name="tags"
            icon={toAbsoluteUrl("/media/icons/tag.svg")}
            component={InputCreatableSelectField}
            placeholder={intl.formatMessage({ id: "FIELD.WORKSPACE_TAGS" })}
          />

          <div className="col-xs-12 spacing--medium">
            <SubmitButton
              isLoading={isLoading}
              submitting={submitting}
              className="btn--xl btn__primary btn__block"
            >
              {intl.formatMessage({ id: "WORKSPACE.FORM.SUBMIT" })}{" "}
            </SubmitButton>
          </div>

          <div className="text--center">
            <p className="spacing--extra-small text--muted text--description text__width--medium--center">
              {intl.formatMessage({ id: "WORKSPACE_CREATE.FOOTER" })}
            </p>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default reduxForm({
  form: "workspace", // a unique identifier for this form
  //asyncValidate: validateWorkspaceName,
  //asyncBlurFields: ["name"],
})(CreateWorkspaceForm);
