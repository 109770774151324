const CardItem = ({ children }) => <div className="card__item">{children}</div>;

const Media = ({ children, className, ...props }) => (
  <div className={`card__item__media ${className}`} {...props}>
    {children}
  </div>
);

const MediaInfo = ({ children, className, ...props }) => (
  <div className={`card__item__media__info ${className}`} {...props}>
    {children}
  </div>
);

const MediaIconText = ({ children, className, ...props }) => (
  <div className={`card__item__media__icon ${className}`} {...props}>
    <span className="card__item__media__icon__text">{children}</span>
  </div>
);

const MediaTitle = ({ children, className, ...props }) => (
  <div className={`card__item__media__info__title ${className}`} {...props}>
    {children}
  </div>
);

const MediaSubTitle = ({ children, className, ...props }) => (
  <div className={`card__item__media__info__subtitle ${className}`} {...props}>
    {children}
  </div>
);

const Action = ({ children, className, ...props }) => {
  return (
    <div className={`card__item__action ${className}`} {...props}>
      {children}
    </div>
  );
};

CardItem.Media = Media;
CardItem.MediaInfo = MediaInfo;
CardItem.MediaIconText = MediaIconText;
CardItem.MediaSubTitle = MediaSubTitle;
CardItem.MediaTitle = MediaTitle;
CardItem.Action = Action;

export default CardItem;
