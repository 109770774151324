import { useRestAPIPerformActionResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useAcceptBrief = () => {
  const { onSubmit, isLoading } = useRestAPIPerformActionResource(
    RESOURCES.PRODUCT,
    ENDPOINTS.BRIEF_VALIDATE,
    {
      title: "PRODUCT.MSG.BRIEF_VALIDATE.TITLE",
      subtitle: "PRODUCT.MSG.BRIEF_VALIDATE.SUBTITLE",
    }
  );

  return { onAcceptBrief: onSubmit, isAcceptBrief: isLoading };
};

export default useAcceptBrief;
