import { FormattedMessage, useIntl } from "react-intl";
import { Dropdown, Img } from "src/components/common";

import * as routes from "src/modules/manager/routes";
import InputFilterField from "src/components/forms/InputFilterField";
import { toAbsoluteUrl } from "src/utils";

const Header = ({ totalSize, query, filterItems, isFetching }) => {
  const intl = useIntl();

  return (
    <div className="row spacing--page-filter">
      <div className="col-md-4 col-xs-12">
        <p className="text--title text--bold text--left">
          <FormattedMessage id="PRODUCT.TITLE" /> <span className="divider" />{" "}
          {totalSize || 0} <FormattedMessage id="TOTAL" />
        </p>
      </div>
      <div className="row col-md-8 col-xs-12 end-xs">
        <div className="spacing__right--extra-small spacing__down--extra-small">
          <InputFilterField
            isFetching={isFetching}
            onChange={(keyword) => filterItems({ keyword })}
            value={query?.keyword}
            icon={toAbsoluteUrl("/media/icons/search.svg")}
            placeholder={intl.formatMessage({ id: "COMPANY.FILTER" })}
          />
        </div>
        <div className="spacing__right--extra-small spacing__down--extra-small">
          <Dropdown>
            <Dropdown.Toggle>
              <FormattedMessage id="ORDER_BY" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.OrderByItem
                onOrderBy={filterItems}
                attr="status"
                query={query}
                choice={<FormattedMessage id="ORDER.BY_STATUS" />}
              />
              <Dropdown.OrderByItem
                onOrderBy={filterItems}
                active={true}
                attr="name"
                query={query}
                choice={<FormattedMessage id="ORDER.BY_NAME" />}
              />
              <Dropdown.OrderByItem
                onOrderBy={filterItems}
                attr="tag"
                query={query}
                choice={<FormattedMessage id="ORDER.BY_TAG" />}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="spacing__right--extra-small spacing__down--extra-small">
          <Dropdown>
            <Dropdown.Toggle>
              <FormattedMessage id="VIEW_AS" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                to={routes.managerCollaborationListRoute.path}
                choice={<FormattedMessage id="VIEW.COMPANY" />}
              >
                <Img src={toAbsoluteUrl("/media/icons/company.svg")} alt="..." />
              </Dropdown.Item>
              <Dropdown.Item
                to={routes.managerBriefListRoute.path}
                choice={<FormattedMessage id="VIEW.PRODUCT" />}
                active={true}
              >
                <Img
                  src={toAbsoluteUrl("/media/icons/product.svg")}
                  alt="..."
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {/*<div className="spacing__right--extra-small spacing__down--extra-small">
          <button className="btn btn__primary" onClick={() => history.push(routes.managerCompanyCreateRoute.path)}><FormattedMessage id="COMPANY.CREATE" /></button>
        </div>*/}
      </div>
    </div>
  );
};

export default Header;
