import axios from "axios";
import { getEnv } from "./Env";
import { isEmpty } from "lodash";

const MemoryCache = {
  data: {},
  set(key, value, maxAge) {
    this.data[key] = {
      maxAge: maxAge || 0,
      value,
      now: Date.now(),
    };
  },
  get(key) {
    // Retrieves the value of the specified key from the cache.
    const cachedItem = this.data[key];
    if (!cachedItem) return null;
    const isExpired = Date.now() - cachedItem.now > cachedItem.maxAge;
    isExpired && this.delete(key);
    return isExpired ? null : cachedItem.value;
  },
  delete(key) {
    // Remove the value of the specified key from the cache.
    return delete this.data[key];
  },
  clear() {
    // Clear the cached data.
    this.data = {};
  },
};

const generateReqKey = (config) => {
  const { method, url, params, data } = config;
  return [method, url, JSON.stringify(params), JSON.stringify(data)].join("&");
};

const isCacheLike = (cache) =>
  cache.set &&
  cache.get &&
  cache.delete &&
  cache.clear &&
  typeof cache.get === "function" &&
  typeof cache.set === "function" &&
  typeof cache.delete === "function" &&
  typeof cache.clear === "function";

const cacheAdapterEnhancer = (adapter, options) => {
  const {
    maxAge,
    enabledByDefault = true,
    cacheFlag = "cache",
    defaultCache = MemoryCache,
  } = options;

  return (config) => {
    const { method, forceUpdate } = config;
    let useCache =
      config[cacheFlag] !== undefined && config[cacheFlag] !== null
        ? config[cacheFlag]
        : enabledByDefault;

    if (method === "get" && useCache) {
      const cache = isCacheLike(useCache) ? useCache : defaultCache;
      let requestKey = generateReqKey(config); // Generate the request Key
      let responsePromise = cache.get(requestKey); // Retrieve the response object corresponding to the request key from the cache
      if (!responsePromise || forceUpdate) {
        // If the cache is not hit/invalid or forced to update, the data is re-requested
        responsePromise = (async () => {
          try {
            return await adapter(config); // Use the default xhrAdapter to send requests
          } catch (reason) {
            cache.delete(requestKey);
            throw reason;
          }
        })();
        cache.set(requestKey, responsePromise, maxAge); // Save the response object returned by the request
        return responsePromise; // Returns the saved response object
      }
      return responsePromise;
    }
    return adapter(config); // Use the default xhrAdapter to send requests
  };
};

export const replaceParams = (endpoint, params) =>
  isEmpty(params)
    ? endpoint
    : Object.keys(params).reduce((prev, param) => {
        if (prev && prev.includes(`:${param}`)) {
          return prev?.replace(`:${param}`, params[param]);
        }
        return prev;
      }, endpoint);

export const guessEndpointAndReplaceParams = (endpoints, params) =>
  isEmpty(params)
    ? endpoints[0]
    : replaceParams(
        endpoints.find((endpoint) =>
          Object.keys(params).every((param) => endpoint.includes(param))
        ),
        params
      );

const client = axios.create({
  baseURL: getEnv("REACT_APP_API_BASE_URL"),
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
  },
  /*adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: true,
    maxAge: 5000,
  }),*/
});

export const setupAxiosInterceptorOnUnauthorizedRequest = (fn) => {
  const UNAUTHORIZED = 401;
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === UNAUTHORIZED) {
        fn();
      }
      return Promise.reject(error);
    }
  );
};

export const makeCall = (
  method,
  url,
  data = {},
  headers = {},
  params = {},
  extra = {}
) => {
  return client.request({
    method,
    data,
    url,
    headers,
    params,
    ...extra,
  });
};

export const makeS3Call = (
  method,
  url,
  data = {},
  headers = {},
  onUploadProgressCallback = (f) => f
) => {
  const client = axios.create({
    baseURL: getEnv("REACT_APP_API_BASE_URL"),
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
      enabledByDefault: true,
      maxAge: 5000,
    }),
  });
  const config = {
    onUploadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      onUploadProgressCallback(percentCompleted);
    },
  };

  return client.request({ method, url, data, headers, config });
};
