import { useRestAPICreateResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useCreateDiscussionReply = () => {
  const { onSubmit, isLoading, createdItem, success } =
    useRestAPICreateResource(
      RESOURCES.DISCUSSION,
      ENDPOINTS.BRIEF_ROUND_DISCUSSION_LIST
      //"DISCUSSION.MSG.CREATED"
    );

  return {
    onSubmitReply: onSubmit,
    createdDiscussion: createdItem,
    isReplyCreated: success,
    isLoading,
  };
};

export default useCreateDiscussionReply;
