import { Issue } from "src/components/common";
import { FormattedMessage } from "react-intl";

const PinPointNoIssue = () => {
  return (
    <Issue>
      <Issue.Body>
        <FormattedMessage id="BRIEF.CLIENT_IN_REVISION" />
      </Issue.Body>
    </Issue>
  );
};

export default PinPointNoIssue;
