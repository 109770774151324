import { useSelector, shallowEqual } from "react-redux";

const useErrorSelector = () => {
  const { error } = useSelector(
    (state) => ({ error: state.common.error }),
    shallowEqual
  );

  return error;
};

export default useErrorSelector;
