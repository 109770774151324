import { FormattedMessage } from "react-intl";

import { PinPointIssueInfo } from "src/modules/manager/pages/collaboration/components";

import {
  PinPoint,
  Note,
  ActionButton,
  ObjectAttrDisplay,
  ObjectAttrDisplayProvider,
} from "src/components/common";
import { ManagerFormattedStatus as FormattedStatus } from "src/components/status";
import { toAbsoluteUrl } from "src/utils";
import { ISSUE, REVIEW, REVISION } from "src/uiHelper/status";
import {
  managerBriefUploadRoute,
  managerCompanyBriefUploadRoute,
} from "src/modules/manager/routes";
import PinPointNoIssue from "../../components/issue/PinPointNoIssue";
import { LoadMore } from "src/components/pagination";

const PinPointIssues = (props) => {
  const {
    fetchedBrief,
    fetchedRound,
    pinpoints,
    pinpointPaginationProps,
    isLatestTab,
    isFetching,
    history,
    match: { params },
  } = props;

  const goToUploadBrief = () => {
    if (params?.companyParam) {
      history.push(
        managerCompanyBriefUploadRoute.path
          .replace(":param", fetchedBrief?.id)
          .replace(":companyParam", params?.companyParam)
      );
    } else {
      history.push(
        managerBriefUploadRoute.path.replace(":param", fetchedBrief?.id)
      );
    }
  };

  return (
    <>
      <PinPoint>
        <PinPoint.Header>
          <ObjectAttrDisplayProvider
            object={fetchedBrief}
            isFetching={isFetching}
          >
            <ObjectAttrDisplay
              attr="status"
              className="medium_text__loader"
              formatter={(status) =>
                isLatestTab && [REVIEW, ISSUE].includes(status) ? (
                  <PinPoint.Img
                    src={toAbsoluteUrl("/media/icons/waiting.svg")}
                  />
                ) : (
                  <PinPoint.Img src={toAbsoluteUrl("/media/icons/issue.svg")} />
                )
              }
            />
          </ObjectAttrDisplayProvider>
          <PinPoint.Title>
            <ObjectAttrDisplayProvider
              object={fetchedBrief}
              isFetching={isFetching}
            >
              <ObjectAttrDisplay
                attr="status"
                className="medium_text__loader"
                formatter={(status) =>
                  isLatestTab && [REVIEW, ISSUE].includes(status) ? (
                    <FormattedMessage id="ROUND.WAITING_FOR_COMPANY" />
                  ) : (
                    <FormattedMessage id="ROUND.MODEL_ISSUE" />
                  )
                }
              />
            </ObjectAttrDisplayProvider>
          </PinPoint.Title>
          <PinPoint.Toolbar>
            <ObjectAttrDisplayProvider
              object={fetchedBrief}
              isFetching={isFetching}
            >
              <ObjectAttrDisplay
                attr="status"
                className="medium_text__loader"
                formatter={(status) => (
                  <FormattedStatus className="status-card" status={status} />
                )}
              />
            </ObjectAttrDisplayProvider>
          </PinPoint.Toolbar>
        </PinPoint.Header>
        <PinPoint.Body>
          <ObjectAttrDisplayProvider
            object={fetchedRound}
            isFetching={isFetching}
          >
            <ObjectAttrDisplay
              attr="description"
              className="alert__loader spacing--b-1"
              formatter={(description) =>
                description && (
                  <>
                    <Note variant="danger">
                      <Note.Title>
                        <FormattedMessage id="NOTE.COMPANY_IN_REVIEW.TITLE" />
                      </Note.Title>
                      <Note.Description>{description}</Note.Description>
                    </Note>
                    {pinpoints?.length > 0 && (
                      <div className="content__divider spacing--b-0" />
                    )}
                  </>
                )
              }
            />
          </ObjectAttrDisplayProvider>
          <ObjectAttrDisplayProvider
            object={fetchedBrief}
            isFetching={isFetching}
          >
            <ObjectAttrDisplay
              attr="status"
              className="button__loader"
              formatter={(status) =>
                !isLatestTab || status !== ISSUE ? (
                  <LoadMore
                    {...pinpointPaginationProps}
                    briefParam={fetchedBrief?.id}
                    roundParam={params?.roundParam}
                    companyParam={params?.companyParam}
                    viewAsList={true}
                    items={pinpoints}
                    itemComponent={PinPointIssueInfo}
                    noDataComponent={PinPointNoIssue}
                    isFetching={isFetching}
                  />
                ) : (
                  <Note variant="danger">
                    <Note.Description>
                      <FormattedMessage id="NOTE.WAITING_FOR_COMPANY.DESC" />
                    </Note.Description>
                  </Note>
                )
              }
            />
          </ObjectAttrDisplayProvider>
        </PinPoint.Body>

        <PinPoint.Footer>
          <ObjectAttrDisplayProvider
            object={fetchedBrief}
            isFetching={isFetching}
          >
            <ObjectAttrDisplay
              attr="status"
              className="button__loader"
              formatter={(status) =>
                isLatestTab && (
                  <>
                    {pinpoints?.length > 0 && (
                      <ActionButton
                        disabled={status !== REVISION}
                        onClick={goToUploadBrief}
                        className={
                          "btn btn__primary btn__block spacing__down--small " +
                          (status !== REVISION && " btn__disabled")
                        }
                      >
                        <FormattedMessage id="BRIEF.RESOLVE_ISSUES" />
                      </ActionButton>
                    )}
                    {/* pinpoints?.length === 0  &&
                <ActionButton
                  disabled={status !== REVISION }
                  onClick={goToUploadBrief}
                  className={"btn btn__success btn__block spacing__down--small " + (status !== REVISION && " btn__disabled")}>
                  <FormattedMessage id="BRIEF.UPLOAD" />
                </ActionButton> */}
                  </>
                )
              }
            />
          </ObjectAttrDisplayProvider>
        </PinPoint.Footer>
      </PinPoint>
    </>
  );
};

export default PinPointIssues;
