import { ACTIONS, RESOURCES } from "./../../constants";

const initialState = Object.keys(RESOURCES).reduce(
  (acc, key) => ({
    ...acc,
    [RESOURCES[key]]: {
      actionResult: {},
      isLoading: false,
      success: false,
    },
  }),
  {}
);

const restAPICreateResourceReducer = (state = initialState, action) => {
  const { payload, type, resource } = action;
  switch (type) {
    case ACTIONS.CLEAR_ITEM: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          actionResult: {},
          success: false,
          isLoading: false,
        },
      };
    }

    case ACTIONS.ACTION_ON_ITEM_INIT: {
      return {
        ...state,
        [resource]: { ...state[resource], isLoading: true, success: false },
      };
    }
    case ACTIONS.ACTION_ON_ITEM_SUCCEDED: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          actionResult: payload,
          success: true,
          isLoading: false,
        },
      };
    }
    case ACTIONS.ACTION_ON_ITEM_FAILED: {
      return { ...state, [resource]: { ...state[resource], isLoading: false } };
    }
    default: {
      return state;
    }
  }
};

export default restAPICreateResourceReducer;
