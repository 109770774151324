import { Img } from "../common";
import { debounce } from "lodash";

const InputFilterField = ({
  helper,
  isFetching,
  icon,
  placeholder,
  onChange,
}) => {
  const debounceFn = debounce((value) => {
    onChange && onChange(value);
  }, 500);

  const handleChange = (e) => {
    debounceFn(e?.currentTarget?.value);
  };

  return (
    <div className="form-group">
      <div className="form-group-input">
        {icon && (
          <div className="form-group-input__addon--filter">
            {!isFetching ? (
              <Img src={icon} alt="..." />
            ) : (
              <span className="loader spacing__no sm" />
            )}
          </div>
        )}
        <input
          className={`form-control__filter`}
          onChange={handleChange}
          type="text"
          placeholder={placeholder}
        />
      </div>
      <div className="form-control__helper">{helper}</div>
    </div>
  );
};

export default InputFilterField;
