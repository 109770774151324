import { ACTIONS, RESOURCES } from "./../../constants";

const initialState = Object.keys(RESOURCES).reduce(
  (acc, key) => ({
    ...acc,
    [RESOURCES[key]]: {
      fetchedItem: {},
      isFetching: false,
      success: false,
    },
  }),
  {}
);

const restAPIFetchResourceReducer = (state = initialState, action) => {
  const { payload, type, resource } = action;
  switch (type) {
    case ACTIONS.CLEAR_RESOURCE_ITEM: {
      return {
        ...state,
        [resource]: { ...state[resource], fetchedItem: {}, isFetching: false },
      };
    }

    case ACTIONS.FETCH_ITEM_INIT: {
      return { ...state, [resource]: { ...state[resource], isFetching: true } };
    }
    case ACTIONS.FETCH_ITEM_SUCCEDED: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          fetchedItem: payload,
          isFetching: false,
        },
      };
    }
    case ACTIONS.FETCH_ITEM_FAILED: {
      return {
        ...state,
        [resource]: { ...state[resource], isFetching: false },
      };
    }

    case ACTIONS.ACTION_ON_ITEM_SUCCEDED: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          fetchedItem: { ...(state[resource]?.fetchedItem || {}), ...payload },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default restAPIFetchResourceReducer;
