import { FormattedMessage } from "react-intl";
import { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  PinPoint,
  ObjectAttrDisplay,
  ObjectAttrDisplayProvider,
  Discussion,
} from "src/components/common";
import { toAbsoluteUrl, formatNumber } from "src/utils";
import {
  DiscussionReplyForm,
  DiscussionInfo,
  DiscussionEmpty,
} from "src/modules/manager/pages/collaboration/components";
import { useCreateDiscussionReply, useDiscussionPagination } from "../../hooks";
import { useCurrentUser } from "src/hooks";
import { pinpointRoutes, workspacePinpointRoutes } from "../../routes";
import { ManagerFormattedStatus as FormattedStatus } from "src/components/status";
import { LoadMore } from "src/components/pagination";

const PinPointDiscussion = (props) => {
  const {
    isLatestTab,
    pinpoints,
    fetchedBrief,
    match: { params },
  } = props;

  const { discussions, isFetching, ...discussionProps } =
    useDiscussionPagination({ params });

  const currentUser = useCurrentUser();
  const { onSubmitReply, isReplyCreated, isLoading } =
    useCreateDiscussionReply();

  const pinpoint = useMemo(
    () =>
      Array.isArray(pinpoints)
        ? pinpoints.find(
            (pinpoint) =>
              parseInt(pinpoint.id) === parseInt(params.pinpointParam)
          )
        : null,
    [pinpoints, params]
  );

  const onReply = useCallback(
    (values) => {
      if (isLatestTab) {
        onSubmitReply(values, { pinpointParam: params?.pinpointParam });
      }

      // eslint-disable-next-line
    },
    [params?.pinpointParam, onSubmitReply, isLatestTab]
  );

  const history = useHistory();
  const onCancel = () => {
    if (params?.companyParam && params?.param && params?.roundParam) {
      history.push({
        pathname: workspacePinpointRoutes.managerPinPointRoundIssueRoute.path
          .replace(":param", params?.param)
          .replace(":companyParam", params?.companyParam)
          .replace(":roundParam", params?.roundParam),
        state: { doNotAnnimate: true },
      });
    } else if (params?.param && params?.roundParam) {
      history.push({
        pathname: pinpointRoutes.managerPinPointRoundIssueRoute.path
          .replace(":param", params?.param)
          .replace(":roundParam", params?.roundParam),
        state: { doNotAnnimate: true },
      });
    }
  };

  return (
    <PinPoint>
      <PinPoint.Header>
        <PinPoint.Img src={toAbsoluteUrl("/media/icons/issue.svg")} />
        <PinPoint.Title>
          <FormattedMessage id="PINPOINT.ISSUE" />{" "}
          <ObjectAttrDisplayProvider object={pinpoint} isFetching={isFetching}>
            <ObjectAttrDisplay
              attr="pinpointNumber"
              className="small_text__loader"
              formatter={(pinpointNumber) => formatNumber(pinpointNumber)}
            />
          </ObjectAttrDisplayProvider>
        </PinPoint.Title>
        <PinPoint.Toolbar>
          <ObjectAttrDisplayProvider
            object={fetchedBrief}
            isFetching={isFetching}
          >
            <ObjectAttrDisplay
              attr="status"
              className="medium_text__loader"
              formatter={(status) => (
                <FormattedStatus className="status-card" status={status} />
              )}
            />
          </ObjectAttrDisplayProvider>
        </PinPoint.Toolbar>
      </PinPoint.Header>
      <PinPoint.Body>
        <ObjectAttrDisplayProvider object={pinpoint} isFetching={isFetching}>
          <ObjectAttrDisplay
            attr="comment"
            className="alert__loader"
            formatter={(comment) => (
              <Discussion>
                <Discussion.Body>{comment}</Discussion.Body>
              </Discussion>
            )}
          />
        </ObjectAttrDisplayProvider>
        <LoadMore
          {...discussionProps}
          currentUser={currentUser}
          items={discussions}
          itemComponent={DiscussionInfo}
          noDataComponent={DiscussionEmpty}
          isFetching={isFetching}
        />
      </PinPoint.Body>

      <PinPoint.Footer>
        {isLatestTab ? (
          <DiscussionReplyForm
            onSubmit={onReply}
            waitToSendReply={isReplyCreated}
            waitToSubmitReply={isLoading}
            onCancel={onCancel}
          />
        ) : (
          <button className="btn btn__block btn__danger" onClick={onCancel}>
            <FormattedMessage id="ISSUE.CANCEL" />
          </button>
        )}
      </PinPoint.Footer>
    </PinPoint>
  );
};

export default PinPointDiscussion;
