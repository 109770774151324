const Progress = ({ title, progress }) => {
  return (
    <div className="progress__section">
      <span className="progress__section__title">{title}</span>
      <div className="progress">
        <div
          className="progress__bar progress__bar--success"
          role="progressbar"
          style={{ width: `${progress}%` }}
          aria-valuenow={progress}
          aria-valuemin="0"
          aria-valuemax="100"
        />
      </div>
      <span className="progress__section__indicator">{progress}</span>
      <span className="progress__section__indicator">%</span>
    </div>
  );
};

export default Progress;
