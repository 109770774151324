import { ACTIONS, CALL_API, HTTP_METHODS, ENDPOINTS } from "./../constants";
import { replaceParams } from "src/utils";

export const pushNotification = (payload) => ({
  type: ACTIONS.PUSH_NOTIFICATION,
  payload,
});

export const seenNotification = () => ({
  type: ACTIONS.SEEN_NOTIFICATION,
});

export const resetSocketConnection = () => ({
  type: ACTIONS.RESET_SOCKET_CONNECTION,
});

export const flashNotification = () => ({
  type: ACTIONS.FLASH_NOTIFICATION,
});

export const failToConnectToSocket = () => ({
  type: ACTIONS.FAILED_NOTIFICATION,
});

export const seenComment = () => ({
  type: ACTIONS.SEEN_COMMENT,
});

export const clearNotification = () => ({
  type: ACTIONS.CLEAR_NOTIFICATION,
});

export const fetchNotifications = (query) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_NOTIFICATIONS_INIT,
      success: ACTIONS.FETCH_NOTIFICATIONS_SUCCEDED,
      fail: ACTIONS.FETCH_NOTIFICATIONS_FAILED,
    },
    endpoint: ENDPOINTS.CENTER_NOTIFICATIONS,
    method: HTTP_METHODS.GET,
    params: { ...query },
    auth: true,
  },
});

export const fetchDiscussions = (query) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_DISCUSSIONS_INIT,
      success: ACTIONS.FETCH_DISCUSSIONS_SUCCEDED,
      fail: ACTIONS.FETCH_DISCUSSIONS_FAILED,
    },
    endpoint: ENDPOINTS.CENTER_DISCUSSIONS,
    method: HTTP_METHODS.GET,
    params: { ...query },
    auth: true,
  },
});

export const notificationSeen = (params) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.ACTION_NOTIFICATION_SEEN_INIT,
      success: ACTIONS.ACTION_NOTIFICATION_SEEN_SUCCEDED,
      fail: ACTIONS.ACTION_NOTIFICATION_SEEN_FAILED,
    },
    endpoint: replaceParams(ENDPOINTS.CENTER_NOTIFICATION, params),
    method: HTTP_METHODS.POST,
    auth: true,
  },
});

export const discussionSeen = (params) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.ACTION_DISCUSSION_SEEN_INIT,
      success: ACTIONS.ACTION_DISCUSSION_SEEN_SUCCEDED,
      fail: ACTIONS.ACTION_DISCUSSION_SEEN_FAILED,
    },
    endpoint: replaceParams(ENDPOINTS.CENTER_DISCUSSION, params),
    method: HTTP_METHODS.POST,
    auth: true,
  },
});
