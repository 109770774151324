import { useState, useEffect } from "react";
import { isEqual, isEmpty } from "lodash";
import { useRestAPIPaginateCollection } from "src/hooks";
import { ENDPOINTS, RESOURCES } from "src/store/constants";

const useDiscussionPagination = ({ params }) => {
  const { loadItems, items, ...paginationProps } = useRestAPIPaginateCollection(
    RESOURCES.DISCUSSION,
    ENDPOINTS.BRIEF_ROUND_DISCUSSION_LIST
  );

  const [prevParams, setPrevParams] = useState({});

  useEffect(() => {
    if (!isEmpty(params) && !isEqual(prevParams, params)) {
      loadItems({ pinpointParam: params?.pinpointParam });
      setPrevParams(params);
    }

    // eslint-disable-next-line
  }, [params]);

  const refreshItems = () => {
    loadItems({ pinpointParam: params?.pinpointParam });
  };

  return { loadItems, refreshItems, discussions: items, ...paginationProps };
};

export default useDiscussionPagination;
