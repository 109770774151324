import { toAbsoluteUrl } from "src/utils";
import { useIntl } from "react-intl";
import {
  InputTextareaField,
  InputUploadModelProductField,
} from "src/components/forms";
import { reduxForm, Field, Form } from "redux-form";
import { useIsFormUplading } from "src/hooks";
import { useToolbarContext } from "src/layouts/context/ToolbarContext";
import { useEffect } from "react";
import { validations } from "src/utils";

const BriefForm = (props) => {
  const { handleSubmit, form, isLoading } = props;
  const intl = useIntl();

  const uploading = useIsFormUplading(form);
  const toolbarProps = useToolbarContext();

  useEffect(() => {
    toolbarProps.setDisableBtn(uploading);

    // eslint-disable-next-line
  }, [uploading]);

  return (
    <Form onSubmit={handleSubmit}>
      <Field
        isLoading={isLoading}
        name="product"
        modelResource="round_model"
        thumbnailResource="round_thumbnail"
        icon={toAbsoluteUrl("/media/svg/3d-file.svg")}
        component={InputUploadModelProductField}
        accept="*"
        note={intl.formatMessage({ id: "FIELD.PRODUCT_UPLOAD" })}
        modalTitle={intl.formatMessage({ id: "MODAL.PRODUCT.TITLE" })}
        modalFormTitle={intl.formatMessage({ id: "MODAL.PRODUCT.INFO" })}
      >
        <Field
          size="col-12 spacing__no"
          name="description"
          type="text"
          component={InputTextareaField}
          placeholder={intl.formatMessage({ id: "FIELD.BRIEF_DESCRIPTION" })}
          validate={[validations.required]}
        />
      </Field>
      <button
        ref={toolbarProps.buttonRef}
        className="hidden"
        type="submit"
      ></button>
    </Form>
  );
};

export default reduxForm({
  form: "round_upload", // a unique identifier for this form
})(BriefForm);
