import { getEnv } from "src/utils";

export const API_HEALTH_CHECK = "/api/liveness";

// auth
export const LOGIN = "/api/v1/authentication/login";
export const LOGOUT = "/api/v1/authentication/login";
export const FORGOT_PASSWORD = "/api/v1/authentication/forgot-password";
export const RESET_PASSWORD = "/api/v1/authentication/reset-password/:token";
export const FETCH_TOKEN = "/api/v1/authentication/check-token/:token";
export const REGISTER = "/api/v1/authentication/signup/:token";
export const VERIFY_REGISTERATION =
  "/api/v1/authentication/check-invitation/:token";

// WORKSPACE
export const WORKSPACES = "/api/v1/workspaces";
export const WORKSPACE = "/api/v1/workspaces/:param";

export const BRIEFS = "/api/v1/briefs";

export const LIBRARYS = "/api/v1/products";
export const LIBRARY = "/api/v1/products/:param";

export const PRODUCT_CONFIG = "/api/v1/products/:param/config";

export const BRIEFS_BY_WORKSPACE = "/api/v1/workspaces/:param/briefs";
export const BRIEF = "/api/v1/briefs/:param";

export const BRIEF_VALIDATE = "/api/v1/briefs/:param/validate";
export const BRIEF_WORKING_PROGRESS = "/api/v1/briefs/:param/start-working";
export const BRIEF_READY_FOR_REVIEW = "/api/v1/briefs/:param/ready-for-review";
export const BRIEF_READY_FOR_REVISION =
  "/api/v1/briefs/:param/ready-for-revision";
export const BRIEF_CLOSE = "/api/v1/briefs/:param/close";
export const BRIEF_HAS_ISSUES = "/api/v1/briefs/:param/has-issues";
export const BRIEF_ISSUE_CANCEL = "/api/v1/briefs/:param/cancel-issues";

export const BRIEF_ROUND = "/api/v1/briefs/:param/rounds/:roundParam";
export const BRIEF_ROUND_LIST = "/api/v1/briefs/:param/rounds";
export const BRIEF_ROUND_DISCUSSION_LIST =
  "/api/v1/pinpoints/:pinpointParam/discussions";

export const PRODUCTS = "/api/v1/briefs";
export const PRODUCTS_BY_COMPANY = "/api/v1/companies/:param/briefs";
export const PRODUCT_ROUND = "/api/v1/briefs/:param/rounds";
export const PRODUCT = "/api/v1/briefs/:param";

export const ANALYTICS_SESSION_GEO_DISTRIBUTION = "/api/v1/model-analytic/country-metrics"
export const ANALYTICS_SESSION_AVG = "/api/v1/model-analytic/metrics"
export const ANALYTICS_SESSION_NUMBER = "/api/v1/model-analytic/session-metrics"
export const ANALYTICS_STATISTICS_BY_DEVICE = "/api/v1/model-analytic/device-metrics"

export const CLIENTS = "/api/v1/clients";
export const CLIENT = "/api/v1/clients/:param";

export const INVITATIONS = "/api/v1/invitations";
export const INVITATIONS_MANAGER = "/api/v1/invitations/manager";
export const INVITATIONS_COMPANY = "/api/v1/invitations/company";
export const INVITATIONS_DRAFT = "/api/v1/invitations/draft";
export const INVITATION = "/api/v1/invitations/:param";
export const INVITATION_SEND = "/api/v1/invitations/:param/re-send";

export const UPDATE_CLIENT_ROLE = "/api/v1/roles/:roleParam/link/:userParam"

export const PINPOINTS = "/api/v1/rounds/:param/pinpoints";
export const PINPOINT = "/api/v1/rounds/:param/pinpoints/:pinpint";

export const DISCUSSIONS = "/api/v1/pinpints/:param/discussions";
export const DISCUSSION = "/api/v1/pinpints/:param/discussions/:discussion";

export const COMPANYS = "/api/v1/companies";
export const COMPANY = "/api/v1/companies/:param";

export const CENTER_DISCUSSIONS = "/api/v1/notifications/discussions";
export const CENTER_DISCUSSION = "/api/v1/notifications/:param/seen";
export const CENTER_NOTIFICATIONS = "/api/v1/notifications/timelines";
export const CENTER_NOTIFICATION = "/api/v1/notifications/:param/seen";

// global upload
export const FETCH_OBJECTS = "/api/v1/upload/list-uploaded-objects/:resource";
export const CREATE_MULTIPART = "/api/v1/upload/create-multipart/:resource";
export const SIGN_UPLOAD_PART = "/api/v1/upload/sign-upload-part";
export const COMPLETE_MULTIPART_UPLOAD =
  "/api/v1/upload/complete-multipart-upload";
export const ABORT_MULTIPART_UPLOAD = "/api/v1/upload/abort-multipart-upload";
export const DELETE_OBJECT = "/api/v1/upload/delete-object";

export const EMBEDED_3D_MODEL = getEnv("REACT_APP_EMBEDED_3D_MODEL_URL");
