import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { notificationSeen } from "src/store/actions";

const useNotifications = () => {
  const dispatch = useDispatch();

  const onSeen = (param) => {
    dispatch(notificationSeen({ param }));
  };

  const props = useSelector(
    (state) => ({
      notifications: state.notif.notifications,
      newNotification: state.notif.newNotification,
      lastActive: state.notif.lastActive,
    }),
    isEqual
  );

  return { ...props, onSeen };
};

export default useNotifications;
