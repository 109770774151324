import { lazy } from "react";

const PublicLayout = lazy(() => import("./layouts/PublicLayout"));
const ClientLayout = lazy(() => import("./layouts/ClientLayout"));
const AdminLayout = lazy(() => import("./layouts/AdminLayout"));
const ManagerLayout = lazy(() => import("./layouts/ManagerLayout"));

export const adminSpace = {
  path: "/admin",
  component: AdminLayout,
};

export const managerSpace = {
  path: "/manager",
  component: ManagerLayout,
};

export const clientSpace = {
  path: "/client",
  component: ClientLayout,
};

export const anonymousSpace = {
  path: "/",
  component: PublicLayout,
};
