import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { ProfileIcon, Issue } from "src/components/common";
import { toAbsoluteUrl } from "src/utils";
import moment from "moment";
import { pinpointRoutes, workspacePinpointRoutes } from "../../routes";

const PinPointIssueInfo = (props) => {
  const {
    onToggle,
    pinpoint,
    briefParam,
    workspaceParam,
    roundParam,
    viewAsList = false,
  } = props;

  const history = useHistory();
  const goToDiscussion = () => {
    if (workspaceParam && briefParam && roundParam) {
      history.push({
        pathname:
          workspacePinpointRoutes.clientPinPointIssueDiscussionRoute.path
            .replace(":param", briefParam)
            .replace(":roundParam", roundParam)
            .replace(":workspaceParam", workspaceParam)
            .replace(":pinpointParam", pinpoint?.id),
        state: { doNotAnnimate: true },
      });
    } else if (briefParam && roundParam) {
      history.push({
        pathname: pinpointRoutes.clientPinPointIssueDiscussionRoute.path
          .replace(":param", briefParam)
          .replace(":roundParam", roundParam)
          .replace(":pinpointParam", pinpoint?.id),
        state: { doNotAnnimate: true },
      });
    }
  };

  return (
    <Issue onClick={goToDiscussion}>
      <Issue.Header className={viewAsList ? "issue__divider" : ""}>
        <Issue.HeaderIcon>
          <ProfileIcon />
        </Issue.HeaderIcon>
        <Issue.HeaderInfo>
          <Issue.HeaderTitle>
            {pinpoint?.user?.firstName} {pinpoint?.user?.lastName}
          </Issue.HeaderTitle>
          <Issue.HeaderSubTitle>
            {moment(pinpoint?.createdAt).fromNow()}
          </Issue.HeaderSubTitle>
        </Issue.HeaderInfo>
        <Issue.Toolbar>
          {viewAsList ? (
            <>
              <Issue.ToolbarItem>
                <FormattedMessage id="ISSUE.TITLE" /> #
                {pinpoint?.pinpointNumber || 0}
              </Issue.ToolbarItem>
              <Issue.ToolbarIcon
                src={toAbsoluteUrl("/media/icons/comment.svg")}
              />
            </>
          ) : (
            <Issue.ToolbarIcon
              background="muted"
              onClick={onToggle}
              src={toAbsoluteUrl("/media/icons/close.svg")}
            />
          )}
        </Issue.Toolbar>
      </Issue.Header>
      <Issue.Body>{pinpoint?.comment}</Issue.Body>
    </Issue>
  );
};

export default PinPointIssueInfo;
