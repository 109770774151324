import { useRestAPIPaginateCollection } from "src/hooks";
import { ENDPOINTS, RESOURCES } from "src/store/constants";

const useBriefPagination = () => {
  const { loadItems, items, ...paginationProps } = useRestAPIPaginateCollection(
    RESOURCES.BRIEF,
    ENDPOINTS.BRIEFS,
    ENDPOINTS.BRIEFS_BY_WORKSPACE
  );

  return { loadItems, items, ...paginationProps };
};

export default useBriefPagination;
