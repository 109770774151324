const validator = require("gltf-validator");

export const validate3DModel = (url) =>
  new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.arrayBuffer())
      .then((asset) => validator.validateBytes(new Uint8Array(asset)))
      .then((report) => resolve(report))
      .catch((error) => reject("Validation failed: ", error));
  });
