import { createContext, useContext, useState } from "react";
import { Img as BaseImg } from ".";
const PinPointContext = createContext(null);
const usePinPointContext = () => useContext(PinPointContext);

const PinPoint = ({ children }) => {
  const [show, setShow] = useState(true);

  const value = {
    show,
    setShow,
  };

  return (
    <PinPointContext.Provider value={value}>
      <div className="pinpoint">{children}</div>
    </PinPointContext.Provider>
  );
};

const Header = ({ children, props }) => {
  return (
    <div className="pinpoint__header" {...props}>
      {children}
    </div>
  );
};

const Title = ({ children, ...props }) => {
  return (
    <div className="pinpoint__header__title" {...props}>
      {children}
    </div>
  );
};

const Img = ({ src }) => {
  return <BaseImg className="pinpoint__header__img" src={src} alt="..." />;
};

const Toolbar = ({ children, props }) => {
  return (
    <div className="pinpoint__header__toolbar" {...props}>
      {children}
    </div>
  );
};

const Body = ({ children, ...props }) => {
  const { show } = usePinPointContext();

  return (
    <div
      id="js-pinpoint__body"
      className={`pinpoint__body ${show ? "show" : ""}`}
      {...props}
    >
      {children}
    </div>
  );
};

const Footer = ({ children, ...props }) => {
  return (
    <div className={`pinpoint__footer`} {...props}>
      {children}
    </div>
  );
};

const Item = ({ children, primary, secondary, ...props }) => (
  <p className="pinpoint__item" {...props}>
    {children && <div className="pinpoint__item--secondary">{children}</div>}
    {primary && <span className="pinpoint__item--primary">{primary}</span>}
    {secondary && (
      <span className="pinpoint__item--secondary">{secondary}</span>
    )}
  </p>
);

PinPoint.Header = Header;
PinPoint.Toolbar = Toolbar;
PinPoint.Title = Title;
PinPoint.Img = Img;
PinPoint.Body = Body;
PinPoint.Footer = Footer;
PinPoint.Item = Item;

export default PinPoint;
