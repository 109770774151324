import { lazy } from "react";
import { MANAGER_PERMISSIONS as PERMISSIONS } from "src/uiHelper/permission";

const CollaborationList = lazy(() =>
  import("./pages/collaboration/ListCompany")
);
const BriefList = lazy(() => import("./pages/collaboration/ListBrief"));

const AddRound = lazy(() => import("./pages/collaboration/AddRound"));
const BriefShow = lazy(() => import("./pages/collaboration/ShowBrief"));
const ShowBriefWithWorkspace = lazy(() =>
  import("./pages/collaboration/ShowBriefWithWorkspace")
);

const PlaygroundIndex = lazy(() => import("./pages/playground/Index"));

const PeopleList = lazy(() => import("./pages/people/List"));
const PeopleCreateAndEdit = lazy(() => import("./pages/people/CreateAndEdit"));

const WaitingList = lazy(() => import("./pages/waitingList/List"));

const CompanyList = lazy(() => import("./pages/company/List"));
const CompanyCreateAndEdit = lazy(() =>
  import("./pages/company/CreateAndEdit")
);

export const managerPlaygroundListRoute = {
  path: "/manager/playground",
  component: PlaygroundIndex,
  permission: PERMISSIONS.PLAYGROUND_LIST,
};

export const managerWorkspaceBriefShowRoute = {
  path: "/manager/collaboration/company/:companyParam/product/:param/show",
  component: ShowBriefWithWorkspace,
  permission: PERMISSIONS.WORKSPACE_BRIEF_SHOW,
};

export const managerPeopleCreateRoute = {
  path: "/manager/people/new",
  component: PeopleCreateAndEdit,
  permission: PERMISSIONS.PEOPLE_CREATE,
};

export const managerPeopleEditRoute = {
  path: "/manager/people/:param/edit",
  component: PeopleCreateAndEdit,
  permission: PERMISSIONS.PEOPLE_EDIT,
};

export const managerPeopleListRoute = {
  path: "/manager/people",
  component: PeopleList,
  permission: PERMISSIONS.PEOPLE_LIST,
};

export const managerWaitingListRoute = {
  path: "/manager/waiting-list",
  component: WaitingList,
  permission: PERMISSIONS.WAITING_LIST,
};

export const managerCompanyEditRoute = {
  path: "/manager/company/:param/edit",
  component: CompanyCreateAndEdit,
  permission: PERMISSIONS.COMPANY_EDIT,
};

export const managerCompanyListRoute = {
  path: "/manager/company",
  component: CompanyList,
  permission: PERMISSIONS.COMPANY_LIST,
};

export const managerCompanyCreateRoute = {
  path: "/manager/collaboration/new",
  component: CompanyCreateAndEdit,
  permission: PERMISSIONS.COMPANY_CREATE,
};

export const managerCompanyBriefListRoute = {
  path: "/manager/collaboration/company/:param/products",
  component: BriefList,
  permission: PERMISSIONS.COMPANY_BRIEF_LIST,
};

export const managerCompanyBriefUploadRoute = {
  path: "/manager/collaboration/company/:companyParam/product/:param/upload",
  component: AddRound,
  permission: PERMISSIONS.COMPANY_BRIEF_UPLOAD,
};

export const managerBriefUploadRoute = {
  path: "/manager/collaboration/product/:param/upload",
  component: AddRound,
  permission: PERMISSIONS.BRIEF_UPLOAD,
};

export const managerBriefShowRoute = {
  path: "/manager/collaboration/product/:param/show",
  component: BriefShow,
  permission: PERMISSIONS.BRIEF_SHOW,
};

export const managerBriefListRoute = {
  path: "/manager/collaboration/products",
  component: BriefList,
  permission: PERMISSIONS.BRIEF_LIST,
};

export const managerCollaborationListRoute = {
  path: "/manager/collaboration",
  component: CollaborationList,
  permission: PERMISSIONS.COLLABORATION_LIST,
};

