import { FormattedMessage } from "react-intl";

const LoadingUploadedFiles = () => {
  return (
    <>
      <svg className="splash-spinner img-center" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
      <div className="spacing--big-small">
        <p className="form-control__model__area-spacing--big-text form-control__model__area--text">
          <FormattedMessage id="FETCH_UPLOAD.TITLE" />
        </p>
        <p className="form-control__model__area--text--extra-small">
          <FormattedMessage id="FETCH_UPLOAD.DESC" />
        </p>
      </div>
    </>
  );
};

export default LoadingUploadedFiles;
