import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearState } from "src/store/actions";

const useClearStateOnUnmout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    return () => dispatch(clearState());
    // eslint-disable-next-line
  }, [history]);

  return () => dispatch(clearState());
};

export default useClearStateOnUnmout;
