import { useRestAPIPerformActionResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useKickoff3DProject = () => {
  const { onSubmit, isLoading } = useRestAPIPerformActionResource(
    RESOURCES.PRODUCT,
    ENDPOINTS.BRIEF_WORKING_PROGRESS,
    {
      title: "PRODUCT.MSG.KICKOFF.TITLE",
      subtitle: "PRODUCT.MSG.KICKOFF.SUBTITLE",
    }
  );

  return { onKickoff: onSubmit, isKickoffStart: isLoading };
};

export default useKickoff3DProject;
