import { FormattedMessage } from "react-intl";
import { DEFAULT_STATUS_LIST } from "src/uiHelper/status";

const FormattedStatus = ({ status = 0 }) => {
  return (
    <FormattedMessage id={DEFAULT_STATUS_LIST[status] || "STATUS.PENDING"} />
  );
};

export default FormattedStatus;
