import { useEffect } from "react";

const useOutsideAlerter = (ref, elemId, onClick) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        ref.current &&
        event.target.id !== elemId &&
        !ref.current.contains(event.target)
      ) {
        onClick();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line
  }, [ref]);
};

export default useOutsideAlerter;
