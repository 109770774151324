import { useCallback } from "react";
import { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { render } from "react-dom";
import { AppI18nProvider, I18nProvider } from "src/i18n";
import { Provider } from "react-redux";

import { store } from "src/store";

const useModelViewerEditor = ({
  isFetching,
  pinpoints,
  pinpointCreated,
  isMounted,
  canCreate,
  currentUser,
  FormComponent,
  DisplayComponent,
  onCreate,
  src,
}) => {
  const [hotspots, setHotspots] = useState(new Set());
  const [currentHotspot, setCurrentHotspot] = useState({});
  const [pinpointVisible, setPinPointVisibility] = useState(true);

  const onShowPinPoint = () => {
    const viewer = document.querySelector("#js-model-viewer-editor");
    setPinPointVisibility(true);
    if (viewer.hasChildNodes()) {
      for (let i = 0; i < viewer.children.length; i++) {
        const elem = viewer.children[i];
        elem.classList.remove("model-hotspot__hide");
      }
    }
  };

  const onTogglePinPoint = () => {
    const viewer = document.querySelector("#js-model-viewer-editor");
    if (viewer.hasChildNodes()) {
      for (let i = 0; i < viewer.children.length; i++) {
        const elem = viewer.children[i];
        if (!pinpointVisible) {
          elem.classList.remove("model-hotspot__hide");
        } else {
          elem.classList.add("model-hotspot__hide");
        }
      }
    }
    setPinPointVisibility(!pinpointVisible);
  };

  useEffect(() => {
    if (pinpointCreated && !isEmpty(currentHotspot)) {
      const hotspotId = currentHotspot.position + currentHotspot.normal;
      if (hotspots.has(hotspotId)) {
        onRemove(currentHotspot.index);
        setCurrentHotspot((hotspot) => {
          Object.keys(hotspot).forEach((key) => delete hotspot[key]);
          return hotspot;
        });
      }
    }
    // eslint-disable-next-line
  }, [pinpointCreated]);

  const onClick = useCallback(
    (event) => {
      event.preventDefault();

      if (isEmpty(FormComponent)) {
        return;
      }

      const viewer = document.querySelector("#js-model-viewer-editor");

      const rect = viewer.getBoundingClientRect();
      const x = event.offsetX + rect.left;
      const y = event.offsetY + rect.top;
      const positionAndNormal = viewer.positionAndNormalFromPoint(x, y);

      const { position, normal } = positionAndNormal || {};
      const hotspotId = position?.toString() + normal?.toString();
      if (
        position &&
        normal &&
        !hotspots.has(hotspotId) &&
        isEmpty(currentHotspot)
      ) {
        createHostspot(position.toString(), normal.toString(), true);
        setHotspots((hotspotsList) => hotspotsList.add(hotspotId));
      }

      // eslint-disable-next-line
    }, [hotspots, currentHotspot]);

  useEffect(() => {
    const viewer = document.querySelector("#js-model-viewer-editor");
    if (viewer && canCreate) {
      viewer.addEventListener("dblclick", onClick);
      viewer.addEventListener("model-visibility", onShowPinPoint);
    }

    return () => {
      if (viewer) {
        viewer.removeEventListener("model-visibility", onShowPinPoint);
        viewer.removeEventListener("dblclick", onClick);
      }
    };
    // eslint-disable-next-line
  }, [canCreate, isFetching]);

  useEffect(() => {
    const viewer = document.querySelector("#js-model-viewer-editor");

    if (
      !isFetching &&
      isMounted &&
      !isEmpty(src) &&
      !isEmpty(pinpoints) &&
      !isEmpty(viewer)
    ) {
      pinpoints.forEach((pinpoint) => {
        const hotspotId = pinpoint.position + pinpoint.normal;
        if (
          !hotspots.has(hotspotId) &&
          !isEmpty(pinpoint.position) &&
          !isEmpty(pinpoint.normal)
        ) {
          createHostspot(pinpoint.position, pinpoint.normal, false, {
            pinpoint,
          });
          setHotspots((hotspotsList) => hotspotsList.add(hotspotId));
        }
      });
    }
    // eslint-disable-next-line
  }, [pinpoints, isFetching, isMounted]);

  const onToggle = useCallback((index) => {
    const viewer = document.querySelector("#js-model-viewer-editor");
    if (viewer.hasChildNodes()) {
      for (let i = 0; i < viewer.children.length; i++) {
        const hotspot = viewer.children[i];
        const elem = hotspot.children[1];
        if (
          i === index &&
          elem.classList.contains("model-hotspot-content__hide")
        ) {
          elem.classList.remove("model-hotspot-content__hide");
        } else if (
          i === index &&
          !elem.classList.contains("model-hotspot-content__hide")
        ) {
          elem.classList.add("model-hotspot-content__hide");
        } else {
          elem.classList.add("model-hotspot-content__hide");
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  const onRemove = (index) => {
    const viewer = document.querySelector("#js-model-viewer-editor");
    if (viewer.hasChildNodes()) {
      const elem = viewer.children[index];
      setHotspots((hotspotsList) => {
        hotspotsList.delete(elem.dataset.position + elem.dataset.normal);
        return hotspotsList;
      });
      setCurrentHotspot((hotspot) => {
        Object.keys(hotspot).forEach((key) => {
          delete hotspot[key];
        });
        return hotspot;
      });
      viewer.removeChild(elem);
    }
  };

  const createHostspot = useCallback(
    (position, normal, showForm = true, props) => {
      const viewer = document.querySelector("#js-model-viewer-editor");
      const hotspot = document.createElement("div");
      const index = props?.pinpoint
        ? props?.pinpoint?.pinpointNumber - 1
        : hotspots.size;
      hotspot.innerText = props?.pinpoint
        ? props?.pinpoint?.pinpointNumber
        : hotspots.size + 1;
      hotspot.slot = `hotspot-${index + 1}`;
      hotspot.classList.add("model-hotspot");
      if (!pinpointVisible && !showForm) {
        hotspot.classList.add("model-hotspot__hide");
      }
      hotspot.dataset.position = position;
      if (normal !== null) {
        hotspot.dataset.normal = normal;
      }
      if (showForm) {
        setCurrentHotspot((hotspot) => {
          hotspot.index = index;
          return hotspot;
        });
      }
      const onSubmit = (values) => {
        setCurrentHotspot((hotspot) => {
          Object.keys(values).forEach((key) => {
            hotspot[key] = values[key];
          });
          return hotspot;
        });
        onCreate(values);
      };
      render(
        <AppI18nProvider>
          <Provider store={store}>
            <div onClick={() => onToggle(index)} className="model-hotspot-slot">
              {props?.pinpoint
                ? props?.pinpoint?.pinpointNumber
                : hotspots.size + 1}
            </div>
            <div
              className={
                "model-hotspot-content " +
                (!showForm && " model-hotspot-content__hide")
              }
            >
              <I18nProvider>
                {!showForm && (
                  <DisplayComponent
                    {...props}
                    onToggle={() => onToggle(index)}
                    onRemove={() => onRemove(index)}
                  />
                )}
                {showForm && (
                  <FormComponent
                    initialValues={{
                      pinpointNumber: props?.pinpoint
                        ? props?.pinpoint?.pinpointNumber
                        : hotspots.size + 1,
                      position: position,
                      normal: normal,
                      comment: "",
                    }}
                    onSubmit={onSubmit}
                    currentUser={currentUser}
                    onRemove={() => onRemove(index)}
                  />
                )}
              </I18nProvider>
            </div>
          </Provider>
        </AppI18nProvider>,
        hotspot
      );
      viewer.appendChild(hotspot);

      // eslint-disable-next-line
    }, [hotspots, pinpointVisible]);

  return { onTogglePinPoint, pinpointVisible };
};

export default useModelViewerEditor;
