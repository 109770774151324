import { useIntl } from "react-intl";
import { ProfileIcon } from "src/components/common";
import { InputTextareaField, SubmitButton } from "src/components/forms";
import { validations } from "src/utils";
import moment from "moment";
import { reduxForm, Field, Form } from "redux-form";
import { useCurrentUser } from "src/hooks";
import { useRequestCreatePinPoint } from "./../../hooks";

const maxLength200 = validations.maxLength(200);

const PinPointIssueForm = (props) => {
  const { handleSubmit, onRemove, slotname, submitting } = props;
  const intl = useIntl();

  const currentUser = useCurrentUser();
  const { isPinPointCreatingLoading } = useRequestCreatePinPoint();

  return (
    <Form onSubmit={handleSubmit}>
      <div className="model-hotspot-content__header">
        <div className="model-hotspot-content__header__icon">
          <ProfileIcon />
        </div>
        <div className="model-hotspot-content__header__info">
          <div className="model-hotspot-content__header__info__title">
            {currentUser?.firstName} {currentUser?.lastName}
          </div>
          <div className="model-hotspot-content__header__info__subtitle">
            {moment().format("LLL")}
          </div>
        </div>
      </div>

      <Field
        name="comment"
        size="spacing--extra-small"
        component={InputTextareaField}
        placeholder={intl.formatMessage({ id: "FIELD.ISSUE_TEXT" })}
        validate={[validations.required, maxLength200]}
      />

      <div className="text--right spacing--extra-small">
        <button
          className="btn btn__sm btn__danger"
          onClick={() => onRemove(slotname)}
          type="reset"
        >
          {intl.formatMessage({ id: "ISSUE.CANCEL" })}
        </button>
        <SubmitButton
          loading="sm"
          isLoading={isPinPointCreatingLoading}
          submitting={submitting}
          className="btn btn__sm btn__primary spacing__left--extra-small"
          type="submit"
        >
          {intl.formatMessage({ id: "ISSUE.CREATE" })}
        </SubmitButton>
      </div>
    </Form>
  );
};

export default reduxForm({
  enableReinitialize: true,
  form: "issue", // a unique identifier for this form
})(PinPointIssueForm);
