import { useRestAPIPaginateCollection } from "src/hooks";
import { ENDPOINTS, RESOURCES } from "src/store/constants";

const useWorkspacePagination = () => {
  const { loadItems, items, ...paginationProps } = useRestAPIPaginateCollection(
    RESOURCES.WORKSPACE,
    ENDPOINTS.WORKSPACES
  );

  return { loadItems, items, ...paginationProps };
};

export default useWorkspacePagination;
