import { useRestAPIPaginateCollection } from "src/hooks";
import { ENDPOINTS, RESOURCES } from "src/store/constants";

const useBriefPagination = () => {
  const { loadItems, items, ...paginationProps } = useRestAPIPaginateCollection(
    RESOURCES.PRODUCT,
    ENDPOINTS.PRODUCTS,
    ENDPOINTS.PRODUCTS_BY_COMPANY
  );

  return { loadItems, items, ...paginationProps };
};

export default useBriefPagination;
