import { useModelViewerEditor } from "src/hooks";
import { ContentLoader } from "../common";
import Controls from "./Controls";
import ProgressBar from "./ProgressBar";
import { isString, isNumber } from "lodash"
import { useRef, useMemo } from "react";

import useModelViewerLoader from "src/hooks/3dmodel/useModelViewerLoader";

const MODEL_VIEWER_ID = "js-model-viewer-editor";

const ModelViewerEditor = ({
  isFetching,
  qrcode,
  src,
  className,
  cameraOrbit,
  pinpoints,
  waitToSubmitPinPoint,
  pinpointCreated,
  currentUser,
  FormComponent,
  DisplayComponent,
  onCreate,
  canCreate,
  poster,
  editorConfig,
  id = MODEL_VIEWER_ID,
  ...props
}) => {
  const isMounted = useModelViewerLoader({ id });

  const modelOptions = useMemo(() => {
    const options = {}
    if (isNumber(props?.shadowIntensity)) {
      options["shadow-intensity"] = props?.shadowIntensity
    }
    if (isNumber(props?.exposure)) {
      options["exposure"] = props?.exposure
    }
    if (isNumber(props?.shadowSoftness)) {
      options["shadow-softness"] = props?.shadowSoftness
    }
    if (isString(props?.environmentImage)) {
      options["environment-image"] = props?.environmentImage
    }
    return options
  }, [props])

  const ref = useRef();

  const { pinpointVisible, onTogglePinPoint } = useModelViewerEditor({
    isFetching,
    isMounted,
    pinpoints: pinpoints
      .sort((a, b) => a?.pinpointNumber - b?.pinpointNumber)
      .map((pinpoint, i) => ({ ...pinpoint, pinpointNumber: i + 1 })),
    pinpointCreated,
    currentUser,
    canCreate,
    FormComponent,
    DisplayComponent,
    onCreate,
    src,
  });

  if (isFetching) {
    return <ContentLoader className={className} />;
  }

  console.log(editorConfig)

  return (
    <div className={className + " model-bg"}>
      <model-viewer
        id={id}
        poster={poster}
        class={className}
        style={{
          backgroundColor: "#F3F3F3",
          width: ref?.current?.clientWidth,
          height: ref?.current?.clientHeight,
        }}
        loading="lazy"
        alt=""
        disable-pan
        src={src}
        camera-controls
        camera-orbit={cameraOrbit}
        {...modelOptions}
      ></model-viewer>
      <Controls
        pinpointVisible={pinpointVisible}
        qrcode={qrcode}
        onTogglePinPoint={onTogglePinPoint}
      />
      <ProgressBar />
    </div>
  );
};

export default ModelViewerEditor;
