import { useDispatch } from "react-redux";
import { fetchItem, clearItem } from "src/store/actions";

import { useEffect } from "react";
import { isEqual } from "lodash";

import { useSelector } from "react-redux";

const useRestAPIFetchResource = (resource, endpoint) => {
  const dispatch = useDispatch();

  const loadItem = (params = {}, query = {}) => dispatch(fetchItem(resource, endpoint, params, query));

  const { isFetching, fetchedItem } = useSelector(
    (state) => ({
      isFetching: state.rest.fetchedResource[resource]?.isFetching,
      fetchedItem: state.rest.fetchedResource[resource]?.fetchedItem,
    }),
    isEqual
  );

  useEffect(() => {
    return () => dispatch(clearItem(resource));

    // eslint-disable-next-line
  }, []);

  return { loadItem, isFetching, fetchedItem };
};

export default useRestAPIFetchResource;
