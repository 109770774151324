import { toAbsoluteUrl } from "src/utils";
import { Img } from ".";

const RANDOM_ICONS = [
  toAbsoluteUrl("/media/profile/Deer.jpg"),
  toAbsoluteUrl("/media/profile/Elephant.jpg"),
  toAbsoluteUrl("/media/profile/Lama.jpg"),
  toAbsoluteUrl("/media/profile/Panda.jpg"),

  toAbsoluteUrl("/media/profile/Penguin.jpg"),
  toAbsoluteUrl("/media/profile/Sloth.jpg"),
  toAbsoluteUrl("/media/profile/Whale.jpg"),
  toAbsoluteUrl("/media/profile/Zebra.jpg"),
];

const randomIndex = Math.floor(Math.random() * RANDOM_ICONS.length);

const ProfileIcon = ({ className, ...props }) => {
  return (
    <Img
      className={"header__menu__item__icon--profile " + className}
      {...props}
      src={RANDOM_ICONS[randomIndex]}
      alt="..."
    />
  );
};

export default ProfileIcon;
