import { FormattedMessage } from "react-intl";
import { isFunction } from "lodash";
import { useState } from "react";
import { toAbsoluteUrl, toggleFullscreen } from "src/utils";
import { ComponentTransition, Img } from "../common";

const Controls = ({ qrcode, onTogglePinPoint, pinpointVisible }) => {
  const [showHelp, hideHelp] = useState(false);
  const [showQrCode, hideQrCode] = useState(false);

  const onToggleQrCode = () => {
    hideQrCode(!showQrCode);
    hideHelp(false);
  };

  const onToggleHelp = () => {
    hideHelp(!showHelp);
    hideQrCode(false);
  };

  return (
    <>
      <div id="js-controls" className="controls">
        {isFunction(onTogglePinPoint) && (
          <Img
            className="img-tools"
            alt="..."
            onClick={onTogglePinPoint}
            src={
              !pinpointVisible
                ? toAbsoluteUrl("/media/icons/annotation_hidden.svg")
                : toAbsoluteUrl("/media/icons/annotation_shown.svg")
            }
          />
        )}
        <Img
          className="img-tools"
          onClick={onToggleQrCode}
          alt="..."
          src={toAbsoluteUrl("/media/icons/qr-code.svg")}
        />
        <Img
          className="img-tools"
          onClick={onToggleHelp}
          alt="..."
          src={toAbsoluteUrl("/media/icons/help.svg")}
        />
        <Img
          className="img-tools"
          onClick={toggleFullscreen}
          alt="..."
          src={toAbsoluteUrl("/media/icons/fullscreen.svg")}
        />
      </div>
      <div className="popup">
        <ComponentTransition
          className={
            "model-viewer__popup row model-viewer__popup__help " +
            (!showHelp && " model-viewer__popup__hide")
          }
        >
          <div>
            <p>
              <FormattedMessage id="MODEL_VIEWER.POPUP.ROTATE" />
            </p>
            <Img
              height="30"
              width="30"
              alt="..."
              src={toAbsoluteUrl("/media/icons/rotate.svg")}
            />
            <Img
              height="30"
              width="30"
              alt="..."
              src={toAbsoluteUrl("/media/icons/rotate_touch.svg")}
            />
          </div>
          <div>
            <p>
              <FormattedMessage id="MODEL_VIEWER.POPUP.ZOOM" />
            </p>
            <Img
              height="30"
              width="30"
              alt="..."
              src={toAbsoluteUrl("/media/icons/zoom.svg")}
            />
            <Img
              height="30"
              width="30"
              alt="..."
              src={toAbsoluteUrl("/media/icons/zoom_touch.svg")}
            />
          </div>
          <div>
            <p>
              <FormattedMessage id="MODEL_VIEWER.POPUP.PAN" />
            </p>
            <Img
              height="30"
              width="30"
              alt="..."
              src={toAbsoluteUrl("/media/icons/pan.svg")}
            />
            <Img
              height="30"
              width="30"
              alt="..."
              src={toAbsoluteUrl("/media/icons/pan_touch.svg")}
            />
          </div>
        </ComponentTransition>
        <ComponentTransition
          className={
            "model-viewer__popup model-viewer__popup__qrcode " +
            (!showQrCode && " model-viewer__popup__hide")
          }
        >
          <Img
            height="200"
            width="200"
            src={qrcode || toAbsoluteUrl("/media/svg/qrcode.svg")}
          />
          <h1 className="title">
            <FormattedMessage id="MODEL_VIEWER.POPUP.QR_CODE.TITLE" />
          </h1>
          <p className="description">
            <FormattedMessage id="MODEL_VIEWER.POPUP.QR_CODE.DESC" />
          </p>
        </ComponentTransition>
      </div>
    </>
  );
};

export default Controls;
