import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const InputChoiceField = ({
  input,
  label,
  type,
  helper,
  size = "col-xs-12",
  meta: { touched = false, error = null, warning = null } = {},
}) => {
  console.log(input.value);
  return (
    <div className={size}>
      <div className="form-group">
        <div className={`custom-${type} custom-control`}>
          <input
            className={
              "custom-control-input " + (touched && error && "checkbox-danger")
            }
            id={`${type}-button-${input.name}-${input.value}`}
            type={type}
            value={input.value}
            {...input}
          />
          <label
            className="custom-control-label"
            htmlFor={`${type}-button-${input.name}-${input.value}`}
          >
            <span className="choice-label"> {label}</span>
          </label>
        </div>
        <div className="form-control__danger">
          {touched && error && <FormattedMessage {...JSON.parse(error)} />}
          {touched && warning && <FormattedMessage {...JSON.parse(warning)} />}
        </div>
        <div className="form-control__helper">{helper}</div>
      </div>
    </div>
  );
};

InputChoiceField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

export default InputChoiceField;
