// AUTHENTICATION
export const LOGIN_INIT = "AUTHENTICATION@LOGIN_INIT";
export const LOGIN_SUCCEDED = "AUTHENTICATION@LOGIN_SUCCEDED";
export const LOGIN_FAILED = "AUTHENTICATION@LOGIN_FAILED";

export const SIGNUP_INIT = "AUTHENTICATION@REGISTER_INIT";
export const SIGNUP_SUCCEDED = "AUTHENTICATION@REGISTER_SUCCEDED";
export const SIGNUP_FAILED = "AUTHENTICATION@REGISTER_FAILED";

export const SIGNUP_VERIFICATION_INIT =
  "AUTHENTICATION@REGISTER_VERIFICATION_INIT";
export const SIGNUP_VERIFICATION_SUCCEDED =
  "AUTHENTICATION@REGISTER_VERIFICATION_SUCCEDED";
export const SIGNUP_VERIFICATION_FAILED =
  "AUTHENTICATION@REGISTER_VERIFICATION_FAILED";

export const FORGOT_PASSWORD_INIT = "AUTHENTICATION@FORGOT_PASSWORD_INIT";
export const FORGOT_PASSWORD_SUCCEDED =
  "AUTHENTICATION@FORGOT_PASSWORD_SUCCEDED";
export const FORGOT_PASSWORD_FAILED = "AUTHENTICATION@FORGOT_PASSWORD_FAILED";

export const FETCH_TOKEN_INIT = "AUTHENTICATION@FETCH_TOKEN_INIT";
export const FETCH_TOKEN_SUCCEDED = "AUTHENTICATION@FETCH_TOKEN_SUCCEDED";
export const FETCH_TOKEN_FAILED = "AUTHENTICATION@FETCH_TOKEN_FAILED";

export const RESET_PASSWORD_INIT = "AUTHENTICATION@RESET_PASSWORD_INIT";
export const RESET_PASSWORD_SUCCEDED = "AUTHENTICATION@RESET_PASSWORD_SUCCEDED";
export const RESET_PASSWORD_FAILED = "AUTHENTICATION@RESET_PASSWORD_FAILED";

export const LOGOUT_INIT = "AUTHENTICATION@LOGOUT_INIT";
export const LOGOUT_SUCCEDED = "AUTHENTICATION@LOGOUT_SUCCEDED";
export const LOGOUT_FAILED = "AUTHENTICATION@LOGOUT_FAILED";

export const CLEAR_ERROR = "common@CLEAR_ERROR";

export const FLASH_ERROR = "flash@error";
export const FLASH_SUCCESS = "flash@success";

export const INIT = "common@INIT";
export const FAILED = "common@FAILED";
export const SUCCEDED = "common@SUCCEDED";

// item
export const CLEAR_STATE = "item@CLEAR_STATE";
export const CLEAR_RESOURCE_ITEM = "item@CLEAR_RESOURCE_ITEM";
export const CLEAR_COLLECTION_ITEM = "item@CLEAR_COLLECTION_ITEM";
export const CLEAR_PAGINATION_ITEM = "item@CLEAR_PAGINATION_ITEM";
export const CLEAR_ITEM = "item@CLEAR_ITEM";

export const FETCH_ITEMS_INIT = "item@FETCH_ITEMS_INIT";
export const FETCH_ITEMS_SUCCEDED = "item@FETCH_ITEMS_SUCCEDED";
export const FETCH_ITEMS_FAILED = "item@FETCH_ITEMS_FAILED";

export const FETCH_ALL_ITEMS_INIT = "item@FETCH_ALL_ITEMS_INIT";
export const FETCH_ALL_ITEMS_SUCCEDED = "item@FETCH_ALL_ITEMS_SUCCEDED";
export const FETCH_ALL_ITEMS_FAILED = "item@FETCH_ALL_ITEMS_FAILED";

export const CREATE_ITEM_INIT = "item@CREATE_ITEM_INIT";
export const CREATE_ITEM_SUCCEDED = "item@CREATE_ITEM_SUCCEDED";
export const CREATE_ITEM_FAILED = "item@CREATE_ITEM_FAILED";

export const FETCH_ITEM_INIT = "item@FETCH_ITEM_INIT";
export const FETCH_ITEM_SUCCEDED = "item@FETCH_ITEM_SUCCEDED";
export const FETCH_ITEM_FAILED = "item@FETCH_ITEM_FAILED";

export const ACTION_ON_ITEM_INIT = "item@ACTION_ON_ITEM_INIT";
export const ACTION_ON_ITEM_SUCCEDED = "item@ACTION_ON_ITEM_SUCCEDED";
export const ACTION_ON_ITEM_FAILED = "item@ACTION_ON_ITEM_FAILED";

export const EDIT_ITEM_INIT = "item@EDIT_ITEM_INIT";
export const EDIT_ITEM_SUCCEDED = "item@EDIT_ITEM_SUCCEDED";
export const EDIT_ITEM_FAILED = "item@EDIT_ITEM_FAILED";

export const DELETE_ITEM_INIT = "item@DELETE_ITEM_INIT";
export const DELETE_ITEM_SUCCEDED = "item@DELETE_ITEM_SUCCEDED";
export const DELETE_ITEM_FAILED = "item@DELETE_ITEM_FAILED";

export const START_UPLOADING = "form@startUploading";
export const DONE_UPLOADING = "form@doneUplading";

export const PUSH_NOTIFICATION = "notification@pushNotification";
export const CLEAR_NOTIFICATION = "notification@clear";

export const SUBSCRIBE = "notification@SUBSCRIBE";
export const RESET_SOCKET_CONNECTION = "notification@RESET_SOCKET_CONNECTION";
export const UN_SUBSCRIBE = "notification@UN_SUBSCRIBE";

export const FILTER_PAGINATION = "item@FILTER_PAGINATION";

export const FAILED_NOTIFICATION = "notification@FAIL";
export const SEEN_NOTIFICATION = "notification@SEEN_NOTIFICATION";
export const SEEN_COMMENT = "notification@SEEN_COMMENT";
export const FLASH_NOTIFICATION = "notification@FLASH_NOTIFICATION";

export const FETCH_DISCUSSIONS_INIT = "notification@FETCH_DISCUSSIONS_INIT";
export const FETCH_DISCUSSIONS_SUCCEDED =
  "notification@FETCH_DISCUSSIONS_SUCCEDED";
export const FETCH_DISCUSSIONS_FAILED = "notification@FETCH_DISCUSSIONS_FAILED";

export const ACTION_DISCUSSION_SEEN_INIT =
  "notification@ACTION_DISCUSSION_SEEN_INIT";
export const ACTION_DISCUSSION_SEEN_SUCCEDED =
  "notification@ACTION_DISCUSSION_SEEN_SUCCEDED";
export const ACTION_DISCUSSION_SEEN_FAILED =
  "notification@ACTION_DISCUSSION_SEEN_FAILED";

export const FETCH_NOTIFICATIONS_INIT = "notification@FETCH_NOTIFICATIONS_INIT";
export const FETCH_NOTIFICATIONS_SUCCEDED =
  "notification@FETCH_NOTIFICATIONS_SUCCEDED";
export const FETCH_NOTIFICATIONS_FAILED =
  "notification@FETCH_NOTIFICATIONS_FAILED";

export const ACTION_NOTIFICATION_SEEN_INIT =
  "notification@ACTION_NOTIFICATION_SEEN_INIT";
export const ACTION_NOTIFICATION_SEEN_SUCCEDED =
  "notification@ACTION_NOTIFICATION_SEEN_SUCCEDED";
export const ACTION_NOTIFICATION_SEEN_FAILED =
  "notification@ACTION_NOTIFICATION_SEEN_FAILED";
