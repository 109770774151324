import { useEffect } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { ERROR_CODES } from "src/store/constants";
import { useErrorSelector, useFlashErrorAction } from "src/hooks";

const ErrorMessage = () => {
  const intl = useIntl();
  const error = useErrorSelector();
  const flashError = useFlashErrorAction();

  useEffect(() => {
    if (error && error.payload !== null) {
      const { data: { code } = {}, status } = error.payload;
      toast(
        <div className="toast">
          <div className="toast__divider--danger" />
          <div className="toast__content">
            <div className="title">
              {intl.formatMessage({
                id: "ERROR.CODE.INTERNAL_ERROR",
                defaultMessage: "Something wrong happend!",
              })}
            </div>
            <div className="subtitle">
              {intl.formatMessage({
                id: ERROR_CODES[code || status] || "ERROR.CODE.INTERNAL_ERROR",
                defaultMessage: "Something wrong happend!",
              })}
            </div>
          </div>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          onOpen: flashError,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }

    // eslint-disable-next-line
  }, [error]);

  return null;
};

export default ErrorMessage;
