// thrid party lib reducers
import { reducer as formReducer } from "redux-form";
import { ACTIONS } from "../constants";

const extendedFormReducer = (initialState, action) => {
  const state = formReducer(initialState, action);
  const { type, form } = action;
  if (type === ACTIONS.START_UPLOADING) {
    return {
      ...state,
      [form]: { ...state[form], uploading: true },
    };
  }
  if (type === ACTIONS.DONE_UPLOADING) {
    return {
      ...state,
      [form]: { ...state[form], uploading: false },
    };
  }

  return state;
};

export default extendedFormReducer;
