import { Img } from "src/components/common";
import { downloadFile, toAbsoluteUrl } from "src/utils";
import ComponentTransition from "src/components/common/ComponentTransition";

const Editor3DModel = ({ onRemove, modelUrl, setEditorConfig, isDeleting }) => {

  const onDownload = () => {
    downloadFile(modelUrl);
  };

  const onToggleLighting = () => {
    if (modelUrl) {
      setEditorConfig((config) => ({ ...config, toggleLightingConfig: !config.toggleLightingConfig }))
    }
  } 
  

  return (
    <div>
      <ComponentTransition duration={0.5} className="model-menu">
        <Img
          className={modelUrl ? "disabled" : ""}
          alt="..."
          src={toAbsoluteUrl("/media/icons/product.svg")}
        />
        <Img
          alt="..."
          className={!modelUrl ? "disabled" : ""}
          onClick={onToggleLighting}
          src={toAbsoluteUrl("/media/icons/light.svg")}
        />
        <Img
          alt="..."
          className="disabled"
          src={toAbsoluteUrl("/media/icons/camera.svg")}
        />
        <Img
          alt="..."
          className="disabled"
          src={toAbsoluteUrl("/media/icons/material.svg")}
        />
      </ComponentTransition>
      <ComponentTransition
        duration={0.5}
        className="model-menu model-menu--spacing"
      >
        <Img
          onClick={onDownload}
          className={!modelUrl ? "disabled" : ""}
          alt="..."
          src={toAbsoluteUrl("/media/icons/document-upload.svg")}
        />
        {!isDeleting ? (
          <Img
            onClick={onRemove}
            className={!modelUrl ? "disabled" : ""}
            alt="..."
            src={toAbsoluteUrl("/media/icons/trash.svg")}
          />
        ) : (
          <svg viewBox="0 0 50 50">
            <circle
              class="path"
              cx="25"
              cy="25"
              r="20"
              fill="none"
              stroke-width="5"
            ></circle>
          </svg>
        )}
      </ComponentTransition>
    </div>
  );
};

export default Editor3DModel;
