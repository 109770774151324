import { isEmpty, isString } from "lodash";
import mime from "mime-types";

export const removeCSSClass = (ele, cls) => {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
};

export const addCSSClass = (ele, cls) => {
  ele.classList.add(cls);
};

export const isModelViewerFullyLoaded = () => {
  const src = "https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js";
  console.log(window?.scriptLoadMap);
  return Boolean(window?.scriptLoadMap && window?.scriptLoadMap[src]);
};

export const isUrlImg = (src) => {
  if (isString(src) && !mime.lookup(src).match("image.*")) {
    return false;
  }
  return true;
};

export const getMimeType = (src) => mime.lookup(src);

export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export const getFileNameFromURL = (url) => {
  const lastSlashIndex = url.lastIndexOf('/');
  if (lastSlashIndex !== -1) {
    return url.substring(lastSlashIndex + 1);
  }
  return url;
}

export const getFileSize = (url) =>
  new Promise((resolve, reject) => {
    if (isEmpty(url)) {
      resolve(0);
    }
    const http = new XMLHttpRequest();
    http.open("HEAD", url, true); // true = Asynchronous
    http.onreadystatechange = function () {
      if (this.readyState === this.DONE) {
        if (this.status === 200) {
          resolve(this.getResponseHeader("x-amz-meta-size"));
        }
      }
    };
    http.send(); // it will submit request and jump to the next line immediately, without even waiting for request result b/c we used ASYNC XHR call
  });

export const combinePathRoutes = (routeProps = {}, ...routes) => {
  let combinedRoutes = {};
  for (let i = 0; i < routes.length; i++) {
    combinedRoutes = Object.assign(
      {},
      combinedRoutes,
      Object.keys(routes[i]).reduce((combinePath, key) => {
        combinePath[key] = { ...routes[i][key] };
        Object.keys(routeProps).forEach((prop) => {
          if (combinePath[key].hasOwnProperty(prop)) {
            combinePath[key][prop] = routeProps[prop] + combinePath[key][prop];
          } else {
            combinePath[key][prop] = routeProps[prop];
          }
        });
        return combinePath;
      }, {})
    );
  }
  return combinedRoutes;
};
