import { makeCall } from "src/utils";
import { store } from "src/store";

const FETCH_WORKSPACES_ENDPOINT = "/api/v1/workspaces/all";

const formatWorkspaces = (workspaces) =>
  workspaces.map((workspace) => ({
    label: workspace.name,
    value: workspace.id,
  }));

export const workspaceUIHelper = (callback = (f) => f) => {
  const { token } = store.getState().authentication || {};

  return new Promise((resolve, reject) =>
    makeCall(
      "GET",
      FETCH_WORKSPACES_ENDPOINT,
      {},
      { Authorization: `Bearer ${token.access_token}` },
      {}
    )
      .then((resp) => resolve(callback(formatWorkspaces(resp.data))))
      .catch(() => reject(callback([])))
  );
};
