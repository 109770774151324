export const basename = (path) => path.replace(/.*\//, "");

export const downloadFile = (url) => {
  const element = document.createElement("a");
  element.setAttribute("href", url);
  element.setAttribute("download", basename(url));
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
