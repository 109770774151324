import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";
import { discussionSeen } from "src/store/actions";

const useDiscussions = () => {
  const dispatch = useDispatch();

  const onSeen = (param) => {
    dispatch(discussionSeen({ param }));
  };

  const props = useSelector(
    (state) => ({
      discussions: state.notif.discussions,
      newDiscussion: state.notif.newDiscussion,
      lastActive: state.notif.lastActive,
    }),
    isEqual
  );

  return { ...props, onSeen };
};

export default useDiscussions;
