import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Range, getTrackBackground } from "react-range";

import { Img } from "../common";

const InputField = ({
  input,
  helper,
  required = true,
  label,
  icon,
  min = 0,
  max = 100,
  step = 1,
  size = "col-xs-12",
  labelClassName="control-label",
  className = "form-control",
  onChangeCallback = f=>f,
  meta: { touched, error, warning },
  //...props
}) => {

  const onChangeRange = (value) => {
    const [range] = value
    onChangeCallback(range)
    input.onChange(range)
  }
  return (
    <div className={size}>
      {label && (
        <label className={labelClassName}>
          {label} {!required && <FormattedMessage id="FIELD.OPTIONAL" />}
          <span className="text--float-right">{ input.value || 0 }</span>
        </label>
      )}
      <div className="form-group">
        <div className="form-group-input">
          {icon && (
            <div className="form-group-input__addon">
              <Img src={icon} alt="..." />
            </div>
          )}
          <Range
            className={`${className} ${touched && error && "has-danger"}`}
            step={step}
            min={min}
            max={max}
            values={[input.value || 0]}
            onChange={onChangeRange}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: "0.938rem",
                  display: "flex",
                  width: "100%",
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: "0.313rem",
                    width: "100%",
                    borderRadius: "0.25rem",
                    background: getTrackBackground({
                      values: [input.value],
                      colors: ["#7b4fff", "#ccc"],
                      min,
                      max,
                    }),
                    alignSelf: "center",
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "0.938rem",
                  width: "0.938rem",
                  borderRadius: "50%",
                  backgroundColor: "#7b4fff",
                }}
              />
            )}
          />
        </div>
        <div className="form-control__danger">
          {touched && error && <FormattedMessage {...JSON.parse(error)} />}
          {touched && warning && <FormattedMessage {...JSON.parse(warning)} />}
        </div>
        <div className="form-control__helper">{helper}</div>
      </div>
    </div>
  );
};

InputField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

export default InputField;
