import { ObjectAttrDisplay, ObjectAttrDisplayProvider } from "./ObjectPath";

const CardAnalytic = ({ title, isFetching, object, attrName, defaultVal, formatter }) => {
  return (
    <div className="analytics__card">
      <h6 className="analytics__card__title">{title}</h6>
      <ObjectAttrDisplayProvider object={object} isFetching={isFetching}>
        <p className="analytics__card__value">
          <ObjectAttrDisplay attr={attrName} defaultVal={defaultVal} formatter={formatter} />
        </p>
      </ObjectAttrDisplayProvider>
    </div>
  );
};

export default CardAnalytic;
