import { useEffect, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { useRestAPIFetchResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useRequestBriefInfo = ({ params }) => {
  const {
    loadItem,
    isFetching: isFetchingBrief,
    fetchedItem: fetchedBrief,
  } = useRestAPIFetchResource(RESOURCES.PRODUCT, ENDPOINTS.BRIEF);

  const [prevParams, setPrevParams] = useState({});

  useEffect(() => {
    if (!isEmpty(params) && !isEqual(prevParams, params)) {
      loadItem(params);
      setPrevParams(params);
    }

    // eslint-disable-next-line
  }, [params]);

  return { isFetchingBrief, fetchedBrief, loadItem };
};

export default useRequestBriefInfo;
