import { createContext, useContext, useRef, useState } from "react";

const ToolbarContext = createContext(null);

export const useToolbarContext = () => useContext(ToolbarContext);

export const ToolbarConsumer = ToolbarContext.Consumer;

export const ToolbarProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [previousLocation, setPreviousLocation] = useState("");
  const [tabs, setTabs] = useState([]);
  const [buttonText, setButtonText] = useState("");
  const [disableBtn, setDisableBtn] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const buttonRef = useRef();

  const value = {
    show,
    setShow,
    title,
    setTitle,
    previousLocation,
    setPreviousLocation,
    tabs,
    setTabs,
    isLoading,
    setLoading,
    setButtonText,
    buttonText,
    disableBtn,
    setDisableBtn,
    buttonRef,
  };

  return (
    <ToolbarContext.Provider value={value}>{children}</ToolbarContext.Provider>
  );
};
