import { useDispatch } from "react-redux";
import { doneUploading } from "src/store/actions";

const useDoneUploadingAction = (resource) => {
  const dispatch = useDispatch();

  return () => dispatch(doneUploading(resource));
};

export default useDoneUploadingAction;
