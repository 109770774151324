import { ACTIONS } from "./../constants";
import { PURGE } from "redux-persist";

const initialState = {
  currentUser: null,
  permissions: new Set([]),
  isSuperAdmin: false,
  isClient: false,
  isManager: false,
  token: null,
  waitForLogin: false,
  waitForRegistration: false,
  waitForRegistrationVerification: false,
  waitForResetPasswordVerification: false,
  waitForForgetPassword: false,
  successfulLogin: false,
  successfulRegistration: false,
  successfulResetPasswordVerification: false,
  registrationVerfication: null,
  successfulForgetPassword: false,
  isAuthenticated: false,
};

const authenticationReducer = (state = initialState, action) => {
  const { payload, type } = action;

  switch (type) {
    case ACTIONS.CLEAR_STATE: {
      return {
        ...state,
        registrationVerfication: null,
        waitForLogin: false,
        waitForRegistration: false,
        waitForForgetPassword: false,
        successfulLogin: false,
        successfulRegistration: false,
        successfulForgetPassword: false,
      };
    }

    case ACTIONS.LOGIN_INIT: {
      return { ...state, permissions: [], waitForLogin: true, successfulLogin: false };
    }
    case ACTIONS.LOGIN_SUCCEDED: {
      const { user, token, permissions, isSuperAdmin, isClient, isManager } = payload;
      return {
        ...state,
        permissions,
        isSuperAdmin,
        isClient,
        isManager,
        waitForLogin: false,
        successfulLogin: true,
        isAuthenticated: true,
        currentUser: user,
        token,
      };
    }
    case ACTIONS.LOGIN_FAILED: {
      return { ...state, waitForLogin: false, successfulLogin: false };
    }

    case ACTIONS.SIGNUP_INIT: {
      return {
        ...state,
        permissions: [],
        waitForRegistration: true,
        successfulRegistration: false,
      };
    }
    case ACTIONS.SIGNUP_SUCCEDED: {
       const { user, token, permissions, isSuperAdmin, isClient, isManager } = payload;
      return {
        ...state,
        permissions,
        isSuperAdmin,
        isClient,
        isManager,
        waitForRegistration: false,
        successfulRegistration: true,
        isAuthenticated: true,
        currentUser: user,
        token,
      };
    }
    case ACTIONS.SIGNUP_FAILED: {
      return {
        ...state,
        waitForRegistration: false,
        successfulRegistration: false,
      };
    }

    case ACTIONS.SIGNUP_VERIFICATION_INIT: {
      return {
        ...state,
        waitForRegistrationVerification: true,
        registrationVerfication: null,
      };
    }
    case ACTIONS.SIGNUP_VERIFICATION_SUCCEDED: {
      return {
        ...state,
        waitForRegistrationVerification: false,
        registrationVerfication: payload,
      };
    }
    case ACTIONS.SIGNUP_VERIFICATION_FAILED: {
      return {
        ...state,
        waitForRegistrationVerification: false,
        registrationVerfication: null,
      };
    }

    case ACTIONS.FETCH_TOKEN_INIT: {
      return {
        ...state,
        waitForResetPasswordVerification: true,
        successfulResetPasswordVerification: false,
      };
    }
    case ACTIONS.FETCH_TOKEN_SUCCEDED: {
      return {
        ...state,
        waitForResetPasswordVerification: false,
        successfulResetPasswordVerification: true,
      };
    }
    case ACTIONS.FETCH_TOKEN_FAILED: {
      return {
        ...state,
        waitForResetPasswordVerification: false,
        successfulResetPasswordVerification: false,
      };
    }

    case ACTIONS.RESET_PASSWORD_INIT: {
      return { ...state, waitForResetPassword: true };
    }
    case ACTIONS.RESET_PASSWORD_SUCCEDED: {
      const { user, token, permissions, isSuperAdmin, isClient, isManager } = payload;
      return {
        ...state,
        permissions,
        isSuperAdmin,
        isClient,
        isManager,
        waitForResetPassword: false,
        isAuthenticated: true,
        currentUser: user,
        token,
      };
    }
    case ACTIONS.RESET_PASSWORD_FAILED: {
      return { ...state, waitForResetPassword: false };
    }

    case ACTIONS.LOGOUT_SUCCEDED: {
      return { ...state, ...initialState };
    }

    case PURGE: {
      return { ...state, ...initialState };
    }

    case ACTIONS.UPDATE_USER_INFORMATION_SUCCEDED: {
      return { ...state, currentUser: payload };
    }

    case ACTIONS.FORGOT_PASSWORD_INIT: {
      return {
        ...state,
        waitForForgetPassword: true,
        successfulForgetPassword: false,
      };
    }
    case ACTIONS.FORGOT_PASSWORD_SUCCEDED: {
      return {
        ...state,
        waitForForgetPassword: false,
        successfulForgetPassword: true,
      };
    }
    case ACTIONS.FORGOT_PASSWORD_FAILED: {
      return {
        ...state,
        waitForForgetPassword: false,
        successfulForgetPassword: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default authenticationReducer;
