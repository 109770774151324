import { Img } from ".";

const Issue = ({ children, className, ...props }) => {
  return (
    <div className={"issue " + className} {...props}>
      {children}
    </div>
  );
};

const Toolbar = ({ children, className, ...props }) => (
  <div className={`issue__toolbar ${className}`} {...props}>
    {children}
  </div>
);

const ToolbarItem = ({ children, className, ...props }) => (
  <div className={`issue__toolbar__item ${className}`} {...props}>
    {children}
  </div>
);

const ToolbarIcon = ({ children, background, className, src, ...props }) => (
  <Img
    className={`issue__toolbar__icon issue__toolbar__icon--background-${background}`}
    src={src}
    alt="..."
    {...props}
  />
);

const Header = ({ children, className, ...props }) => (
  <div className={`issue__header ${className}`} {...props}>
    {children}
  </div>
);

const HeaderIcon = ({ children, className, ...props }) => (
  <div className={`issue__header__icon ${className}`} {...props}>
    {children}
  </div>
);

const HeaderInfo = ({ children, className, ...props }) => (
  <div className={`issue__header__info ${className}`} {...props}>
    {children}
  </div>
);

const HeaderTitle = ({ children, className, ...props }) => (
  <div className={`issue__header__info__title ${className}`} {...props}>
    {children}
  </div>
);

const HeaderSubTitle = ({ children, className, ...props }) => (
  <div className={`issue__header__info__subtitle ${className}`} {...props}>
    {children}
  </div>
);

const Body = ({ children, ...props }) => {
  return (
    <div className={`issue__body`} {...props}>
      {children}
    </div>
  );
};

Issue.Header = Header;
Issue.Toolbar = Toolbar;
Issue.ToolbarItem = ToolbarItem;
Issue.ToolbarIcon = ToolbarIcon;
Issue.HeaderIcon = HeaderIcon;
Issue.HeaderInfo = HeaderInfo;
Issue.HeaderSubTitle = HeaderSubTitle;
Issue.HeaderTitle = HeaderTitle;
Issue.Body = Body;

export default Issue;
