import { FormattedMessage } from "react-intl";
import { Img } from "src/components/common";

import { toAbsoluteUrl } from "src/utils";

const NoData = () => (
  <div className="spacing--big text--center">
    <Img
      className="img-center"
      height="160"
      width="206"
      src={toAbsoluteUrl("/media/svg/empty-state.svg")}
      alt="..."
    />
    <p className="auth__text--padding text--title text--center text--bold text--secondary">
      <FormattedMessage id="NO_COLLABORATION.TITLE" />
    </p>
    <p className="spacing--extra-small spacing-down--medium text--description text--muted text__width--extra-small--center">
      <FormattedMessage id="NO_COLLABORATION.DESC" />
    </p>
  </div>
);

export default NoData;
