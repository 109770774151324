import { useEffect } from "react";

const useDidUnMount = (callback) => {
  useEffect(() => {
    return () => {
      if (typeof callback === "function") {
        callback();
      }
    };

    // eslint-disable-next-line
  }, []);
};

export default useDidUnMount;
