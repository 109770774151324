import { useEffect, useState } from "react";
import { isEmpty, isEqual } from "lodash";
import { useRestAPIPaginateCollection } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useRequestRoundList = ({ params }) => {
  const {
    loadItems,
    isFetching: isFetchingRoundList,
    items: fetchedRounds = [],
  } = useRestAPIPaginateCollection(RESOURCES.ROUND, ENDPOINTS.BRIEF_ROUND_LIST);

  const [prevParams, setPrevParams] = useState({});

  useEffect(() => {
    if (!isEmpty(params) && !isEqual(params, prevParams)) {
      loadItems(params);
      setPrevParams(params);
    }

    // eslint-disable-next-line
  }, [params]);

  return { isFetchingRoundList, fetchedRounds, loadItems };
};

export default useRequestRoundList;
