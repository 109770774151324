import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";

const ProgressBar = ({
  fileToBeUploaded,
  isPaused,
  progress,
  pauseUpload,
  restartUpload,
  removeFile,
}) => {
  return (
    !isEmpty(fileToBeUploaded) && isEmpty(fileToBeUploaded?.completeMultipartUploadResponse?.url) && (
      <div className="form-group__file-progress">
        <div className="form-group__file-progress__progressbar">
          <div className="form-group__file-progress__progressbar__header">
            <div className="text-left">
              <span>{progress ? progress.toFixed(2) : 0}%</span>
              <span>
                <FormattedMessage id="LOADING" />
              </span>
            </div>
            {!isEmpty(fileToBeUploaded) && (
              <div className="text-right">
                {!isPaused && (
                  <span
                    onClick={pauseUpload}
                    className="text-right text-danger"
                  >
                    <FormattedMessage id="UPLOAD_FILE.PAUSE" />
                  </span>
                )}
                {isPaused && (
                  <span
                    onClick={restartUpload}
                    className="text-right text-danger"
                  >
                    <FormattedMessage id="UPLOAD_FILE.RESUME" />
                  </span>
                )}
                <span onClick={removeFile} className="text-right text-danger">
                  <FormattedMessage id="UPLOAD_FILE.ABORT" />
                </span>
              </div>
            )}
          </div>
          <div className="progress">
            <div
              className={
                "progress__bar" +
                (!isEmpty(fileToBeUploaded) && isPaused
                  ? " progress__bar--danger"
                  : " ")
              }
              role="progressbar"
              style={{ width: `${progress}%` }}
              aria-valuenow={progress || 100}
              aria-valuemin="0"
              aria-valuemax="100"
            />
          </div>
        </div>
      </div>
    )
  );
};

export default ProgressBar;
