import { useDispatch } from "react-redux";
import { startUploading } from "src/store/actions";

const useStartUploadingAction = (resource) => {
  const dispatch = useDispatch();

  return () => dispatch(startUploading(resource));
};

export default useStartUploadingAction;
