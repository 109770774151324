import { getEnv } from "../../utils";

import * as _ACTIONS from "./actions";
import * as _ENDPOINTS from "./endpoints";
import * as _RESOURCES from "./resources";

export * from "./error-codes";

export const API_BASE_URL = getEnv("REACT_APP_API_BASE_URL");

export const CALL_API = "middleware@CALL_API";

export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  DELETE: "DELETE",
};

export const ACTIONS = _ACTIONS;
export const ENDPOINTS = _ENDPOINTS;
export const RESOURCES = _RESOURCES;

export const S3_SUB_DIR = {
  S3_BRIEF_FILES_PATH: "brief_files",
  S3_BRIEF_IMGS_PATH: "brief_imgs",
  S3_ROUND_MODEL_PATH: "round_model",
  S3_ROUND_THUMBNAIL_PATH: "round_thumbnail",
};
