import {useCurrentUserPermission} from "src/hooks";
import {NavLink} from "react-router-dom";

const ProtectedLink = ({ to, permission, children, ...props }) => {

  const { isSuperAdmin, hasPermission } = useCurrentUserPermission()

  if ((!isSuperAdmin && !hasPermission(permission))){
    return <></>
  }

  return (
  <NavLink to={to} { ...props }>
    { children }
  </NavLink>
);
}

export default ProtectedLink;
