import { ACTIONS, CALL_API, HTTP_METHODS } from "./../constants";
import { replaceParams, guessEndpointAndReplaceParams } from "src/utils";

export const clearResourceState = (resource) => ({
  type: ACTIONS.CLEAR_ITEM,
  resource,
});

export const filterPagination = (resource, payload) => ({
  type: ACTIONS.FILTER_PAGINATION,
  resource,
  payload,
});

export const clearItems = (resource) => ({
  type: ACTIONS.CLEAR_PAGINATION_ITEM,
  resource,
});

export const clearAllItems = (resource) => ({
  type: ACTIONS.CLEAR_COLLECTION_ITEM,
  resource,
});

export const clearItem = (resource) => ({
  type: ACTIONS.CLEAR_RESOURCE_ITEM,
  resource,
});

export const fetchItems = (resource, endpoints, params, query) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_ITEMS_INIT,
      success: ACTIONS.FETCH_ITEMS_SUCCEDED,
      fail: ACTIONS.FETCH_ITEMS_FAILED,
    },
    resource,
    endpoint: guessEndpointAndReplaceParams(endpoints, params),
    method: HTTP_METHODS.GET,
    params: { ...query },
    auth: true,
  },
});

export const fetchAllItems = (resource, endpoints, params, query) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_ALL_ITEMS_INIT,
      success: ACTIONS.FETCH_ALL_ITEMS_SUCCEDED,
      fail: ACTIONS.FETCH_ALL_ITEMS_FAILED,
    },
    resource,
    endpoint: guessEndpointAndReplaceParams(endpoints, params),
    method: HTTP_METHODS.GET,
    params: { ...query },
    auth: true,
  },
});

export const filterItems = (resource, endpoint, params) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_ITEMS_INIT,
      success: ACTIONS.FETCH_ITEMS_SUCCEDED,
      fail: ACTIONS.FETCH_ITEMS_FAILED,
    },
    resource,
    endpoint: replaceParams(endpoint, params),
    method: HTTP_METHODS.GET,
    params,
    auth: true,
  },
});

export const createItem = (
  resource,
  endpoint,
  payload,
  params,
  isFormData = false
) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.CREATE_ITEM_INIT,
      success: ACTIONS.CREATE_ITEM_SUCCEDED,
      fail: ACTIONS.CREATE_ITEM_FAILED,
    },
    resource,
    isFormData,
    endpoint: replaceParams(endpoint, params),
    method: HTTP_METHODS.POST,
    auth: true,
  },
});

export const editItem = (
  resource,
  endpoint,
  params,
  payload,
  isFormData = false
) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.EDIT_ITEM_INIT,
      success: ACTIONS.EDIT_ITEM_SUCCEDED,
      fail: ACTIONS.EDIT_ITEM_FAILED,
    },
    isFormData,
    resource,
    endpoint: replaceParams(endpoint, params),
    method: HTTP_METHODS.PUT,
    auth: true,
  },
});

export const deleteItem = (resource, endpoint, params) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.DELETE_ITEM_INIT,
      success: ACTIONS.DELETE_ITEM_SUCCEDED,
      fail: ACTIONS.DELETE_ITEM_FAILED,
    },
    resource,
    endpoint: replaceParams(endpoint, params),
    method: HTTP_METHODS.DELETE,
    auth: true,
  },
});

export const actionOnItem = (
  resource,
  endpoint,
  payload,
  params,
  isFormData = false
) => ({
  type: CALL_API,
  payload,
  meta: {
    actions: {
      init: ACTIONS.ACTION_ON_ITEM_INIT,
      success: ACTIONS.ACTION_ON_ITEM_SUCCEDED,
      fail: ACTIONS.ACTION_ON_ITEM_FAILED,
    },
    resource,
    endpoint: replaceParams(endpoint, params),
    method: HTTP_METHODS.POST,
    isFormData,
    auth: true,
  },
});

export const fetchItem = (resource, endpoint, params, query) => ({
  type: CALL_API,
  meta: {
    actions: {
      init: ACTIONS.FETCH_ITEM_INIT,
      success: ACTIONS.FETCH_ITEM_SUCCEDED,
      fail: ACTIONS.FETCH_ITEM_FAILED,
    },
    resource,
    endpoint: replaceParams(endpoint, params),
    method: HTTP_METHODS.GET,
    params: { ...query },
    auth: true,
  },
});
