import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";
import {getFileNameFromURL} from "src/utils";

const ShowExistingUpload = ({
  fileUploaded,
  removeUploadedFile,
}) => {
  return (
  (!isEmpty(fileUploaded) && !isEmpty(fileUploaded?.completeMultipartUploadResponse?.url)) && (
      <div className="form-group__file-progress">
        <div className="form-group__file-progress__progressbar">
          <div className="form-group__file-progress__progressbar__header">
            <div className="text-left">
              <span>{ getFileNameFromURL(fileUploaded?.completeMultipartUploadResponse?.url) }</span>
            </div>
              <div className="text-right">
                <span onClick={removeUploadedFile} className="text-right text-danger">
                  <FormattedMessage id="UPLOAD_FILE.DELETE" />
                </span>
              </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ShowExistingUpload;
