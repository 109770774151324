import { useEffect, useState } from "react";
import { isEqual, isFunction } from "lodash";

import Elements from "./Elements";
import Paginator from "./Paginator";
import Table from "./Table";

const Paginate = ({
  isTable = false,
  columns = [],

  itemComponent,
  noDataComponent,

  isFetching = false,

  items = [],
  actions = [],

  onRowClick = (f) => f,

  onFetch,
  params,
  query,
  page,
  pages,
  totalSize,
  itemPerPage = 10,
  ...props
}) => {
  const [latestQuery, setLatestQuery] = useState(undefined);

  useEffect(() => {
    if (!isEqual(query, latestQuery) && isFunction(onFetch)) {
      onFetch(params, query);
      setLatestQuery(query);
    }

    // eslint-disable-next-line
  }, [onFetch, query]);

  const onUpdatePage = (page) => {
    onFetch(params, { ...query, page });
  };

  return (
    <div className="paginate--section">
      {!isTable && (
        <Elements
          isFetching={isFetching}
          items={items}
          itemComponent={itemComponent}
          noDataComponent={noDataComponent}
          {...props}
        />
      )}
      {isTable && (
        <Table
          isFetching={isFetching}
          items={items}
          onRowClick={onRowClick}
          actions={actions}
          columns={columns}
          noDataComponent={noDataComponent}
        />
      )}
      <Paginator
        page={page}
        pages={pages}
        isFetching={isFetching}
        totalSize={totalSize}
        itemPerPage={itemPerPage}
        onUpdatePage={onUpdatePage}
      />
    </div>
  );
};

export default Paginate;
