import { useCallback, useState } from "react";

const useQueue = () => {
  const [queue, setQueue] = useState([]);
  const [currentItem, setCurrentItem] = useState(undefined);

  const enqueue = useCallback((item) => {
    if (currentItem === undefined) {
      setCurrentItem(item)
    }
    setQueue((prevQueue) => [...prevQueue, item]);
  }, [currentItem]);

  const dequeue = useCallback(() => {
    if (queue.length > 0) {
      const [removedItem, ...remainingItems] = queue;
      setCurrentItem(removedItem)
      setQueue(remainingItems);
    }
    setCurrentItem(undefined)
  }, [queue]);

  const peek = useCallback(() => {
    return currentItem
  }, [currentItem]);

  const clear = useCallback(() => {
    setQueue([]);
  }, []);

  const length = queue.length;

  return {
    enqueue,
    dequeue,
    peek,
    clear,
    length,
  };
};

export default useQueue;
