import Modal from "src/components/common/Modal";
import { isEmpty } from "lodash"
import { toAbsoluteUrl } from "src/utils";

import Img from "src/components/common/Img";
import { FormattedMessage, useIntl } from "react-intl";
import { Field } from "redux-form";
import { InputRangeField, InputSelectField } from "..";
import { environmentUIHelper } from "src/uiHelper";
import {useHdrUploader} from "src/hooks";
import ProgressBar from "./ProgressBar";
import ShowExistingUpload from "./ShowExistingUpload";

const Draggable3DModelProductForm = ({
  modalTitle,
  editorConfig,
  setEditorConfig,
  isLoading,
  input,
  form,
}) => {
  const intl = useIntl();

  const {
    inputHdrEnvironmentRef, 
    trigerHdrEnvironmentUpload, 
    handleHdrEnvironmentChange, 
    restartHdrEnvironmentUpload, 
    removeHdrEnvironment, 
    pauseHdrEnvironmentUpload, 
    progress, 
    isPaused,
    isUploading,
    hdrEnvironmentPreView,
    hasCustomHdrEnvironment,
    hdrEnvironment
  } = useHdrUploader(input, form, "environment_image", (environmentImage) => setEditorConfig((editor) => ({ ...editor, environmentImage })))

  return (
    editorConfig?.toggleLightingConfig ? (
      <Modal.Draggable className="modal modal--absolute--left">
        <Modal.Header>
          <Modal.Img src={toAbsoluteUrl("/media/icons/light.svg")} />
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="model__modal">
          <div className="form-group">
            <label className="model__label">
              <FormattedMessage id="MODEL.ENVIRONMENT_IMG" />
            </label>
            <div id="js-hdr-preview" className="model__img__small spacing__down--small">
              <Img
                src={hdrEnvironmentPreView}
                alt="..."
              />
            </div>
            <Field
              name="environmentImage"
              size="col-xs-12 spacing__no"
              options={environmentUIHelper(intl)}
              disabled={hasCustomHdrEnvironment || isUploading}
              onChangeCallback={(environmentImage) => setEditorConfig((editor) => ({ ...editor, environmentImage }))}
              component={InputSelectField}
              placeholder={intl.formatMessage({
                id: "FIELD.CUSTOM_ENVIRONMENT",
              })}
              isClearable={true}
            />
            <button
              type="button"
              disabled={isLoading || !isEmpty(editorConfig?.environmentImage) }
              onClick={trigerHdrEnvironmentUpload}
              className={
                "btn btn__block btn__primary model__button " +
                ((isLoading || !isEmpty(editorConfig?.environmentImage)) ? "btn__disabled" : "")
              }
            >
              <FormattedMessage id="MODEL.UPLOAD_CUSTOM_ENVIRONMENT" />
            </button>
            <input
              key={hdrEnvironment?._id}
              ref={inputHdrEnvironmentRef}
              type="file"
              accept=".hdr"
              onChange={handleHdrEnvironmentChange}
              className={`form-control__upload`}
            />
            <ProgressBar
                restartUpload={restartHdrEnvironmentUpload}
                pauseUpload={pauseHdrEnvironmentUpload}
                progress={progress}
                isPaused={isPaused}
                fileToBeUploaded={hdrEnvironment}
              />
            <ShowExistingUpload fileUploaded={hdrEnvironment} removeUploadedFile={removeHdrEnvironment} />
          </div>
          <div className="model__spacing" />
          <div className="form-group spacing__no">
            <Field
              size="col-12 spacing__no"
              labelClassName="model__label"
              name="exposure"
              component={InputRangeField}
              label={intl.formatMessage({
                id: "MODEL.ENVIRONMENT.FIELD.EXPOSURE",
              })}
              min={0}
              max={2}
              step={0.01}
              onChangeCallback={(exposure) => setEditorConfig((editor) => ({ ...editor, exposure }))}
            />
            <Field
              size="col-12 spacing__no"
              name="shadowIntensity"
              labelClassName="model__label"
              component={InputRangeField}
              label={intl.formatMessage({
                id: "MODEL.ENVIRONMENT.FIELD.SHADOW_INTENSITY",
              })}
              min={0}
              max={2}
              step={0.01}
              onChangeCallback={(shadowIntensity) => setEditorConfig((editor) => ({ ...editor, shadowIntensity }))}
            />
            <Field
              name="shadowSoftness"
              labelClassName="model__label"
              size="col-xs-12 spacing__no"
              component={InputRangeField}
              onChangeCallback={(shadowSoftness) => setEditorConfig((editor) => ({ ...editor, shadowSoftness }))}
              min={0}
              max={1}
              step={0.01}
              label={intl.formatMessage({
                id: "MODEL.ENVIRONMENT.FIELD.SHADOW_SOFTNESS",
              })}
            />
          </div>
        </Modal.Body>
      </Modal.Draggable>
    ) : null
  );
};

export default Draggable3DModelProductForm;
