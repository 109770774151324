import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";

const InputTextareaField = ({
  input,
  label,
  required = true,
  placeholder,
  disabled,
  size = "col-xs-12",
  className = "form-control",
  row = 5,
  meta: { touched, error, warning },
}) => {
  const intl = useIntl();

  return (
    <div className={size}>
      <div className="form-group">
        {label && <label className="control-label">{label}</label>}
        <textarea
          {...input}
          disabled={disabled}
          rows={row}
          className={`${className} ${touched && error && "has-danger"}`}
          placeholder={
            placeholder +
            (!required ? intl.formatMessage({ id: "FIELD.OPTIONAL" }) : "")
          }
        />
        <div className="form-control__danger">
          {touched && error && <FormattedMessage {...JSON.parse(error)} />}
          {touched && warning && <FormattedMessage {...JSON.parse(warning)} />}
        </div>
      </div>
    </div>
  );
};

InputTextareaField.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
};

export default InputTextareaField;
