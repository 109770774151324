import { ENDPOINTS } from "src/store/constants";
import { makeCall } from ".";
import mime from "mime-types";

export class MultiUpload {
  constructor(
    file,
    src,
    resource,
    token,
    completeMultipartUploadResponse = null
  ) {
    this.src = src;
    this.PART_SIZE = 5 * 1024 * 1024; //minimum part size defined by aws s3
    this.headers = { Authorization: `Bearer ${token.access_token}` };
    this.RETRY_MAX = 5; //wait before retrying again on upload catchure
    this.retryNum = 0;
    this.file = file;
    this.isUploaded = false;
    this.isFailed = false;
    this.uploadedSize = 0;
    this.completeMultipartUploadResponse = completeMultipartUploadResponse;
    this._id = this.file.name + this.file.type + this.file.size + this.file.lastModifiedDate;
    this.fileInfo = {
      resource,
      name: this.file.name,
      type: mime.lookup(this.file.name),
      size: this.file.size,
      lastModifiedDate: this.file.lastModifiedDate,
    };
    this.resource = resource;
    this.sendBackData = null;
    this.isPaused = false;
    this.uploadXHR = null;
    this.otherInfo = {};
    this.uploadedSize = [];
    this.isUploading = false;
    this.curUploadInfo = {
      blob: null,
      partNum: 0,
    };
    this.blobs = [];
    this.progress = [];
    this.isAborted = false;

    if (console && console.log) {
      this.log = console.log;
    } else {
      this.log = () => {};
    }
  }

  isUploaded = () => this.completeMultipartUploadResponse !== null

  static fetchObjects = (resource, token) =>
    new Promise((resolve, reject) => {
      makeCall(
        "GET",
        ENDPOINTS.FETCH_OBJECTS.replace(":resource", resource),
        {},
        { Authorization: `Bearer ${token.access_token}` }
      )
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => reject(e));
    });

  /** private */
  _createMultipartUpload = () => {
    makeCall(
      "POST",
      ENDPOINTS.CREATE_MULTIPART.replace(":resource", this.resource),
      this.fileInfo,
      this.headers
    )
      .then((response) => {
        this.uploadedSize = [];
        this.sendBackData = response.data;
        this.prepare(0);
      })
      .catch((jqXHR, textStatus, errorThrown) => {
        this.onServerError(
          "CreateMultipartUpload",
          jqXHR,
          textStatus,
          errorThrown
        );
      });
  };

  start = () => {
    this.isUploading = true;
    this._createMultipartUpload();
  };

  /** private */
  prepare = (partNum) => {
    let blobs = (this.blobs = []);
    let start = 0;
    let part = partNum;
    let end;

    this.curUploadInfo.partNum = partNum;

    while (start <= this.file.size && start !== end) {
      console.log(start, end);
      start = this.PART_SIZE * part++;
      end = Math.min(start + this.PART_SIZE, this.file.size);
      blobs.push(this.file.slice(start, end));
      this.progress.push(0);
    }
    console.log(blobs);
    this.progress[this.progress.length - 1] = 70;
    this.uploadPart(partNum);
  };

  setThumbnail = (img) => {
    this.thmbnail = img;
    return this;
  };

  uploadPart = (partNum) => {
    if (this.blobs?.length === 0) {
      this.start();
    }
    if (partNum < this.blobs?.length && this.blobs[partNum]?.size > 0) {
      let blob = this.blobs[partNum];
      makeCall(
        "POST",
        ENDPOINTS.SIGN_UPLOAD_PART,
        {
          ...this.sendBackData,
          partNumber: partNum + 1,
          contentLength: blob.size,
        },
        this.headers
      )
        .then((response) => {
          this.send(response, partNum)
            .then(() => {
              this.curUploadInfo.partNum = partNum + 1;
              this.uploadPart(partNum + 1);
            })
            .catch(() => this.waitRetry());
        })
        .catch(() => {
          this.waitRetry();
        });
    } else {
      this.completeMultipartUpload();
    }
  };

  send = (response, partNum) =>
    new Promise((resolve, reject) => {
      let blobs = this.blobs;
      this.sendToS3(response.data, blobs[partNum], partNum)
        .then((result) => resolve(result))
        .catch((err) => reject(err));
    });

  sendToS3 = (data, blob, index) =>
    new Promise((resolve, reject) => {
      try {
        this.log(data);
        let url = data["url"];
        let size = blob.size;

        this.uploadedSize[index] = 0;

        console.log(index);

        let request = (this.uploadXHR = new XMLHttpRequest());
        request.onreadystatechange = () => {
          if (request.readyState === 4) {
            if (request.status === 200) {
              this.uploadXHR = null;
              this.progress[index] = 100;
              this.uploadedSize[index] = size;
              this.updateProgressBar();
              resolve(true);
            } else {
              if (!this.isPaused) {
                this.onS3UploadError(request);
              }
            }
          }
        };

        request.onerror = (e) => {
          reject(e);
        };

        request.upload.onprogress = (e) => {
          if (e.lengthComputable) {
            this.progress[index] = e.loaded / size;
            this.uploadedSize[index] = e.loaded;
            this.updateProgressBar();
          }
        };
        request.open("PUT", url, true);
        request.setRequestHeader("Content-Type", "application/octet-stream");
        //request.setRequestHeader("Authorization", authHeader)
        request.send(blob);
      } catch (e) {
        console.log(e);
      }
    });

  pause = () => {
    this.isPaused = true;
    this.onPaused();
    if (this.uploadXHR !== null) {
      this.uploadXHR.abort();
    }
  };

  onPaused = () => {
    this.log("paused");
  };

  onResume = () => {
    this.log("resume");
  };

  resume = () => {
    if (this.isPaused) {
      this.isPaused = false;
      this.onResume();
      this.uploadPart(this.curUploadInfo.partNum + 1);
    }
  };

  abort = () => {
    this.isAborted = true;
    this.pause();
    if (this.completeMultipartUploadResponse === null) {
      makeCall(
        "POST",
        ENDPOINTS.ABORT_MULTIPART_UPLOAD,
        this.sendBackData,
        this.headers
      )
        .then(() => {
          this.completeMultipartUploadResponse = null;
        })
        .catch((e) => this.log(e));
    }
  };

  remove = () => {
    this.isRemoved = true;
    this.pause();
    if (this.completeMultipartUploadResponse !== null) {
      makeCall(
        "POST",
        ENDPOINTS.DELETE_OBJECT,
        this.completeMultipartUploadResponse,
        this.headers
      )
        .then((res) => {
          this.completeMultipartUploadResponse = null;
          this.onUploadRemoved(res);
        })
        .catch((e) => this.log(e));
    }
  };

  onUploadRemoved = (res) => {
    console.log(res);
  };

  waitRetry = () => {
    this.log(this.retryNum);
    if (this.retryNum < this.RETRY_MAX) {
      this.retry();
    }
  };

  retry = () => {
    if (
      !this.isPaused &&
      this.uploadXHR === null &&
      this.retryNum < this.RETRY_MAX
    ) {
      this.uploadPart(this.curUploadInfo.partNum);
      this.retryNum += 1;
    }
  };

  completeMultipartUpload = () => {
    makeCall(
      "POST",
      ENDPOINTS.COMPLETE_MULTIPART_UPLOAD,
      this.sendBackData,
      this.headers
    )
      .then((response) => {
        if (!this.isAborted) {
          this.isUploaded = true;
          this.completeMultipartUploadResponse = {
            ...response.data,
            resource: this.resource,
          };
          this.onUploadCompleted(response.data);
          //this.progress[index] =
          this.updateProgressBar();
        }
      })
      .catch((jqXHR, textStatus, errorThrown) => {
        this.onServerError(
          "CompleteMultipartUpload",
          jqXHR,
          textStatus,
          errorThrown
        );
      });
  };

  updateProgressBar = () => {
    let progress = this.progress;
    let length = progress.length;
    let total = 0;
    for (let i = 0; i < progress.length; i++) {
      total = total + progress[i];
    }
    total = total / length;

    this.onProgressChanged(
      this.uploadedSize.reduce((acc, num) => acc + num, 0),
      this.file.size,
      total
    );
  };

  onServerError = (command, jqXHR, textStatus, errorThrown) => {
    this.log(command, jqXHR, textStatus, errorThrown);
    this.isFailed = true;
  };

  onS3UploadError = (xhr) => {
    this.log(xhr);
    this.waitRetry();
  };

  onProgressChanged = (uploadingSize, totalSize, uploadedSize) => {
    this.log("uploadedSize = " + uploadedSize);
    this.log("uploadingSize = " + uploadingSize);
    this.log("totalSize = " + totalSize);
  };

  onUploadCompleted = (serverData) => {
    this.log(serverData);
    this.progress[this.progress.length - 1] = 100;
  };
}
