const CardMedia = ({ children }) => {
  return <div className="card-media">{children}</div>;
};

const Footer = ({ children, className, ...props }) => (
  <div className={`card-media__footer ${className}`} {...props}>
    {children}
  </div>
);

const FooterLeft = ({ children, className, ...props }) => (
  <div className={`card-media__footer--left ${className}`} {...props}>
    {children}
  </div>
);

const FooterRight = ({ children, className, ...props }) => (
  <div className={`card-media__footer--right ${className}`} {...props}>
    {children}
  </div>
);

const Body = ({ children, ...props }) => {
  return (
    <div className={`card-media__body`} {...props}>
      {children}
    </div>
  );
};

CardMedia.Footer = Footer;
CardMedia.Footer.Left = FooterLeft;
CardMedia.Footer.Right = FooterRight;
CardMedia.Body = Body;

export default CardMedia;
