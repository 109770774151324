const INDUSTRIALS = 1;
const HEALTH_CARE = 2;
const FINANCIALS = 3;
const INFORMATION_TECHNOLOGY = 4;
const REAL_ESTATE = 5;
const COMMUNICATION_SERVICES_AND_UTILITIES_SECTOR = 6;

export const INDUSTRY_LIST = {
  [parseInt(INDUSTRIALS)]: "INDUSTRY.OPTION.INDUSTRIALS",
  [parseInt(HEALTH_CARE)]: "INDUSTRY.OPTION.HEALTH_CARE",
  [parseInt(FINANCIALS)]: "INDUSTRY.OPTION.FINANCIALS",
  [parseInt(INFORMATION_TECHNOLOGY)]: "INDUSTRY.OPTION.INFORMATION_TECHNOLOGY",
  [parseInt(REAL_ESTATE)]: "INDUSTRY.OPTION.REAL_ESTATE",
  [parseInt(COMMUNICATION_SERVICES_AND_UTILITIES_SECTOR)]:
    "INDUSTRY.OPTION.COMMUNICATION_SERVICES_AND_UTILITIES_SECTOR",
};

export const industryUIHelper = (intl) =>
  Object.keys(INDUSTRY_LIST).map((industry) => ({
    label: intl.formatMessage({ id: INDUSTRY_LIST[industry] }),
    value: industry,
  }));
