import { ACTIONS, RESOURCES } from "./../../constants";

const initialState = Object.keys(RESOURCES).reduce(
  (acc, key) => ({
    ...acc,
    [RESOURCES[key]]: {
      isDeletedItem: false,
      isLoading: false,
    },
  }),
  {}
);

const restAPIDeletedResourceReducer = (state = initialState, action) => {
  const { type, resource } = action;
  switch (type) {
    case ACTIONS.CLEAR_ITEM: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          isDeletedItem: false,
          isLoading: false,
        },
      };
    }

    case ACTIONS.DELETE_ITEM_INIT: {
      return {
        ...state,
        [resource]: { ...state[resource], isLoading: true, isDeletedItem: false },
      };
    }
    case ACTIONS.DELETE_ITEM_SUCCEDED: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          isDeletedItem: true,
          success: true,
          isLoading: false,
        },
      };
    }
    case ACTIONS.DELETE_ITEM_FAILED: {
      return { ...state, [resource]: { ...state[resource], isDeletedItem: false, isLoading: false } };
    }
    default: {
      return state;
    }
  }
};

export default restAPIDeletedResourceReducer;
