import { useEffect } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import { useClearStateAction } from "src/hooks";

const SuccessMessage = ({ success, title, subtitle }) => {
  const intl = useIntl();
  const flash = useClearStateAction();

  useEffect(() => {
    if (!success) {
      toast(
        <div className="toast">
          <div className="toast__divider--success" />
          <div className="toast__content">
            <div className="title">
              {intl.formatMessage({ id: title || "SUCCESS.TITLE" })}
            </div>
            <div className="subtitle">
              {intl.formatMessage({ id: subtitle || "SUCCESS.SUBTITLE" })}
            </div>
          </div>
        </div>,
        {
          position: "top-right",
          //autoClose: 5000,
          hideProgressBar: false,
          //closeOnClick: true,
          onOpen: flash,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }

    // eslint-disable-next-line
  }, [success]);

  return null;
};

export default SuccessMessage;
