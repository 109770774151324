import { combineReducers } from "redux";

// app reducers
import restAPIFetchResourceReducer from "./restAPIFetchResourceReducer";
import restAPICreateResourceReducer from "./restAPICreateResourceReducer";
import restAPIUpdateResourceReducer from "./restAPIUpdateResourceReducer";
import restAPIDeleteResourceReducer from "./restAPIDeleteResourceReducer";

import restAPIPaginateCollectionReducer from "./restAPIPaginateCollectionReducer";
import restAPIFetchCollectionReducer from "./restAPIFetchCollectionReducer";

import restAPIPerformActionReducer from "./restAPIPerformActionReducer";

// combine app reducers
export default combineReducers({
  fetchedResource: restAPIFetchResourceReducer,
  createdResource: restAPICreateResourceReducer,
  updatedResource: restAPIUpdateResourceReducer,
  deletedResource: restAPIDeleteResourceReducer,

  collection: restAPIFetchCollectionReducer,
  paginateCollection: restAPIPaginateCollectionReducer,

  performAction: restAPIPerformActionReducer,
});
