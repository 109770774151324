import {useCurrentUserPermission} from "src/hooks";

const ProtectedComponent = ({ permissions, children, ...props }) => {

  const { isSuperAdmin, hasPermission } = useCurrentUserPermission()

  if ((!isSuperAdmin && permissions.some((permission) => !hasPermission(permission)))){
    return <></>
  }

  return (
  <div { ...props }>
    { children }
  </div>
);
}

export default ProtectedComponent;
