import { useIntl } from "react-intl";
import { ActionButton } from "../common";

const Paginator = ({ page, isFetching, pages, onUpdatePage = (f) => f }) => {
  const intl = useIntl();

  if (!pages || pages <= 1 || page >= pages - 1) {
    return null;
  }

  return (
    <div className="">
      <div className="spacing--small" />
      <ActionButton
        size="sm"
        isLoading={isFetching}
        onClick={() => onUpdatePage(Math.max(Math.min(page + 1, pages - 1), 1))}
        className="btn btn__primary btn__block"
      >
        {intl.formatMessage({ id: "LOAD_MORE" })}
      </ActionButton>
      <div className="spacing--medium" />
    </div>
  );
};

export default Paginator;
