import { FormattedMessage } from "react-intl";

import {
  PinPointIssueInfo,
  PinPointNoIssue,
} from "src/modules/client/pages/collaboration/components";

import {
  PinPoint,
  Note,
  ActionButton,
  ObjectAttrDisplay,
  ObjectAttrDisplayProvider,
} from "src/components/common";
import { ClientFormattedStatus as FormattedStatus } from "src/components/status";
import { toAbsoluteUrl } from "src/utils";
import { ISSUE, REVIEW } from "src/uiHelper/status";
import {
  useAcceptProduct,
  useDeclineProduct,
  useProductReadyForRevision,
} from "../../hooks";
import { useToolbarContext } from "src/layouts/context/ToolbarContext";
import { useEffect } from "react";
import { useDidUnMount } from "src/hooks";
import { LoadMore } from "src/components/pagination";
import useCancelDeclineProduct from "../../hooks/brief/useCancelDeclineProduct";

const PinPointIssues = (props) => {
  const {
    fetchedBrief,
    isFetching,
    fetchedRound,
    pinpointPaginationProps,
    pinpoints,
    isLatestTab,
    match: { params },
  } = props;

  const { onAcceptProduct, isAcceptProduct } = useAcceptProduct();
  const { onDeclineProduct, isDeclineProduct } = useDeclineProduct();
  const { onCancelDeclineProduct, isCancelDeclineProduct } =
    useCancelDeclineProduct();
  const { productReadyForReview, isLoading } = useProductReadyForRevision();

  const toolbarProps = useToolbarContext();

  useEffect(() => {
    if (fetchedBrief?.status === ISSUE) {
      toolbarProps.setLoading(isLoading);
      toolbarProps.setDisableBtn(pinpoints && pinpoints.length === 0);
      toolbarProps.setButtonText(<FormattedMessage id="SUBMIT.ISSUES" />);
    } else {
      toolbarProps.setButtonText("");
    }

    // eslint-disable-next-line
  }, [fetchedBrief?.status, isLoading, pinpoints]);

  useDidUnMount(() => {
    toolbarProps.setButtonText("");
  });

  return (
    <>
      <button
        ref={toolbarProps.buttonRef}
        className="hidden"
        onClick={() => productReadyForReview({ param: fetchedBrief?.id })}
      ></button>
      <PinPoint>
        <PinPoint.Header>
          <PinPoint.Img src={toAbsoluteUrl("/media/icons/pinpoint.svg")} />
          <PinPoint.Title>
            <FormattedMessage id="ROUND.PINPOINT_ISSUE" />
          </PinPoint.Title>
          <PinPoint.Toolbar>
            <ObjectAttrDisplayProvider
              object={fetchedBrief}
              isFetching={isFetching}
            >
              <ObjectAttrDisplay
                attr="status"
                className="medium_text__loader"
                formatter={(status) => (
                  <FormattedStatus className="status-card" status={status} />
                )}
              />
            </ObjectAttrDisplayProvider>
          </PinPoint.Toolbar>
        </PinPoint.Header>
        <PinPoint.Body>
          <ObjectAttrDisplayProvider
            object={fetchedRound}
            isFetching={isFetching}
          >
            <ObjectAttrDisplay
              attr="description"
              className="alert__loader spacing--b-1"
              formatter={(description) =>
                description && (
                  <>
                    <Note variant="danger">
                      <Note.Title>
                        <FormattedMessage id="NOTE.CLIENT_IN_REVIEW.TITLE" />
                      </Note.Title>
                      <Note.Description>{description}</Note.Description>
                    </Note>
                    {pinpoints?.length > 0 && (
                      <div className="content__divider spacing--b-0" />
                    )}
                  </>
                )
              }
            />
          </ObjectAttrDisplayProvider>
          <LoadMore
            {...pinpointPaginationProps}
            briefParam={fetchedBrief?.id}
            params={{ param: params?.roundParam }}
            roundParam={params?.roundParam}
            workspaceParam={params?.workspaceParam}
            viewAsList={true}
            items={pinpoints}
            itemComponent={PinPointIssueInfo}
            noDataComponent={<PinPointNoIssue />}
            isFetching={isFetching}
          />
        </PinPoint.Body>

        <PinPoint.Footer>
          <ObjectAttrDisplayProvider
            object={fetchedBrief}
            isFetching={isFetching}
          >
            <ObjectAttrDisplay
              attr="status"
              className="button__loader"
              formatter={(status) =>
                isLatestTab &&
                status === REVIEW && (
                  <>
                    <ObjectAttrDisplay
                      attr="id"
                      formatter={(id) => (
                        <>
                          <ActionButton
                            size="sm"
                            isLoading={isDeclineProduct}
                            onClick={() => onDeclineProduct({ param: id })}
                            className="btn btn__danger--outline btn__block spacing__down--small"
                          >
                            <FormattedMessage id="PRODUCT.HAS_ISSUE" />
                          </ActionButton>
                          <ActionButton
                            size="sm"
                            isLoading={isAcceptProduct}
                            onClick={() => onAcceptProduct({ param: id })}
                            className="btn btn__primary btn__block"
                          >
                            <FormattedMessage id="PRODUCT.IS_GOOD" />
                          </ActionButton>
                        </>
                      )}
                    />
                  </>
                )
              }
            />
            <ObjectAttrDisplay
              attr="status"
              className="button__loader"
              formatter={(status) =>
                isLatestTab &&
                pinpoints?.length === 0 &&
                status === ISSUE && (
                  <>
                    <ObjectAttrDisplay
                      attr="id"
                      formatter={(id) => (
                        <>
                          <ActionButton
                            size="sm"
                            isLoading={isCancelDeclineProduct}
                            onClick={() =>
                              onCancelDeclineProduct({ param: id })
                            }
                            className="btn btn__danger--outline btn__block"
                          >
                            <FormattedMessage id="PRODUCT.CANCEL_ISSUE" />
                          </ActionButton>
                        </>
                      )}
                    />
                  </>
                )
              }
            />
          </ObjectAttrDisplayProvider>
        </PinPoint.Footer>
      </PinPoint>
    </>
  );
};

export default PinPointIssues;
