import { FormattedMessage } from "react-intl";
import {
  clientWorkspaceBriefListRoute,
  clientWorkspaceEditRoute,
} from "src/modules/client/routes";
import { toAbsoluteUrl } from "src/utils";
import { FormattedStatus } from "src/components/status";
import {
  Progress,
  Dropdown,
  ComponentTransition,
  Card,
  Img,
  ObjectAttrDisplay,
  ObjectAttrDisplayProvider,
  ObjectCustomList,
} from "src/components/common";
import { useHistory } from "react-router-dom";

const OneWorkspace = ({ object, duration, isFetching }) => {
  const history = useHistory();

  const onClick = () => {
    history.push(
      clientWorkspaceBriefListRoute.path.replace(":param", object?.id)
    );
  };

  return (
    <ComponentTransition
      duration={duration}
      className="col-xl-3 col-md-4 col-sm-6 col-xs-12 card-item"
    >
      <ObjectAttrDisplayProvider object={object} isFetching={isFetching}>
        <Card>
          <Card.Toolbar>
            <Dropdown>
              { isFetching || <Dropdown.ToggleImg
                src={toAbsoluteUrl("/media/icons/more.svg")}
              />}

              <Dropdown.Menu>
                {object?.id && (
                  <>
                    <Dropdown.Item
                      to={clientWorkspaceEditRoute.path.replace(
                        ":param",
                        object?.id
                      )}
                    >
                      <FormattedMessage id="WORKSPACE.EDIT" />
                    </Dropdown.Item>
                    <Dropdown.Item
                      to={clientWorkspaceBriefListRoute.path.replace(
                        ":param",
                        object?.id
                      )}
                    >
                      <FormattedMessage id="WORKSPACE.VIEW" />
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Card.Toolbar>
          <Card.Header>
            <Card.HeaderIconText onClick={onClick}>
              <ObjectAttrDisplay
                className="img__loader"
                attr="name"
                defaultVal={"#"}
                formatter={(name) => name[0]}
              />
            </Card.HeaderIconText>
            <Card.HeaderInfo className="middle-md">
              <h5 onClick={onClick} className="card__header__info__title">
                <ObjectAttrDisplay attr="name" />{" "}
              </h5>
              <h6 onClick={onClick} className="card__header__info__subtitle">
                {" "}
                <ObjectAttrDisplay
                  attr="tags"
                  defaultVal={[]}
                  formatter={(tags) => tags.join(", ")}
                />
              </h6>
            </Card.HeaderInfo>
          </Card.Header>
          <Card.Body>
            <div className="spacing--big-small" />
            <Progress
              title={<FormattedMessage id="PROGRESS" />}
              progress={
                object?.progress || (
                  <ObjectAttrDisplay
                    className="small_text__loader"
                    attr="progress"
                    defaultVal={0}
                  />
                )
              }
            />
            <div className="spacing--big-small" />
            <ObjectCustomList
              attr="status"
              length={5}
              formatter={(status) => <FormattedStatus status={status} />}
            />
          </Card.Body>
          <Card.Footer>
            <Card.FooterItem>
              <Img src={toAbsoluteUrl("/media/icons/brief.svg")} alt="..." />
              <ObjectAttrDisplay
                attr="briefs"
                className="text__loader"
                defaultVal={0}
              />
              {"  "}
              <FormattedMessage id="WORKSPACE.BRIEF" />
            </Card.FooterItem>
            <Card.FooterItem>
              <Img src={toAbsoluteUrl("/media/icons/product.svg")} alt="..." />
              <ObjectAttrDisplay
                attr="products"
                className="text__loader"
                defaultVal={0}
              />
              {"  "}
              <FormattedMessage id="WORKSPACE.PRODUCTS" />
            </Card.FooterItem>
          </Card.Footer>
        </Card>
      </ObjectAttrDisplayProvider>
    </ComponentTransition>
  );
};

export default OneWorkspace;
