import { useDispatch } from "react-redux";
import { clearAllItems, fetchAllItems } from "src/store/actions";

import { useEffect } from "react";
import { isEqual } from "lodash";

import { useSelector } from "react-redux";

const useRestAPIFetchCollection = (resource, ...endpoint) => {
  const dispatch = useDispatch();

  const loadAllItems = (params, query) =>
    dispatch(fetchAllItems(resource, endpoint, params, query));

  const { isFetching, allItems } = useSelector(
    (state) => ({
      isFetching: state.rest.collection[resource]?.isFetching,
      allItems: state.rest.collection[resource]?.allItems ?? [],
    }),
    isEqual
  );

  useEffect(() => {
    return () => dispatch(clearAllItems(resource));

    // eslint-disable-next-line
  }, []);

  return { loadAllItems, isFetching, allItems };
};

export default useRestAPIFetchCollection;
