import { HTTP_METHODS } from "src/store/constants";
import { get, map, isArray, isEmpty } from "lodash";
import { store } from "src/store";
import { makeCall, objectToFormData } from "src/utils";

const asyncValidateForm = (fieldAttr, identifierAttr, url) => (values) => {
  const input = get(values, fieldAttr);
  const id = get(values, identifierAttr);
  const { token } = store.getState().authentication || {};
  const formData = objectToFormData({ [fieldAttr]: input, id });
  const headers = {
    Authorization: `Bearer ${token.access_token}`,
    "Content-Type": "multipart/form-data",
  };

  return makeCall(HTTP_METHODS.POST, url, formData, headers)
    .then((resp) => {
      if (resp?.data?.exist === true) {
        // eslint-disable-next-line
        throw {
          [fieldAttr]: JSON.stringify({
            id: "VALIDATION.ERROR.MIXED.UNIQUE",
            defaultMessage: resp?.data?.reason,
          }),
        };
      }
    })
    .catch((error) => {
      // eslint-disable-next-line
      throw {
        [fieldAttr]: JSON.stringify({
          id: "VALIDATION.ERROR.MIXED.UNIQUE",
          defaultMessage: error,
        }),
      };
    });
};

const asyncValidateFields =
  (fieldAttr, nestedAttr, sentAttr, url) => (values) => {
    const input = get(values, fieldAttr);
    const { token } = store.getState().authentication || {};
    const formData = new FormData();
    const fieldValues = map(input, nestedAttr);
    fieldValues.forEach((value) => {
      formData.append(sentAttr, value);
    });
    const headers = {
      Authorization: `Bearer ${token.access_token}`,
      "Content-Type": "multipart/form-data",
    };

    const duplicateCount = fieldValues.reduce(function (countMap, element) {
      countMap[element] = ++countMap[element] || 1;
      return countMap;
    }, {});

    console.log(duplicateCount)

    return new Promise((resolve, reject) => {
      makeCall(HTTP_METHODS.POST, url, formData, headers)
        .then((resp) => {
          if (
            isArray(resp?.data) &&
            resp?.data?.some((data) => data?.exist === true)
          ) {
            const formattedResponse = resp?.data?.reduce((prevData, data) => {
              prevData[data?.value] = data;
              return prevData;
            }, {});
            // eslint-disable-next-line
            reject({
              [`${fieldAttr}`]: fieldValues.map((email) =>
                ((email in formattedResponse && formattedResponse[email]?.exist === true) || duplicateCount[email] > 1)
                  ? {
                      [nestedAttr]: JSON.stringify({
                        id: "VALIDATION.ERROR.MIXED.UNIQUE",
                        defaultMessage: formattedResponse[email]?.reason,
                      }),
                    }
                  : { [nestedAttr]: undefined }
              ),
            });
          } else if (
            Object.values(duplicateCount).some((count) => count !== 1)
          ) {
            console.log(duplicateCount)
            reject({
              [`${fieldAttr}`]: fieldValues.map((email) =>
                duplicateCount[email] > 1
                  ? {
                      [nestedAttr]: JSON.stringify({
                        id: "VALIDATION.ERROR.MIXED.UNIQUE",
                        defaultMessage: "This field need to be unique",
                      }),
                    }
                  : { [nestedAttr]: undefined }
              ),
            });
          } else {
            resolve()
          }
        })
        .catch((error) => {
          resolve(error);
        });
    });
  };

const BRIEF_UID_VALIDATION_URL = "/api/v1/validation/briefs/uid";
export const validateBriefUid = asyncValidateForm(
  "uid",
  "id",
  BRIEF_UID_VALIDATION_URL
);

const WORKSPACE_NAME_VALIDATION_URL = "/api/v1/validation/workspaces/name";
export const validateWorkspaceName = asyncValidateForm(
  "name",
  "id",
  WORKSPACE_NAME_VALIDATION_URL
);

const INVITATION_EMAIL_VALIDATION_URL = "/api/v1/validation/invitations/email";
export const validateInvitationEmail = asyncValidateForm(
  "email",
  "id",
  INVITATION_EMAIL_VALIDATION_URL
);

const INVITATION_EMAILS_VALIDATION_URL =
  "/api/v1/validation/invitations/emails";
export const validateInvitationEmails = asyncValidateFields(
  "invitations",
  "email",
  "emails",
  INVITATION_EMAILS_VALIDATION_URL
);

const USER_EMAIL_VALIDATION_URL = "/api/v1/validation/users/email";
export const validateUserEmail = asyncValidateForm(
  "email",
  "id",
  USER_EMAIL_VALIDATION_URL
);

const COMPANY_NAME_VALIDATION_URL = "/api/v1/validation/companies/name";
export const validateCompanyName = asyncValidateForm(
  "name",
  "id",
  COMPANY_NAME_VALIDATION_URL
);
