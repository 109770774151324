import { useState, useEffect } from "react";

const ActionButton = ({
  size = "lg",
  className,
  children,
  isLoading,
  onClick,
  ...props
}) => {
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    setIsClicked(true);
    onClick();
  };

  useEffect(() => {
    if (isLoading === false && isClicked === true) {
      setIsClicked(false);
    }

    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <button
      onClick={handleClick}
      type={"button"}
      disabled={isLoading}
      className={`${className}  btn__loader ${
        isLoading && isClicked ? " btn__disabled" : ""
      }`}
      {...props}
    >
      {children}
      {isLoading && isClicked && <div className={`loader ${size}`}></div>}
    </button>
  );
};

export default ActionButton;
