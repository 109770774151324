const SubmitButton = ({
  submitting,
  loading = "lg",
  className,
  children,
  isLoading,
  ...props
}) => {
  return (
    <button
      type={isLoading || submitting ? "button" : "submit"}
      disabled={submitting}
      {...props}
      className={
        className +
        " btn__loader " +
        (isLoading || submitting ? " btn__disabled" : "")
      }
    >
      {isLoading && <div className={`loader ${loading}`}></div>}
      {children}
    </button>
  );
};

export default SubmitButton;
