import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useIntl } from "react-intl";
import { toAbsoluteUrl } from "src/utils";
import { toast } from "react-toastify";
import { useFlashNotification } from "src/hooks";
import { Img } from "../common";
import { NOTIFICATION_DESC, NOTIFICATION_TYPE_TIMELINE } from "src/uiHelper";

const PushNotification = () => {
  const { notification, onFlashNotification } = useFlashNotification();
  const intl = useIntl();

  useEffect(() => {
    if (!isEmpty(notification)) {
      toast(
        notification?.type === NOTIFICATION_TYPE_TIMELINE ? (
          <div className="toast">
            <Img
              src={
                notification.icon || toAbsoluteUrl("/media/profile/Penguin.jpg")
              }
              alt="..."
            />
            <div className="toast__content">
              <div className="title">
                {intl.formatMessage({
                  id:
                    NOTIFICATION_DESC[notification.description] ||
                    "MENU.NO_NOTIFICATION_DESC",
                })}
              </div>
              <div className="subtitle">
                {intl.formatMessage(
                  {
                    id:
                      NOTIFICATION_DESC[notification.description] + ".MESSAGE",
                  },
                  { title: notification.title }
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="toast">
            <Img
              src={
                notification.icon || toAbsoluteUrl("/media/profile/Penguin.jpg")
              }
              alt="..."
            />
            <div className="toast__content">
              <div className="title">{notification?.brief?.name}</div>
              <div className="subtitle">{notification?.description}</div>
            </div>
          </div>
        ),
        {
          position: "top-right",
          //autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          onOpen: onFlashNotification,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }

    // eslint-disable-next-line
  }, [notification]);

  return null;
};

export default PushNotification;
