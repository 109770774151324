import { createContext, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";

const AccordionContext = createContext(null);
const useAccordionContext = () => useContext(AccordionContext);

const Accordion = ({ children }) => {
  const [show, setShow] = useState(true);

  const value = {
    show,
    setShow,
  };

  return (
    <AccordionContext.Provider value={value}>
      <div className="accordion">{children}</div>
    </AccordionContext.Provider>
  );
};

const Title = ({ children, props }) => {
  const { setShow, show } = useAccordionContext();

  return (
    <button
      onClick={() => setShow(!show)}
      className={"btn btn__accordion accordion__header " + (show && "active")}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};

const Body = ({ children, ...props }) => {
  const { show } = useAccordionContext();

  return (
    <div className={`accordion__body ${show ? "show" : ""}`} {...props}>
      {children}
    </div>
  );
};

const Item = ({ primary, secondary, ...props }) => (
  <div className="accordion__item" {...props}>
    {primary && <span className="accordion__item--primary">{primary}</span>}
    {secondary && (
      <span className="accordion__item--secondary">{secondary}</span>
    )}
  </div>
);

const Text = ({ children, ...props }) => (
  <div className="accordion__item" {...props}>
    {children && <span className="accordion__item--secondary">{children}</span>}
  </div>
);

const AccordionFile = ({ children, filename, url, size, ...props }) => {
  return (
    <div className="accordion__item row" {...props}>
      {filename && (
        <span className="col-xs-6 accordion__item--filename">{filename}</span>
      )}
      {url && (
        <span className="col-xs-6 accordion__item--size">
          {(size / 1024 / 1024)?.toFixed(2)} <FormattedMessage id="FILE.SIZE" />
        </span>
      )}
    </div>
  );
};

Accordion.File = AccordionFile;
Accordion.Title = Title;
Accordion.Body = Body;
Accordion.Item = Item;
Accordion.Text = Text;

export default Accordion;
