import { useRestAPICreateResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useRequestCreatePinPoint = () => {
  const { onSubmit, isLoading, createdItem, success } =
    useRestAPICreateResource(
      RESOURCES.PINPOINT,
      ENDPOINTS.PINPOINTS
      //{ title: "PINPOINT.MSG.CREATED", subtitle: "PINPOINT.MSG.CREATED" }
    );

  return {
    onSubmitPinPoint: onSubmit,
    createdPinPoint: createdItem,
    isPinPointCreated: success,
    isPinPointCreatingLoading: isLoading,
  };
};

export default useRequestCreatePinPoint;
