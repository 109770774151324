import Select from "react-select";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import { isEmpty, isFunction } from "lodash";
import { Img } from "../common";

const singleChangeHandler = (func, input, onChangeCallback) => (choice) => {
  const selectedValue = choice && choice.value
  func(selectedValue);
  input.value = selectedValue
  onChangeCallback && onChangeCallback(selectedValue)
};

const multiChangeHandler = (func, onChangeCallback) => (values) => {
  const selectedValues = values.map((value) => value.value)
  func(selectedValues);
  onChangeCallback(selectedValues)
};

const transformValue = (value, options, multi) => {
  if (multi && typeof value === "string") return [];

  const filteredOptions = options.filter((option) => {
    return multi ? value.indexOf(option.value) !== -1 : option.value === value;
  });

  return multi ? filteredOptions : filteredOptions[0] || "";
};

const InputSelectField = ({
  input,
  label,
  placeholder,
  isClearable = false,
  multi = false,
  required = true,
  disabled= false,
  size = "col-xs-12",
  icon,
  onChangeCallback,
  options,
  onLoadedData,
  initialValue,
  meta: { touched, error, warning },
}) => {
  const [choices, setChoices] = useState([]);
  const { value, onChange, onBlur, ...inputAttr } = input;
  const transformedValue = transformValue(value, choices, multi);

  useEffect(() => {
    if (!isEmpty(options) && !isFunction(onLoadedData)) {
      setChoices(options);
    }

    if (isEmpty(options) && isFunction(onLoadedData)) {
      onLoadedData(setChoices);
    }
  }, [options, onLoadedData]);

  return (
    <div className={size}>
      <div className="form-group">
        {label && (
          <label>
            {label} {!required && <FormattedMessage id="FIELD.OPTIONAL" />}
          </label>
        )}
        <div className={`form-group-input ${touched && error && "has-danger"}`}>
          {icon && (
            <div className="form-group-input__addon">
              <Img src={icon} alt="..." />
            </div>
          )}
          <Select
            isDisabled={disabled}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            }}
            className="select-input"
            {...inputAttr}
            isMulti={multi}
            options={choices}
            menuPortalTarget={document.body}
            menuShouldBlockScroll={true}
            menuPosition="absolute"
            onChange={
              multi
                ? multiChangeHandler(onChange, onChangeCallback)
                : singleChangeHandler(onBlur, input, onChangeCallback)
            }
            valueKey="value"
            noOptionsMessage={() => <FormattedMessage id="SELECT_NO_ITEM" />}
            loadingMessage={() => <FormattedMessage id="LOADING" />}
            value={transformedValue || initialValue || null}
            classNamePrefix="react-select"
            placeholder={placeholder}
            isClearable={isClearable}
          />
        </div>
        <div className="form-control__danger">
          {touched && error && <FormattedMessage {...JSON.parse(error)} />}
          {touched && warning && <FormattedMessage {...JSON.parse(warning)} />}
        </div>
      </div>
    </div>
  );
};

export default InputSelectField;
