/* eslint-disable */
import { isEmpty, isArray, isFunction, get } from "lodash";
import { FormattedMessage } from "react-intl";
import { useCallback } from "react";
import { AnimatePresence } from "framer-motion";
import { Dropdown, ComponentTransition } from "src/components/common";

const Table = ({
  isFetching,
  itemPerPage = 10,
  items = [],
  columns = [],
  actions = [],
  noDataComponent,
  onRowClick = (f) => f,
}) => {
  const renderText = useCallback(
    (item) => (
      <>
        {columns.map((col, i) => (
          <td onClick={() => onRowClick(item)} key={"ren" + i}>
            {col.formatter
              ? col.formatter(get(item, col.attr), item) || col.defaultVal
              : get(item, col.attr) ||
                col.defaultVal || <FormattedMessage id="NOT_SPECIFIED" />}
          </td>
        ))}
        {isFunction(actions) && (
          <td className="text--right">
            <Dropdown>
              <Dropdown.Toggle>
                {<FormattedMessage id="ACTIONS" />}
              </Dropdown.Toggle>
              <Dropdown.Action>
                {actions(item).map(({ label, icon, onClick, ...props }, i) => (
                  <Dropdown.Item
                    onClick={() => onClick && onClick(item)}
                    key={"action" + i}
                    {...props}
                    size="sm"
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Action>
            </Dropdown>
            <div className="btn__group"></div>
          </td>
        )}
        {!isEmpty(actions) && (
          <td className="text--right">
            <Dropdown>
              <Dropdown.Toggle>
                {<FormattedMessage id="ACTIONS" />}
              </Dropdown.Toggle>
              <Dropdown.Action>
                {actions.map(({ label, icon, onClick, ...props }, i) => (
                  <Dropdown.Item
                    onClick={() => onClick && onClick(item)}
                    key={"action" + i}
                    {...props}
                    size="sm"
                  >
                    {label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Action>
            </Dropdown>
            <div className="btn__group"></div>
          </td>
        )}
      </>
    ),
    // eslint-disable-next-line
    [columns]
  );

  if ((isEmpty(items) || !isArray(items)) && !isFetching) {
    const Component = noDataComponent;
    return Component;
  }

  //exitBeforeEnter initial={true}
  return (
    <AnimatePresence>
      <div className="row">
        <div className="col-xs-12">
          <table className="table">
            <thead className="table__head">
              <tr>
                {columns.map((col, i) => (
                  <ComponentTransition
                    duration={0.001}
                    element="th"
                    key={"col" + i}
                  >
                    {col.text}
                  </ComponentTransition>
                ))}
                {!isEmpty(actions) && (
                  <ComponentTransition duration={0.001} element="th">
                    <FormattedMessage id="ACTIONS" />
                  </ComponentTransition>
                )}
              </tr>
            </thead>
            <tbody className="table__body">
              {isFetching ? (
                <>
                  {new Array(itemPerPage).fill(0).map((_, i) => (
                    <tr key={i}>
                      {columns.map((_, i) => (
                        <td key={i}>
                          <div className=" item-load nested_text__loader" />
                        </td>
                      ))}
                    </tr>
                  ))}
                </>
              ) : (
                <>
                  {items.map((item, i) => (
                    <ComponentTransition
                      duration={i / 9}
                      element="tr"
                      onClick={() => onRowClick(item)}
                      key={"val" + i}
                    >
                      {renderText(item)}
                    </ComponentTransition>
                  ))}
                  {(isEmpty(items) || !isArray(items)) && (
                    <tr>
                      <ComponentTransition element="td">
                        <FormattedMessage id="NO_DATA" />
                      </ComponentTransition>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default Table;
