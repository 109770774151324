import { isEmpty, isArray } from "lodash";
import { AnimatePresence } from "framer-motion";

const Elements = ({
  items = [],
  itemComponent,
  noDataComponent,
  isFetching,
  ...props
}) => {
  if ((isEmpty(items) || !isArray(items)) && !isFetching) {
    const Component = noDataComponent;
    return Component;
  }

  const Component = itemComponent;
  if (isFetching) {
    return <Component isFetching={isFetching} duration={0} {...props} />;
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <div className="row">
        {items.map((item, i) => (
          <Component
            object={item}
            {...props}
            isFetching={isFetching}
            duration={i / 6}
            key={i}
          />
        ))}
      </div>
    </AnimatePresence>
  );
};

export default Elements;
