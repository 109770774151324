import { createContext, useContext, useState } from "react";
import Draggable from "react-draggable";
import { Img as BaseImg } from ".";
const ModalContext = createContext(null);
const useModalContext = () => useContext(ModalContext);

const ModalDraggable = ({ children, className = "modal modal--absolute" }) => {
  const [show, setShow] = useState(true);

  const value = {
    show,
    setShow,
  };

  return (
    <ModalContext.Provider value={value}>
      <Draggable handle=".modal__header" bounds="parent">
        <div className={className}>{children}</div>
      </Draggable>
    </ModalContext.Provider>
  );
};

const Modal = ({ children }) => {
  const [show, setShow] = useState(true);

  const value = {
    show,
    setShow,
  };

  return (
    <ModalContext.Provider value={value}>
      <div className="modal">{children}</div>
    </ModalContext.Provider>
  );
};

const Header = ({ children, props }) => {
  return (
    <div className="modal__header" {...props}>
      {children}
    </div>
  );
};

const Title = ({ children, ...props }) => {
  return (
    <div className="modal__header__title" {...props}>
      {children}
    </div>
  );
};

const Img = ({ src }) => {
  return <BaseImg className="modal__header__img" src={src} alt="..." />;
};

const Toolbar = ({ children, props }) => {
  return (
    <div className="modal__header__toolbar" {...props}>
      {children}
    </div>
  );
};

const Body = ({ children, className, ...props }) => {
  const { show } = useModalContext();

  return (
    <div
      className={`modal__body ${className} ${show ? "show" : ""}`}
      {...props}
    >
      {children}
    </div>
  );
};

const Footer = ({ children, ...props }) => {
  return (
    <div className={`modal__footer`} {...props}>
      {children}
    </div>
  );
};

const Item = ({ children, primary, secondary, ...props }) => (
  <p className="modal__item" {...props}>
    {children && <div className="modal__item--secondary">{children}</div>}
    {primary && <span className="modal__item--primary">{primary}</span>}
    {secondary && <span className="modal__item--secondary">{secondary}</span>}
  </p>
);

Modal.Draggable = ModalDraggable;
Modal.Header = Header;
Modal.Toolbar = Toolbar;
Modal.Title = Title;
Modal.Img = Img;
Modal.Body = Body;
Modal.Footer = Footer;
Modal.Item = Item;

export default Modal;
