import { ACTIONS, RESOURCES } from "./../../constants";

const initialState = Object.keys(RESOURCES).reduce(
  (acc, key) => ({
    ...acc,
    [RESOURCES[key]]: {
      allItems: [],
      isFetching: false,
    },
  }),
  {}
);

const restAPIFindAll = (state = initialState, action) => {
  const { payload, type, resource } = action;
  switch (type) {
    case ACTIONS.CLEAR_COLLECTION_ITEM: {
      return {
        ...state,
        [resource]: { ...state[resource], allItems: [], isFetching: false },
      };
    }
    case ACTIONS.FETCH_ALL_ITEMS_INIT: {
      return { ...state, [resource]: { ...state[resource], isFetching: true } };
    }
    case ACTIONS.FETCH_ALL_ITEMS_SUCCEDED: {
      return {
        ...state,
        [resource]: {
          ...state[resource],
          allItems: payload,
          isFetching: false,
        },
      };
    }
    case ACTIONS.FETCH_ALL_ITEMS_FAILED: {
      return {
        ...state,
        [resource]: { ...state[resource], isFetching: false },
      };
    }
    default: {
      return state;
    }
  }
};

export default restAPIFindAll;
