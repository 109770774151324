import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import { anonymousSpace } from "src/routes";
import { toAbsoluteUrl } from "src/utils";
import { Img } from "../common";

const PermissionDenied = () => (
  <div className="spacing--big text--center">
    <Img
      className="img-center"
      height="200"
      width="400"
      src={toAbsoluteUrl("/media/svg/401.svg")}
      alt="..."
    />
    <p className="auth__text--padding text--title text--center text--bold text--secondary">
      <FormattedMessage id="ERROR.PAGE_UNAUTHORIZED.TITLE" />
    </p>
    <p className="spacing--extra-small spacing-down--medium text--description text__width--extra-small--center">
      <FormattedMessage id="ERROR.PAGE_UNAUTHORIZED.DESC" />
    </p>
    <Link className="text--center text--description" to={anonymousSpace.path}>
      <FormattedMessage id="ERROR.BACK_TO_HOME" />
    </Link>
  </div>
);

export default PermissionDenied;
