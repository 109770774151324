import { useRestAPIPerformActionResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useAcceptProduct = () => {
  const { onSubmit, isLoading } = useRestAPIPerformActionResource(
    RESOURCES.BRIEF,
    ENDPOINTS.BRIEF_CLOSE,
    {
      title: "PRODUCT.MSG.BRIEF_CLOSE.TITLE",
      subtitle: "PRODUCT.MSG.BRIEF_CLOSE.SUBTITLE",
    }
  );

  return { onAcceptProduct: onSubmit, isAcceptProduct: isLoading };
};

export default useAcceptProduct;
