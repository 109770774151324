import { useRestAPICreateResource } from "src/hooks";
import { RESOURCES, ENDPOINTS } from "src/store/constants";

const useAddRound = (path) => {
  const { onSubmit, isLoading, createdItem, success } =
    useRestAPICreateResource(
      RESOURCES.ROUND,
      ENDPOINTS.PRODUCT_ROUND,
      {
        title: "ROUND.MSG.CREATED.TITLE",
        subtitle: "ROUND.MSG.CREATED.SUBTITLE",
      },
      path
    );

  return { onSubmitRound: onSubmit, isLoading, createdItem, success };
};

export default useAddRound;
