import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  fetchDiscussions,
  fetchNotifications,
  flashNotification,
} from "src/store/actions";

const useFlashNotification = () => {
  const { pushNotifications } = useSelector(
    (state) => ({ pushNotifications: state.notif.pushNotifications }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onFlashNotification = () => {
    dispatch(flashNotification());
    dispatch(fetchDiscussions());
    dispatch(fetchNotifications());
  };

  return { notification: pushNotifications?.pop(), onFlashNotification };
};

export default useFlashNotification;
