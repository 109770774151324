import { useMemo, useEffect } from "react";
import { isEmpty } from "lodash";
import { FormattedMessage } from "react-intl";
import {
  clientBriefListRoute,
  clientWorkspaceBriefListRoute,
} from "src/modules/client/routes";
import { CLOSED } from "src/uiHelper";
import { useToolbarContext } from "src/layouts/context/ToolbarContext";
import { briefRoutes, workspaceBriefRoutes } from "./../../routes";

const useBriefToolbar = ({ fetchedRounds, fetchedBrief, params }) => {
  const toolbarProps = useToolbarContext();

  const latestRound = useMemo(() => {
    let theLatestRound = { id: 0 };
    fetchedRounds?.forEach((round) => {
      if (parseInt(theLatestRound?.id) < parseInt(round?.id)) {
        theLatestRound = round;
      }
    });
    return theLatestRound;
  }, [fetchedRounds]);

  const tabs = useMemo(() => {
    if (fetchedRounds?.length > 0) {
      const routes = isEmpty(params?.workspaceParam)
        ? briefRoutes
        : workspaceBriefRoutes;
      const roundList = fetchedRounds?.map((round, i) => ({
        url: routes.clientRoundInfoRoute.path
          .replace(":param", params?.param)
          .replace(":workspaceParam", params?.workspaceParam)
          .replace(":roundParam", round?.id),
        text: (
          <>
            <FormattedMessage id="ROUND.TITLE" />
            {"   "}
            {i + 1}
          </>
        ),
      }));
      if (fetchedBrief?.status === CLOSED) {
        roundList.pop();
        roundList.push({
          url: routes.clientProductInfoRoute.path
            .replace(":workspaceParam", params?.workspaceParam)
            .replace(":param", params?.param)
            .replace(":roundParam", latestRound?.id),
          text: (
            <>
              <FormattedMessage id="PRODUCT.COMPLETED" />
            </>
          ),
        });
      }
      return roundList.reverse().concat([
        {
          url: routes.clientBriefInfoRoute.path
            .replace(":workspaceParam", params?.workspaceParam)
            .replace(":param", params?.param),
          text: (
            <span>
              <FormattedMessage id="BRIEF.TITLE" />
            </span>
          ),
        },
      ]);
    }
    return [];
    // eslint-disable-next-line
  }, [fetchedRounds, latestRound, fetchedBrief, params]);

  useEffect(() => {
    if (toolbarProps) {
      toolbarProps.setPreviousLocation(
        isEmpty(params?.workspaceParam)
          ? clientBriefListRoute.path
          : clientWorkspaceBriefListRoute.path.replace(
              ":param",
              params?.workspaceParam
            )
      );
      toolbarProps.setShow(true);
      toolbarProps.setTitle(fetchedBrief?.name);
      toolbarProps.setTabs(tabs);
      //toolbarProps.setButtonText(<FormattedMessage id="BRIEF.COMPLETED" />)
    }

    return () => {
      toolbarProps.setShow(false);
      toolbarProps.setTitle("");
      toolbarProps.setTabs([]);
    };

    // eslint-disable-next-line
  }, [fetchedBrief, params, tabs]);

  return { latestRound, tabs };
};

export default useBriefToolbar;
