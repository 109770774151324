export const CLIENT_PERMISSIONS = {
  ANALYTICS_READ: 'analytics_read',
  LIBRARY_CONFIG: 'library_config',
  LIBRARY_UPLOAD_PRODUCT: 'library_upload_product',
  LIBRARY_LIST: 'library_list',
  PEOPLE_CREATE: 'people_create',
  PEOPLE_EDIT: 'people_edit',
  PEOPLE_LIST: 'people_list',
  BRIEF_CREATE: 'brief_create',
  WORKSPACE_BRIEF_SHOW: 'workspace_brief_show',
  WORKSPACE_BRIEF_CREATE: 'workspace_brief_create',
  WORKSPACE_CREATE: 'workspace_create',
  WORKSPACE_BRIEF_LIST: 'workspace_brief_list',
  WORKSPACE_EDIT: 'workspace_edit',
  BRIEF_EDIT: 'brief_edit',
  BRIEF_SHOW: 'brief_show',
  BRIEF_LIST: 'brief_list',
  COLLABORATION_LIST: 'collaboration_list',
};

export const MANAGER_PERMISSIONS = {
  PLAYGROUND_CREATE: 'playground_create',
  PLAYGROUND_EDIT: 'playground_edit',
  PLAYGROUND_LIST: 'playground_list',
  WORKSPACE_BRIEF_SHOW: 'workspace_brief_show',
  PEOPLE_CREATE: 'people_create',
  PEOPLE_EDIT: 'people_edit',
  PEOPLE_LIST: 'people_list',
  WAITING_LIST: 'waiting_list',
  COMPANY_EDIT: 'company_edit',
  COMPANY_LIST: 'company_list',
  COMPANY_CREATE: 'company_create',
  COMPANY_BRIEF_LIST: 'company_brief_list',
  COMPANY_BRIEF_UPLOAD: 'company_brief_upload',
  BRIEF_UPLOAD: 'brief_upload',
  BRIEF_SHOW: 'brief_show',
  BRIEF_LIST: 'brief_list',
  COLLABORATION_LIST: 'collaboration_list',
};

