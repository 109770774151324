const Button = ({
  className = "btn btn__primary",
  children,
  ...props
}) => {

  return (
    <button
      type={"button"}
      className={className}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;
