import { FormattedMessage } from "react-intl";
import { isEmpty } from "lodash";

import { useUploader } from "src/hooks";
import { toAbsoluteUrl } from "src/utils";
import { Img } from "../common";
import { LoadingUploadedFiles, ProgressBar } from "./components";

const InputUploadField = ({
  input,
  folder,
  helper,
  label,
  multiple = false,
  note,
  authRequired = true,
  icon,
  size = "col-xs-12",
  className = "form-control",
  meta: { form, touched, error, warning },
  ...props
}) => {
  const {
    restartUpload,
    isFetching,
    pauseUpload,
    removeFile,
    inputProps,
    handleChange,
    trigerFileUpload,
    files,
    progress,
    isPaused,
    fileToBeUploaded,
    inputRef,
  } = useUploader(input, form, folder);

  return (
    <div className={size}>
      <div className="form-group">
        <div
          onClick={isEmpty(files) ? trigerFileUpload : (f) => f}
          className={`form-control__area ${touched && error && "has-danger"}`}
        >
          {!isEmpty(files) && !isFetching && (
            <div className="form-control__area__showcase">
              <div
                onClick={trigerFileUpload}
                className="form-control__area__showcase__upload__more"
              >
                <Img src={icon} alt="..." />
                <p>
                  <FormattedMessage id="UPLOAD_FILE.TITLE" />
                </p>
              </div>
              {files.map((file, i) => (
                <div key={i} className="form-control__area__showcase__item">
                  <Img
                    onClick={() => removeFile(file)}
                    className="form-control__area__showcase__delete"
                    src={toAbsoluteUrl("/media/icons/close.svg")}
                    alt="..."
                  />
                  <Img
                    className="form-control__area__showcase__img"
                    key={i}
                    alt="..."
                    src={file.src}
                  />
                </div>
              ))}
            </div>
          )}
          {isFetching && (
            <div className="form-control__area__placeholder spacing__small spacing--margin--top--1">
              <LoadingUploadedFiles />
            </div>
          )}
          {isEmpty(files) && !isFetching && (
            <div className="form-control__area__placeholder">
              <Img
                className="form-control__area__placeholder__img"
                src={icon}
                alt="..."
              />
              <p className="form-control__area--text--extra-small">{label}</p>
              <p className="form-control__area--text">{note}</p>
            </div>
          )}
          <input
            key={files.length}
            ref={inputRef}
            type="file"
            multiple={multiple}
            onChange={handleChange}
            {...inputProps}
            {...props}
            className={`form-control__upload`}
          />
        </div>
        <ProgressBar
          restartUpload={restartUpload}
          pauseUpload={pauseUpload}
          progress={progress}
          isPaused={isPaused}
          fileToBeUploaded={fileToBeUploaded}
        />
        <div className="form-control__danger">
          {touched && error && <FormattedMessage {...JSON.parse(error)} />}
          {touched && warning && <FormattedMessage {...JSON.parse(warning)} />}
        </div>
        <div className="form-control__helper">{helper}</div>
      </div>
    </div>
  );
};

export default InputUploadField;
