/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Import all the third party stuff
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { ToastContainer } from "react-toastify";

import { setupAxiosInterceptorOnUnauthorizedRequest } from "./utils";
import { ErrorBoundary } from "src/components/error";

// persist
import { PersistGate } from "redux-persist/integration/react";

// load translation
import { AppI18nProvider, I18nProvider } from "./i18n";

// load style
import "./assets/sass/main.scss";

// store
import { store, persistor, actions } from "./store";

// Import routes
import * as rootRoutes from "./routes";

// loader
import { SplashScreen } from "src/components/loaders";
import { ErrorMessage, PushNotification } from "src/components/message";

// registerd workers
import "./workers";

const MOUNT_NODE = document.getElementById("js-root");

const ELEM = (
  <AppI18nProvider>
    {/* Provide Redux store */}
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<SplashScreen />}>
        <ErrorBoundary>
          <BrowserRouter>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              <React.Suspense fallback={<SplashScreen />}>
                <AnimatePresence exitBeforeEnter initial={true}>
                  <Switch>
                    {Object.keys(rootRoutes).map((key) => (
                      <Route key={key} {...rootRoutes[key]} />
                    ))}
                  </Switch>
                </AnimatePresence>
              </React.Suspense>
              <ErrorMessage />
              <PushNotification />
            </I18nProvider>
          </BrowserRouter>
          <ToastContainer />
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </AppI18nProvider>
);

ReactDOM.render(ELEM, MOUNT_NODE);

setupAxiosInterceptorOnUnauthorizedRequest(actions.unauthenticated);

if (process.env.NODE_ENV !== "production" && module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(["./routes"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE).render(ELEM, MOUNT_NODE);
  });
}
